import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import "./ProductList.css";
import ProductCard from "../../ProductCard/ProductCard";

import {
  listPageBanner,
  product_img1,
  product_img1_H,
  product_img1_H2,
  product_img1_H3,
  product_img2,
  product_img2_H,
  product_img2_H2,
  product_img2_H3,
  product_img3,
  product_img3_H,
  product_img3_H2,
  product_img3_H3,
  product_img4,
  product_img4_H,
  product_img4_H2,
  product_img4_H3,
} from "../../../assets/images";
import FilterForm from "../../Filters/FilterForm/FilterForm";
import OtherBanners from "../../Headings/OtherBanners/OtherBanners";
import { Breadcrumbs } from "../../Headings";
import FilterByFeatured from "../../Filters/FilterByFeatured/FilterByFeatured";
import { ProductQuickView, SizeChartDrawer, SubCollectionCircles } from "../..";
import SectionHeadings from "../../Headings/SectionHeadings/SectionHeadings";
import ProductListLoader from "../../loaders/ProductListLoader";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  subscribeFilter,
  subscribesort,
  toggleLoading,
  toggleTrigger,
} from "../../../store";
import { getWithoutAuth } from "../../../api/api";
import { Helmet } from "react-helmet-async";

const ProductList = () => {
  const location = useLocation();
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(2);
  const filters = useSelector((state) => state.filterData);

  const [subCategory, setSubCategory] = useState(
    location.state ? [location.state._id] : []
  );

  const sort = useSelector((state) => state.sort);
  const setSort = (data) => {
    dispatch(subscribesort(data));
  };

  const [catData, setCatData] = useState(null);

  const search = useSelector((state) => state.search);

  const [totalCount, setTotal] = useState(null);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const globalTrigger = useSelector((state) => state.globalTrigger);

  console.log(globalTrigger);

  console.log(location.state);

  useEffect(() => {
    if (!location.state) {
      const title = location.pathname
        .split("/")[2]
        .split("-")
        .join(" ")
        .toUpperCase();
      console.log(title);
      if (title.length > 4) {
        dispatch(toggleLoading());
        getWithoutAuth("product/get-category-id?title=" + title)
          .then((res) => {
            console.log(res.data.load);
            location.state = { ...res.data.load };
            dispatch(toggleTrigger());
            dispatch(toggleLoading());
          })
          .catch((err) => {
            console.log(err);
            dispatch(toggleLoading());
          });
      }
    }
  }, [location]);

  useEffect(() => {
    if (location.state) {
      console.log(location.state._id);
    }
    setLoading(true);
    dispatch(toggleLoading(true));
    console.log("started fetching filters");
    axios
      .get(
        `https://amarnathckr.online/api/v1/product/attributes?category=${
          location.state ? location.state._id : "null"
        }`,
        {
          withCredentials: true, // Add withCredentials option here
        }
      )
      .then((responseFilter) => {
        console.log(responseFilter.data);
        console.log("fetched filterData");
        dispatch(
          subscribeFilter({
            subCategory: responseFilter.data.attributes.subCategory,
            ...responseFilter.data.attributes,
          })
        );
        setLoading(false);
        dispatch(toggleLoading(false));
      })
      .catch((err) => {
        console.log("Filter fetching failed");
        console.log(err);
        setLoading(false);
      });
  }, [location.state]);

  const fetchData = async (params) => {
    setLoading(true);
    if (location.state) {
      console.log(location.state._id);
    }

    let categoryQuery = location.state
      ? location.state._id
      : subCategory.length > 1
      ? subCategory.reduce((p, c) => p + "+" + c)
      : subCategory[0]
      ? subCategory[0]
      : "";

    let crudeFilter = { ...filters };

    delete crudeFilter["subCategory"];

    let selectedValues = Object.entries(crudeFilter).flatMap(
      ([category, items]) =>
        items
          .filter(
            (item) => Object.values(item)[0].state && item !== "subCategory"
          )
          .map((item) => Object.values(item)[0].value)
    );

    console.log(selectedValues);

    selectedValues = selectedValues.join("+");

    let catFilterQuery =
      filters.subCategory?.length > 0
        ? filters?.subCategory?.filter(
            (item) => item[Object.keys(item)].state !== false
          )
        : "";

    if (catFilterQuery?.length > 0) {
      catFilterQuery = catFilterQuery?.map((items) => {
        return items[Object.keys(items)].value;
      });
      console.log(catFilterQuery.reduce((p, c) => p + "+" + c));
      categoryQuery = catFilterQuery.reduce((p, c) => p + "+" + c);
    }

    console.log(selectedValues);

    console.log(categoryQuery);

    console.log(location.search.split("=")[1]);
    const occasion = location.search.split("=")[1];

    const response = await axios.get(
      `https://amarnathckr.online/api/v1/product?search=${search}&page=${2}&sort=${sort}&subCategory=${categoryQuery}&filter=${selectedValues}&collection=${
        location.pathname === "/products/best-seller"
          ? "Best Seller"
          : location.pathname === "/products/trending"
          ? "Trending"
          : location.pathname === "/products/new"
          ? "New Arrivals"
          : ""
      }&sales=${location.pathname === "/products/sales" ? true : ""}${
        occasion ? "&occasion=" + occasion : ""
      }`,
      {
        withCredentials: true, // Add withCredentials option here
      }
    );
    const data = response.data.load;

    console.log(data);
    setTotal(response.data.totalCount);
    setProducts(data);
    data.length < 9 ? setHasMore(false) : setHasMore(true);
    setIndex(3);
    setLoading(false);
  };

  const fetchMoreData = async () => {
    if (location.state) {
      console.log(location.state._id);
    }

    setLoading(true);
    let categoryQuery =
      location.state && location.state._id
        ? location.state._id
        : subCategory.length > 1
        ? subCategory.reduce((p, c) => p + "+" + c)
        : subCategory[0]
        ? subCategory[0]
        : "";

    let selectedValues = Object.entries(filters).flatMap(([category, items]) =>
      items
        .filter(
          (item) => Object.values(item)[0].state && item !== "subCategory"
        )
        .map((item) => Object.values(item)[0].value)
    );

    selectedValues = selectedValues.join("+");

    let catFilterQuery =
      filters.subCategory?.length > 0
        ? filters?.subCategory?.filter(
            (item) => item[Object.keys(item)].state !== false
          )
        : "";

    if (catFilterQuery?.length > 0) {
      catFilterQuery = catFilterQuery?.map((items) => {
        return items[Object.keys(items)].value;
      });
      console.log(catFilterQuery.reduce((p, c) => p + "+" + c));
      categoryQuery = catFilterQuery.reduce((p, c) => p + "+" + c);
    }

    console.log(location.pathname);

    console.log(location.search.split("=")[1]);
    const occasion = location.search.split("=")[1];

    const response = await axios.get(
      `https://amarnathckr.online/api/v1/product?search=${search}&page=${index}&sort=${sort}&subCategory=${categoryQuery}&filter=${selectedValues}${
        location.pathname === "/products/new" ? "&collection=New Arrivals" : ""
      }${location.pathname === "/products/sales" ? "&sales=true" : ""}${
        occasion ? "&occasion=" + occasion : ""
      }`,
      {
        withCredentials: true, // Add withCredentials option here
      }
    );
    setTotal(response.data.totalCount);
    const data = response.data.load;

    console.log(data);
    setProducts((prevProducts) => [...prevProducts, ...data]);
    data.length < 9 ? setHasMore(false) : setHasMore(true);
    setIndex((prevIndex) => prevIndex + 1);
    setLoading(false);
  };

  useEffect(() => {
    setSubCategory([location.state && location.state._id]);
    setIndex(2);
    console.log(location.state);
    fetchData(location.state && location.state._id);
  }, [location.state, filters, sort, location.pathname, search]);

  const toggleQuickModel = () => {
    setIsQuickViewOpen(!isQuickViewOpen);
    if (isQuickViewOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  const [isSizeChartOpen, setSizeChartOpen] = useState(false);

  const toggleSizeChartDrawer = () => {
    setSizeChartOpen(!isSizeChartOpen);
    if (isSizeChartOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  console.log(sort);

  useEffect(() => {
    if (location.state) {
      dispatch(toggleLoading());
      getWithoutAuth("/category/get-category-details?id=" + location.state._id)
        .then((res) => {
          console.log(res.data);
          setCatData(res.data.load);
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    }
  }, [location, globalTrigger]);

  console.log(catData?.description);
  let titleSEO;
  console.log(location.pathname);

  switch (location.pathname) {
    case "/products/new":
      titleSEO = "New Arrival";
      break;
    case "/products/sales":
      titleSEO = "Sales";
      break;
    default:
      titleSEO = catData?.name;
  }

  return (
    <div className="product_list_universal_container">
      <Helmet>
        <title>{titleSEO}</title>
        <meta name="description" content={catData?.description} />
      </Helmet>
      <ProductQuickView
        isQuickViewOpen={isQuickViewOpen}
        toggleQuickModel={toggleQuickModel}
      />
      <OtherBanners
        redirectTo={
          catData
            ? "/products/ " + catData.name.split(" ").join("-").toLowerCase()
            : null
        }
        bannerImage={
          catData && catData.banner
            ? "https://amarnathckr.online/api/v1/upload/" + catData.banner
            : listPageBanner
        }
        bannerImageMobile={null}
      />
      <SizeChartDrawer
        isSizeChartOpen={isSizeChartOpen}
        toggleSizeChartDrawer={toggleSizeChartDrawer}
      />
      <Breadcrumbs />
      <SectionHeadings
        isHome={false}
        title={location.state ? location.state.name : ""}
        paragraph={location.state ? location.state.description : ""}
      />
      <SubCollectionCircles isHome={false} />
      <section className="section">
        <div className="container">
          <div className="product_list_main_container">
            <div className="product_filter_div">
              <FilterForm />
            </div>

            <div className="product_all_container">
              <FilterByFeatured
                value={sort}
                sortHandler={(e) => setSort(e.target.value)}
                totalProductsNumber={totalCount}
              />
              <div className="product_list">
                {products &&
                  products.map((item) => (
                    <div className="products" key={item.id}>
                      <ProductCard
                        toggleQuickModel={toggleQuickModel}
                        product_images={item.images}
                        productTitle={item.title}
                        currencyCode="Rs."
                        finalPrice={item.fcp}
                        originalPrice={item.mrp}
                        salePercentage={item.td}
                        id={item.id}
                        sizeList={item.sizeList}
                        promo={item.promo ? item.promo : null}
                        item={item}
                      />
                    </div>
                  ))}
                {hasMore && !loading && (
                  <>
                    <div className="load-more-div">
                      <button className="load-more-btn" onClick={fetchMoreData}>
                        Load More
                      </button>
                    </div>
                  </>
                )}
                {loading && (
                  <div className="products">
                    <ProductListLoader />
                  </div>
                )}
                {loading && (
                  <div className="products">
                    <ProductListLoader />
                  </div>
                )}
                {loading && (
                  <div className="products">
                    <ProductListLoader />
                  </div>
                )}
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductList;

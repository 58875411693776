import React from "react";
import "./MobileFilterButtons.css";
import { useLocation } from "react-router-dom";

const MobileFilterButtons = ({ toggleFilterDrawer, value, sortHandler }) => {
  const location = useLocation();

  console.log(location.pathname.split("/")[1]);
  return (
    <>
      {location.pathname.split("/")[1] === "products" && (
        <>
          <div className="mobile_filter_buttons_container">
            <div className="mobile_filter_buttons_left">
              <button
                className="mobile_filter_button"
                onClick={toggleFilterDrawer}
              >
                Filter
              </button>
            </div>
            <div className="mobile_filter_buttons_right">
              <div className="mobile_filter_featured_select">
                <select
                  value={value}
                  onChange={sortHandler}
                  name="Featured"
                  id="Featured"
                >
                  <option value="title_az">Alphabetically, A-Z</option>
                  <option value="title_za">Alphabetically, Z-A</option>
                  <option value="mrp_lh">Price, low to high</option>
                  <option value="mrp_hl">Price, high to low</option>
                  <option value="date_on">Date, old to new</option>
                  <option value="date_no">Date, new to old</option>
                </select>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MobileFilterButtons;

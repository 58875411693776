import React from "react";
import { Link } from "react-router-dom";
import "./PreFooter.css"

function PreFooter({ category }) {
  return (
    <section className="section">
      <div className="home-pre-footer_container container">
        <div className="box-pre-footer">
          <div className="title-footer">
            <h4 className="">Shop by Category :</h4>
          </div>
          <div className="content-footer-pre">
            {category &&
              category.map((items, index) => {
                return (
                  <React.Fragment key={items._id}>
                    <Link
                      to={
                        "/products/" +
                        items.name.split(" ").join("-").toLowerCase()
                      }
                      key={items._id}
                    >
                      {items.name}
                    </Link>
                    {index !== category.length - 1 && (
                      <span className="seperator"> | </span>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreFooter;

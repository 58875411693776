/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

import "./ProductQuickView.css";
import {
  Fast_Delivery,
  Global,
  product_img1,
  product_img1_H,
  product_img1_H2,
  product_img1_H3,
} from "../../assets/images";
import {
  CollapsibleList,
  MobileImageSliders,
  SizeChartDrawer,
  SizeRadioButtons,
  Specifications,
} from "../";
import { useDispatch, useSelector } from "react-redux";
import { getAnyApi, getWithoutAuth, postAnyAuth } from "../../api/api";
import {
  subscribeQuickView,
  toggleDrawer,
  toggleLoading,
  toggleTrigger,
} from "../../store";
import LazyImage from "../utils/LazyImage";
import toast from "react-hot-toast";
import CloseButton from "../utils/CloseButton";
import { useLocation } from "react-router-dom";
import Video from "yet-another-react-lightbox/plugins/video";

const ProductQuickView = ({ isQuickViewOpen, toggleQuickModel }) => {
  const [quickViewData, setQuickViewData] = useState(null);
  const [error, setError] = useState(null);
  const zoomRef = React.useRef(null);
  const dispatch = useDispatch();
  const shareUrl = "https://example.com";
  const [isSizeChartOpen, setSizeChartOpen] = useState(false);
  const title = "Check out this amazing content!";
  const close = () => {
    setQuickViewData(null);
    setSelectedSize(null);
    dispatch(subscribeQuickView(null));
    toggleQuickModel();
  };

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    shareUrl
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    shareUrl
  )}&text=${encodeURIComponent(title)}`;
  const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    shareUrl
  )}&description=${encodeURIComponent(title)}`;

  const toggleSizeChartDrawer = () => {
    setSizeChartOpen(!isSizeChartOpen);
    if (isSizeChartOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  const [selectedSize, setSelectedSize] = useState(null);
  const fullscreenRef = React.useRef(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const images = quickViewData?.load?.images;

  const openLightbox = (index) => {
    // Check if all images are loaded before opening the Lightbox
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setZoomLevel(1);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(1, prevZoom - 0.1));
  };

  const gotoNext = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const gotoPrev = () => {
    setPhotoIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const productId = useSelector((state) => state.quickViewData);

  useEffect(() => {
    if (productId && isQuickViewOpen) {
      dispatch(toggleLoading());
      getWithoutAuth("product/details?productId=" + productId)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            console.log(res.data);
            setQuickViewData(res.data);
            setSelectedSize(null);
          }
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    }
  }, [productId, isQuickViewOpen === true]);

  console.log(quickViewData);

  const token = useSelector((state) => state.token);

  const location = useLocation();

  const handleAddCart = () => {
    console.log(productId);
    let localCart = JSON.parse(localStorage.getItem("cart"));

    const referal = location?.pathname
      ?.split("/")[2]
      ?.split("-")
      ?.join(" ")
      ?.toUpperCase();

    console.log(referal);

    let vsku = "";
    if (referal) {
      const index = quickViewData.load.subCategory.findIndex(
        (items) => items.name === referal
      );
      if (index !== -1) {
        vsku = `${quickViewData.load.subCategory[index].vksu}.${
          quickViewData.load.sku.split(".")[1]
        }`;
      } else {
        vsku = `${quickViewData.load.subCategory[0].vksu}.${
          quickViewData.load.sku.split(".")[1]
        }`;
      }
    } else {
      vsku = `${quickViewData.load.subCategory[0].vksu}.${
        quickViewData.load.sku.split(".")[1]
      }`;
    }

    console.log(quickViewData.load.sku);

    if (!selectedSize) {
      setError("Please select size");
      return;
    }

    if (token) {
      // Make an API request to add the product to the cart
      // You need to adapt this based on your API endpoint and structure
      dispatch(toggleLoading());
      postAnyAuth(
        "cart/add",
        { products: [{ productId: productId, size: selectedSize, vsku }] },
        token
      )
        .then((res) => {
          console.log("added to cart");

          if (localCart) {
            const existingProductIndex = localCart.findIndex(
              (item) => item.id === productId && item.size === selectedSize
            );

            if (existingProductIndex !== -1) {
              // Product already exists in the cart, increase the quantity
              localCart[existingProductIndex].quantity += 1;
            } else {
              // Product doesn't exist in the cart, add it
              localCart.push({
                images: quickViewData.load.images,
                title: quickViewData.load.title,
                fcp: quickViewData.load.fcp,
                mrp: quickViewData.load.mrp,
                td: quickViewData.load.td,
                size: selectedSize,
                quantity: 1,
                id: productId,
                vsku,
              });
            }
          } else {
            localCart = [
              {
                images: quickViewData.load.images,
                title: quickViewData.load.title,
                fcp: quickViewData.load.fcp,
                mrp: quickViewData.load.mrp,
                td: quickViewData.load.td,
                size: selectedSize,
                quantity: 1,
                id: productId,
                vsku,
              },
            ];
          }

          console.log(localCart);
          localStorage.setItem("cart", JSON.stringify(localCart));
          dispatch(toggleTrigger());
          dispatch(toggleDrawer());
          dispatch(toggleLoading());
          close();
        })
        .catch((err) => {
          dispatch(toggleLoading());
          console.log(err.response.data.message);
          toast.success(err.response.data.message, {
            position: "top-right",
            style: {
              border: "1px solid #DC747D",
              background: "#fff8f8",
              borderRadius: "5px",
            },
          });

          // Handle error
        });
    } else {
      if (localCart) {
        const existingProductIndex = localCart.findIndex(
          (item) => item.id === productId && item.size === selectedSize
        );

        if (existingProductIndex !== -1) {
          // Product already exists in the cart, increase the quantity
          localCart[existingProductIndex].quantity += 1;
        } else {
          // Product doesn't exist in the cart, add it
          localCart.push({
            images: quickViewData.load.images,
            title: quickViewData.load.title,
            fcp: quickViewData.load.fcp,
            mrp: quickViewData.load.mrp,
            td: quickViewData.load.td,
            size: selectedSize,
            quantity: 1,
            id: productId,
            vsku,
          });
        }
      } else {
        localCart = [
          {
            images: quickViewData.load.images,
            title: quickViewData.load.title,
            fcp: quickViewData.load.fcp,
            mrp: quickViewData.load.mrp,
            td: quickViewData.load.td,
            size: selectedSize,
            quantity: 1,
            id: productId,
            vsku,
          },
        ];
      }

      console.log(localCart);
      localStorage.setItem("cart", JSON.stringify(localCart));
      dispatch(toggleTrigger());
      dispatch(toggleDrawer());
      close();
    }
  };

  console.log(selectedSize);

  const renderContent = (imageUrl, index) => {
    if (imageUrl.includes(".mp4")) {
      // Check if it's a video file
      return (
        <video
          width="100%"
          height="auto"
          src={imageUrl}
          controls={false}
          autoPlay
          muted
          loop
          preload="none"
          poster={
            "https://amarnathckr.online/api/v1/upload/" +
            quickViewData.load.images[0]
          }
        />
      );
    } else {
      // Image
      return <LazyImage src={imageUrl} alt={`Image ${index + 1}`} />;
    }
  };

  console.log(selectedSize);

  return (
    <div className="product-quickview">
      <SizeChartDrawer
        sizeDetails={quickViewData?.load?.sizeDetails}
        isSizeChartOpen={isSizeChartOpen}
        shortName={quickViewData?.subCategory.shortName}
        toggleSizeChartDrawer={toggleSizeChartDrawer}
      />
      {/* Overlay */}
      <div
        className={`overlay ${isQuickViewOpen ? "show" : ""}`}
        onClick={close}
      ></div>

      {/* Sliding Drawer */}
      <div
        className={`quick_view-sliding-drawer-container ${
          isQuickViewOpen ? "open" : ""
        }`}
      >
        {quickViewData && (
          <div className="quick_view-drawer-content">
            <div className="quick_view-drawer__inner">
              <div className="quick_view-drawer__scrollable">
                <div className="quick_view-drawer-header">
                  <i className="ri-close-fill" onClick={close}></i>
                </div>
                <div className="quick_view__items">
                  <div className="details_image_grid common-clearfix">
                    <div className="desktop_image_grid">
                      {quickViewData?.load?.images?.map((imageUrl, index) => (
                        <div
                          className="details_image-sizing"
                          key={index}
                          onClick={() => openLightbox(index)}
                        >
                          <div className="image-grid-imageContainer">
                            {/* <LazyImage
                              src={
                                "https://amarnathckr.online/api/v1/upload/" +
                                imageUrl
                              }
                              alt={`Image ${index + 1}`}
                            /> */}
                            {renderContent(
                              "https://amarnathckr.online/api/v1/upload/" +
                                imageUrl,
                              index
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mobile_image_grid">
                      <MobileImageSliders
                        images={quickViewData?.load?.images}
                      />
                    </div>
                  </div>
                  <div className="product_all_details">
                    <h2 className="product-single__title">
                      {quickViewData?.load?.title}
                    </h2>
                    <div className="product_star_rating">
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-half-fill"></i>
                      <i className="ri-star-line"></i>
                      <span>7 Reviews</span>
                    </div>
                    <div className="product_all_pricing">
                      <div className="product_all_pricing_prices">
                        <span className="prices_final-price">
                          Rs. {quickViewData?.load.fcp}
                        </span>
                        <span className="prices_main-price">
                          Rs. {quickViewData?.load.mrp}
                        </span>
                        <span className="prices_discount">
                          {quickViewData?.load.td}% off
                        </span>
                      </div>
                      <div className="product_all_pricing_savings">
                        <span className="product_all_pricing_saving_price">
                          Save{" "}
                          <b>
                            Rs.{" "}
                            {quickViewData?.load.fcp - quickViewData?.load.mrp}
                          </b>
                        </span>
                        <p className="text-info-label">
                          Inclusive of all taxes
                        </p>
                      </div>
                    </div>
                    <div className="product_details_sizes">
                      <div className="product_details_sizes_chart">
                        <p>SIZE</p>
                        <a
                          onClick={toggleSizeChartDrawer}
                          className="size_chart-btn"
                        >
                          Size chart <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>

                      <div className="product_sizes_radio">
                        <SizeRadioButtons
                          onChange={(data) => {
                            setError(null);
                            setSelectedSize(data.sizeValue);
                          }}
                          sizeDetails={quickViewData?.load?.sizeDetails}
                          selectedSize={selectedSize}
                        />
                      </div>
                      {!error && <div className="empty-div"></div>}
                      {error && <div className="error-text">{error}</div>}
                    </div>

                    <div className="add_to_cart_btn">
                      <a onClick={handleAddCart} className="btn">
                        Add to cart
                      </a>
                    </div>
                    <div className="product_sales_points">
                      <div className="product_sales_point">
                        <img src={Fast_Delivery} alt={Fast_Delivery} />
                        <p>Free Shipping On Prepaid & COD*</p>
                      </div>
                      <div className="product_sales_point">
                        <img className="globalIcon" src={Global} alt={Global} />
                        <p>Worldwide Shipping</p>
                      </div>
                      <div className="product_sales_point">
                        <span className="icon icon--inventory"></span>
                        <p>Available</p>
                      </div>
                    </div>
                    <div className="specifications">
                      <div className="specifications_heading">
                        <h3>Specifications</h3>
                      </div>
                      <Specifications
                        subCategory={quickViewData?.load?.subCategory}
                        ProductSpecifications={{
                          productDetails: quickViewData?.load?.productDetails,
                          otherDetails: quickViewData?.load?.otherDetails,
                          colorDetails: quickViewData?.load?.colorDetails,
                          colorFamily: quickViewData?.load?.colorFamily,
                        }}
                      />
                    </div>
                    <div className="product_other_info">
                      <CollapsibleList
                        collapsibleTitle="Description"
                        collapsibleValues={`${quickViewData?.load?.title} <br />
                      CARE: Dry Clean Only <br /> Model Size: Model is wearing ${quickViewData?.load.otherDetails[4].value.value} Size <br />
                      Model Height: ${quickViewData?.load.otherDetails[5].value.value}''
                      <br /> DISCLAIMER: Slight Colour variations may occur due to different
                      screen resolution.`}
                      />
                      <CollapsibleList
                        collapsibleTitle="Shipping information"
                        collapsibleValues={`
<p>We endeavor to provide a pleasant and zero-hassle shopping experience for you. All our policies and terms of use are crafted to ensure ease of purchase and support. We request you to thoroughly review them and make informed purchases.</p>
<p>We at Aachho, understand the importance of delivering your products timely and safely. Therefore, we partner with some of the most reputed Indian and international courier partners such as Bluedart, Delhivery, DHL, Aramex, etc., to ensure seamless delivery of all orders.</p>
<h2>Domestic &amp; International Shipping</h2>
<ul>
<li>
<p><b>Charges:</b> Aachho offers FREE SHIPPING ACROSS INDIA for all orders, prepaid as well as cash on delivery (COD). For orders from outside India, additional shipping fees is charged per kg and the total shipping charges depend on the weight of the total order parcel which is calculated at the time of checkout.</p>
</li>
<li>
<p><b>Tax and duties:</b> Please note that many countries charge varied import duties, customs charges and taxes on shipped items. All such additional charges are to be directly borne by the customer upon delivery. Hence, it is advisable to check your country’s taxes policies before placing an order with us because, once the order is placed, we do not offer returns or cancellations for international orders.</p>
<li>
<p><b>Credit note usage:</b> If you are issued any credit note, you shall receive it on your registered email ID. It can be applied only once on our official website and within&nbsp;3 months from the date of issue.</p>
</li>
</ul>`}
                      />
                    </div>
                    <div className="product_social_media_links">
                      <a
                        href={facebookShareUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-facebook-fill"></i>
                        Facebook
                      </a>
                      <a
                        href={twitterShareUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-twitter-x-fill"></i>
                        Twitter
                      </a>
                      <a
                        href={pinterestShareUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-pinterest-fill"></i>
                        Pinterest
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {quickViewData && lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={closeLightbox}
          plugins={[Video,Zoom,Fullscreen]}
          fullscreen={{ ref: fullscreenRef }}
          index={photoIndex}
          zoom={{ ref: zoomRef, scrollToZoom:true }}
          slides={quickViewData.load.images.map((imageURL) => {
            if (imageURL.includes("mp4")) {
              return {
                type: "video",
                poster:
                  "https://amarnathckr.online/api/v1/upload/" +
                  imageURL,
                sources: [
                  {
                    src:
                      "https://amarnathckr.online/api/v1/upload/" +
                      imageURL,
                    type: "video/mp4",
                  },
                ],
                autoPlay: true,
                playsInline: true,
                muted: true,
                controls: false,
                loop: true,
              };
            } else {
              return {
                src:
                  "https://amarnathckr.online/api/v1/upload/" +
                  imageURL,
              };
            }
          })}
        />
      )}
    </div>
  );
};

export default ProductQuickView;

import React from "react";
import "./SizeChartDrawer.css";
import SizeChart from "../SizeChart/SizeChart";

const SizeChartDrawer = ({
  isSizeChartOpen,
  toggleSizeChartDrawer,
  sizeDetails,
  shortName,
}) => {
  let result =
    sizeDetails &&
    Object.keys(sizeDetails).map((key) => ({
      standard: key,
      ...sizeDetails[key],
    }));

  console.log(result);

  result =
    sizeDetails &&
    result.map((items) => {
      console.log(items);
      let data = {
        STANDARD: items.standard,
        BUST: items["BUST SIZE"],
        WAIST: items["WAIST SIZE"],
      };

      if (items["TOP BUST"]) {
        data["TOP BUST"] = items["TOP BUST"];
      }
      if (items["TOP WAIST"]) {
        data["TOP WAIST"] = items["TOP WAIST"];
      }
      if (items["TOP LENGTH"] || items["LENGTH"] || items["CROP TOP LENGTH"]) {
        data["TOP LENGTH"] = items["TOP LENGTH"]
          ? items["TOP LENGTH"]
          : items["LENGTH"]
          ? items["LENGTH"]
          : items["CROP TOP LENGTH"];
      }
      if (items["BOTTOM WAIST"]) {
        data["BOTTOM WAIST"] = items["BOTTOM WAIST"];
      }

      if (items["BOTTOM LENGTH"]) {
        data["BOTTOM LENGTH"] = items["BOTTOM LENGTH"];
      }

      return data;
    });

  return (
    <div className="size-chart-drawer">
      {/* Overlay */}
      <div
        className={`overlay ${isSizeChartOpen ? "show" : ""}`}
        onClick={toggleSizeChartDrawer}
      ></div>

      {/* Sliding Drawer */}
      <div
        className={`size-chart-sliding-drawer-container  ${
          isSizeChartOpen ? "open" : ""
        }`}
      >
        <div className="size-chart-drawer-content">
          <div className="size-chart-drawer-header">
            <h2>Size Chart</h2>
            <i className="ri-close-fill" onClick={toggleSizeChartDrawer}></i>
          </div>
          <div className="size-chart-drawer__inner">
            <div className="size-chart-drawer__scrollable">
              <SizeChart sizeDetails={result} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizeChartDrawer;

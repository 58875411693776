import React, { useRef, useState } from "react";
import "./WatchAndShop.css";
import SectionHeadings from "../../Headings/SectionHeadings/SectionHeadings";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const WatchAndShop = ({ data }) => {
  return (
    <section className="section watch-and-shop-section">
      <SectionHeadings
        title="Watch and Shop"
        paragraph="Discover our wide range of women’s ethnic wear and western wear fashion"
      />
      <div className="watch-and-shop-container">
        <div className="watch-and-shop-items">
          <Swiper
            slidesPerView={6}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              340: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
            className="mySwiper"
          >
            {data &&
              data.map((items) => {
                const videourl =
                  items.images[
                    items.images.findIndex((i) => i.includes("mp4"))
                  ];

                console.log(videourl);

                return (
                  <SwiperSlide>
                    <div className="watch-and-shop-item">
                      <Link
                        to={
                          "/product-details/" +
                          items.title.split(" ").join("-").toLowerCase()
                        }
                      >
                        <video
                          src={
                            "https://amarnathckr.online/api/v1/upload/" +
                            videourl
                          }
                          playsInline
                          autoPlay
                          muted
                          loop
                        ></video>
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })}
            {/* <SwiperSlide>
              <div className="watch-and-shop-item">
                <Link href="">
                  <video
                    src={"https://amarnathckr.online/api/v1/upload/file-1709207164815.mp4"}
                    autoPlay
                    muted
                    loop
                  ></video>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="watch-and-shop-item">
                <Link href="">
                  <video
                    src={"https://amarnathckr.online/api/v1/upload/file-1709207164815.mp4"}
                    autoPlay
                    muted
                    loop
                  ></video>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="watch-and-shop-item">
                <Link href="">
                  <video
                    src={"https://amarnathckr.online/api/v1/upload/file-1709207164815.mp4"}
                    autoPlay
                    muted
                    loop
                  ></video>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="watch-and-shop-item">
                <Link href="">
                  <video
                    src={"https://amarnathckr.online/api/v1/upload/file-1709207164815.mp4"}
                    autoPlay
                    muted
                    loop
                  ></video>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="watch-and-shop-item">
                <Link href="">
                  <video
                    src={"https://amarnathckr.online/api/v1/upload/file-1709207164815.mp4"}
                    autoPlay
                    muted
                    loop
                  ></video>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="watch-and-shop-item">
                <Link href="">
                  <video
                    src={"https://amarnathckr.online/api/v1/upload/file-1709207164815.mp4"}
                    autoPlay
                    muted
                    loop
                  ></video>
                </Link>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default WatchAndShop;

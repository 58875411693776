import React, { useState } from "react";
import "./CollapsibleList.css";
import { DownArrow } from "../../assets/images";
import parse from "html-react-parser";

const CollapsibleList = ({ collapsibleTitle, collapsibleValues, keyVal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      key={keyVal}
      className={`product_collapsible-list ${isOpen ? "open" : ""}`}
    >
      <button
        className="product_collapsible-list-btn"
        onClick={toggleCollapsible}
      >
        {collapsibleTitle}
        <img
          src={DownArrow}
          alt={DownArrow}
          className={`${isOpen ? "open" : ""}`}
        />
        {/* <span className={`arrow-icon ${isOpen ? "open" : ""}`}></span> */}
      </button>
      <div className="product_collapsible-list-content">
        {/* {ReactHtmlParser(collapsibleValues)} */}
        {/* {ReactHtmlParser(collapsibleValues)} */}
        {parse(collapsibleValues)}
      </div>
    </div>
  );
};

export default CollapsibleList;

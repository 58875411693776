import React from "react";
import "./CategoryBanners.css";
import { Link } from "react-router-dom";
import SectionHeadings from "../Headings/SectionHeadings/SectionHeadings";

const CategoryBanners = ({ categoryBanner }) => {
  return (
    <section className="section categories_banners_home">
      <div className="container">
        <SectionHeadings
          title="Shop By Category"
          paragraph="Discover the wide varities off categories"
        />
        <div className="categories_banners_home_product_list">
          {categoryBanner?.map((items) => {
            return (
              <div key={items.homeBanner} className="cat_banners">
                <Link
                  to={
                    "/products/" + items.name.split(" ").join("-").toLowerCase()
                  }
                  state={items}
                  className="list-item"
                >
                  <div className="cat_banners-card">
                    <img
                      src={`https://amarnathckr.online/api/v1/upload/${items.homeBanner}`}
                      alt={items.homeBanner}
                    />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CategoryBanners;

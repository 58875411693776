import axios from 'axios'

const API = axios.create({
  baseURL: 'https://amarnathckr.online/api/v1/',
  withCredentials: true
})

export const getWithoutAuth = link => API.get(`${link}`)

export const getAnyApi = (link, token) =>
  API.get(`${link}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })

export const PostAnyApi = (link, input) => API.post(`${link}`, input)

export const postAnyAuth = (link, input, token) =>
  API.post(`${link}`, input, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })

export const uploadImage = (link, input, token,callBack) =>
  API.post(`${link}`, input, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    },
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      callBack(percentCompleted)
    }
  })

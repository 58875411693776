import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./MobileImageSliders.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import { product_img1 } from "../../assets/images";
import { product_img1_H2 } from "../../assets/images";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import LazyImage from "../utils/LazyImage";

const MobileImageSliders = ({ images }) => {
  console.log(images);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const zoomRef = React.useRef(null);
  const fullscreenRef = React.useRef(null);
  const openLightbox = (index) => {
    // Check if all images are loaded before opening the Lightbox
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setZoomLevel(1);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(1, prevZoom - 0.1));
  };

  const gotoNext = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const gotoPrev = () => {
    setPhotoIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const renderContent = (imageUrl, index) => {
    if (imageUrl.includes(".mp4")) {
      // Check if it's a video file
      return (
        <video
          width="100%"
          height="auto"
          src={imageUrl}
          controls={false}
          playsInline
          autoPlay
          muted
          loop
          preload="none"
          poster={"https://amarnathckr.online/api/v1/upload/" + images[0]}
        />
      );
    } else {
      // Image
      return <LazyImage src={imageUrl} alt={`Image ${index + 1}`} />;
    }
  };

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper mobile_image_sliders"
      >
        {images?.map((imageUrl, index) => (
          <SwiperSlide key={index} onClick={() => openLightbox(index)}>
            <div className="product_details_slider_img">
              {renderContent(
                "https://amarnathckr.online/api/v1/upload/" + imageUrl,
                index
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={closeLightbox}
          plugins={[Video, Zoom, Fullscreen]}
          fullscreen={{ ref: fullscreenRef }}
          index={photoIndex}
          zoom={{ ref: zoomRef, scrollToZoom: true }}
          slides={images.map((imageURL) => {
            if (imageURL.includes("mp4")) {
              return {
                type: "video",

                poster: "https://amarnathckr.online/api/v1/upload/" + imageURL,
                sources: [
                  {
                    src: "https://amarnathckr.online/api/v1/upload/" + imageURL,
                    type: "video/mp4",
                  },
                ],
                autoPlay: true,
                playsInline: true,
                muted: true,
                controls: false,
                loop: true,
              };
            } else {
              return {
                src: "https://amarnathckr.online/api/v1/upload/" + imageURL,
              };
            }
          })}
        />
      )}
    </>
  );
};

export default MobileImageSliders;

import React, { useEffect, useState } from "react";
import {
  HeroSlider,
  NewArrivals,
  OtherBanners,
  SubCollectionCircles,
} from "../..";

import "./Home.css";

import { banner_image1, banner_image_mobile1 } from "../../../assets/images";
import { getWithoutAuth } from "../../../api/api";
import { Link } from "react-router-dom";
import CategoryBanners from "../../CategoryBanners/CategoryBanners";
import FAQ from "../../FAQ/FAQ";
import PreFooter from "../../PreFooter/PreFooter";
import TrendingCollection from "../../TrendingCollection/TrendingCollection";
import Occasion from "../../Sections/Occasion/Occasion";
import WatchAndShop from "../../Sections/WatchAndShop/WatchAndShop";
import CustomersReview from "../../Sections/CustomersReview/CustomersReview";
import USP from "../../Sections/USP/USP";

const Home = ({
  homeData,
  otherBanner,
  newArrivals,
  categoryBanner,
  sales,
  faqs,
  category,
  trendingCollection,
  videos,
  ratings,
  occasionData,
}) => {
  const [subEmpty, setSubEmpty] = useState(false);

  console.log(videos);

  let otherBanner1 =
    otherBanner && otherBanner.filter((items) => items.name === "Banner 1")[0];
  let otherBanner2 =
    otherBanner && otherBanner.filter((items) => items.name === "Banner 2")[0];
  let otherBanner3 =
    otherBanner && otherBanner.filter((items) => items.name === "Banner 3")[0];

    console.log("first")

  if(otherBanner && otherBanner1.isActive === false){
    otherBanner1 = null;
  }
  
  if(otherBanner &&otherBanner2.isActive === false){
    otherBanner2 = null;
  }

  if(otherBanner &&otherBanner3.isActive === false){
    otherBanner3 = null;
  }



  return (
    <>
      {!subEmpty && (
        <section className="SubCollectionCirclesHome">
          <SubCollectionCircles
            isEmpty={(data) => setSubEmpty(data)}
            subCollectionsData={[""]}
            isHome={true}
          />
        </section>
      )}
      <HeroSlider sliderData={homeData ? homeData : []} />
      {newArrivals && <NewArrivals newArrivals={newArrivals} />}
      {otherBanner1 && (
        <OtherBanners
          bannerImage={
            otherBanner1
              ? "https://amarnathckr.online/api/v1/upload/" + otherBanner1.imageLG
              : banner_image1
          }
          bannerImageMobile={
            otherBanner1
              ? "https://amarnathckr.online/api/v1/upload/" + otherBanner1.imageSM
              : banner_image_mobile1
          }
          redirectTo={otherBanner1 ? otherBanner1.url : null}
        />
      )}
      <TrendingCollection trendingCollection={trendingCollection} />
      <WatchAndShop data={videos} />
      {otherBanner2 && (
        <OtherBanners
          bannerImage={
            otherBanner2
              ? "https://amarnathckr.online/api/v1/upload/" + otherBanner2.imageLG
              : banner_image1
          }
          bannerImageMobile={
            otherBanner2
              ? "https://amarnathckr.online/api/v1/upload/" + otherBanner2.imageSM
              : banner_image_mobile1
          }
          redirectTo={otherBanner2 ? otherBanner2.url : null}
        />
      )}
      {sales && <NewArrivals newArrivals={sales} title={"Sales"} />}
      {categoryBanner && <CategoryBanners categoryBanner={categoryBanner} />}
      {otherBanner3 && (
        <OtherBanners
          bannerImage={
            otherBanner3
              ? "https://amarnathckr.online/api/v1/upload/" + otherBanner3.imageLG
              : banner_image1
          }
          bannerImageMobile={
            otherBanner3
              ? "https://amarnathckr.online/api/v1/upload/" + otherBanner3.imageSM
              : banner_image_mobile1
          }
          redirectTo={otherBanner3 ? otherBanner3.url : null}
        />
      )}
      {occasionData && <Occasion occasionData={occasionData} />}
      {ratings && <CustomersReview ratings={ratings} />}
      <USP />
      <FAQ list={faqs} />
      <PreFooter category={category} />
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";

import "./ProductDetails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

import {
  Fast_Delivery,
  Global,
  HeartIcon,
  product_img1,
  product_img1_H,
  product_img1_H2,
  product_img1_H3,
} from "../../../assets/images";
import {
  CollapsibleList,
  MobileImageSliders,
  SizeChartDrawer,
  SizeRadioButtons,
  Specifications,
} from "../../";
import { useLocation } from "react-router-dom";
import { getAnyApi, getWithoutAuth, postAnyAuth } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer, toggleLoading, toggleTrigger } from "../../../store";
import LazyImage from "../../utils/LazyImage";
import { Breadcrumbs } from "../../Headings";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import Video from "yet-another-react-lightbox/plugins/video";

const ProductDetails = () => {
  let location = useLocation();

  console.log(location.state);

  const [productData, setProductData] = useState();
  const [selectedSize, setSelectedSize] = useState(null);
  const zoomRef = React.useRef(null);
  const fullscreenRef = React.useRef(null);
  const globalTrigger = useSelector((state) => state.globalTrigger);

  console.log(globalTrigger);

  useEffect(() => {
    console.log("triggered");

    console.log("first " + location.state);
    if (location.state && location.state.id) {
      dispatch(toggleLoading());
      getWithoutAuth("product/details?productId=" + location.state.id)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            console.log(res.data);
            setProductData(res.data);
            setSelectedSize(null);
          }
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    }
  }, [globalTrigger, location.state]);

  const shareUrl = "https://example.com";
  const title = "Check out this amazing content!";

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    shareUrl
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    shareUrl
  )}&text=${encodeURIComponent(title)}`;
  const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    shareUrl
  )}&description=${encodeURIComponent(title)}`;

  const [isSizeChartOpen, setSizeChartOpen] = useState(false);

  const toggleSizeChartDrawer = () => {
    setSizeChartOpen(!isSizeChartOpen);
    if (isSizeChartOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isWishlisted, setIsWish] = useState(false);
  const [error, setError] = useState(null);

  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location.state) {
      const title = location.pathname
        .split("/")[2]
        .split("-")
        .join(" ")
        .toUpperCase();
      dispatch(toggleLoading());
      getWithoutAuth("product/get-product-id?title=" + title)
        .then((res) => {
          console.log(res.data);
          location.state = { id: res.data.load };
          dispatch(toggleTrigger());
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.id) {
      const allList = JSON.parse(localStorage.getItem("wishlist"));

      console.log(allList);

      let filteredData = allList?.filter(
        (items) => items.id === location.state.id
      );

      console.log(filteredData);
      if (filteredData && filteredData.length > 0) {
        setIsWish(true);
      }
    }
  }, [token, location.state]);

  console.log(isWishlisted);

  const handleRemoveWishlist = () => {
    const id = location.state.id;
    console.log(location.state);
    if (token) {
      dispatch(toggleLoading());
      postAnyAuth("wishlist/remove", { productIds: [id] }, token)
        .then((res) => {
          console.log(res.data);
          let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

          localWishlist = localWishlist.filter((items) => items.id !== id);

          console.log(localWishlist);

          localStorage.setItem("wishlist", JSON.stringify(localWishlist));

          setIsWish(false);
          dispatch(toggleTrigger());
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    } else {
      let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

      localWishlist = localWishlist.filter((items) => items.id !== id);

      console.log(localWishlist);

      localStorage.setItem("wishlist", JSON.stringify(localWishlist));

      setIsWish(false);
      dispatch(toggleTrigger());
    }
  };

  const referal = location?.state?.referal;

  console.log(referal);

  const images = productData?.load?.images;

  const openLightbox = (index) => {
    document.documentElement.style.overflow = "hidden";
    // Check if all images are loaded before opening the Lightbox
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    document.documentElement.style.overflow = "unset";
    setLightboxOpen(false);
    setZoomLevel(1);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(1, prevZoom - 0.1));
  };

  const gotoNext = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const gotoPrev = () => {
    setPhotoIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleAddCart = () => {
    const productId = location.state.id;
    console.log(location.state, productId);
    let localCart = JSON.parse(localStorage.getItem("cart"));

    let vsku = "";

    if (referal) {
      const index = productData.load.subCategory.findIndex(
        (items) => items.name === referal
      );
      if (index !== -1) {
        vsku = `${productData.load.subCategory[index].vksu}.${
          productData.load.sku.split(".")[1]
        }`;
      } else {
        vsku = `${productData.load.subCategory[0].vksu}.${
          productData.load.sku.split(".")[1]
        }`;
      }
    } else {
      vsku = `${productData.load.subCategory[0].vksu}.${
        productData.load.sku.split(".")[1]
      }`;
    }

    console.log("sku data " + vsku);

    if (!selectedSize) {
      setError("Please select size");
      return;
    }

    if (token) {
      dispatch(toggleLoading());
      postAnyAuth(
        "cart/add",
        {
          products: [{ productId: productId, size: selectedSize, vsku: vsku }],
        },
        token
      )
        .then((res) => {
          console.log("added to cart");

          if (localCart) {
            const existingProductIndex = localCart.findIndex(
              (item) => item.id === productId && item.size === selectedSize
            );

            if (existingProductIndex !== -1) {
              // Product already exists in the cart, increase the quantity
              localCart[existingProductIndex].quantity += 1;
            } else {
              // Product doesn't exist in the cart, add it
              localCart.push({
                images: productData.load.images,
                title: productData.load.title,
                fcp: productData.load.fcp,
                mrp: productData.load.mrp,
                td: productData.load.td,
                size: selectedSize,
                quantity: 1,
                id: productId,
                vsku: vsku,
              });
            }
          } else {
            localCart = [
              {
                images: productData.load.images,
                title: productData.load.title,
                fcp: productData.load.fcp,
                mrp: productData.load.mrp,
                td: productData.load.td,
                size: selectedSize,
                quantity: 1,
                id: productId,
                vsku: vsku,
              },
            ];
          }

          console.log(localCart);
          localStorage.setItem("cart", JSON.stringify(localCart));
          dispatch(toggleTrigger());
          dispatch(toggleDrawer());

          dispatch(toggleLoading());
        })
        .catch((err) => {
          dispatch(toggleLoading());
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
          // Handle error
        });
    } else {
      if (localCart) {
        const existingProductIndex = localCart.findIndex(
          (item) => item.id === productId && item.size === selectedSize
        );

        if (existingProductIndex !== -1) {
          // Product already exists in the cart, increase the quantity
          localCart[existingProductIndex].quantity += 1;
        } else {
          // Product doesn't exist in the cart, add it
          localCart.push({
            images: productData.load.images,
            title: productData.load.title,
            fcp: productData.load.fcp,
            mrp: productData.load.mrp,
            td: productData.load.td,
            size: selectedSize,
            quantity: 1,
            id: productId,
            vsku: vsku,
          });
        }
      } else {
        localCart = [
          {
            images: productData.load.images,
            title: productData.load.title,
            fcp: productData.load.fcp,
            mrp: productData.load.mrp,
            td: productData.load.td,
            size: selectedSize,
            quantity: 1,
            id: productId,
            vsku: vsku,
          },
        ];
      }

      console.log(localCart);
      localStorage.setItem("cart", JSON.stringify(localCart));
      dispatch(toggleTrigger());
      dispatch(toggleDrawer());
    }
  };

  console.log(selectedSize);

  const handleAddWishlist = () => {
    const id = location.state.id;
    let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

    if (token) {
      dispatch(toggleLoading());
      getAnyApi("wishlist/add?productID=" + id, token)
        .then((res) => {
          console.log("added to wishlist");
          if (localWishlist && localWishlist.length > 0) {
            const index = localWishlist.filter((items) => items.id === id);

            if (index.length > 0) {
              localWishlist.push({
                images: productData.load.images,
                title: productData.load.title,
                fcp: productData.load.fcp,
                mrp: productData.load.mrp,
                td: productData.load.td,
                sizeList: Object.keys(productData.load.sizeDetails),
                id,
              });
            }
            console.log(localWishlist);

            setIsWish(true);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));

            dispatch(toggleTrigger());
          } else {
            localWishlist = [
              {
                images: productData.load.images,
                title: productData.load.title,
                fcp: productData.load.fcp,
                mrp: productData.load.mrp,
                td: productData.load.td,
                sizeList: Object.keys(productData.load.sizeDetails),
                id,
              },
            ];
          }
          console.log(localWishlist);

          setIsWish(true);

          localStorage.setItem("wishlist", JSON.stringify(localWishlist));

          dispatch(toggleTrigger());
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          if (localWishlist && localWishlist.length > 0) {
            const index = localWishlist.filter((items) => items.id === id);

            if (index.length > 0) {
              localWishlist.push({
                images: productData.load.images,
                title: productData.load.title,
                fcp: productData.load.fcp,
                mrp: productData.load.mrp,
                td: productData.load.td,
                sizeList: Object.keys(productData.load.sizeDetails),
                id,
              });
            }
            console.log(localWishlist);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));
            dispatch(toggleTrigger());
          } else {
            localWishlist = [
              {
                images: productData.load.images,
                title: productData.load.title,
                fcp: productData.load.fcp,
                mrp: productData.load.mrp,
                td: productData.load.td,
                sizeList: Object.keys(productData.load.sizeDetails),
                id,
              },
            ];
            console.log(localWishlist);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));
            dispatch(toggleTrigger());
          }
          dispatch(toggleLoading());
        });
    } else {
      if (localWishlist && localWishlist.length > 0) {
        const index = localWishlist.filter((items) => items.id !== id);

        console.log(productData);

        if (index.length > 0) {
          localWishlist.push({
            images: productData.load.images,
            title: productData.load.title,
            fcp: productData.load.fcp,
            mrp: productData.load.mrp,
            td: productData.load.td,
            sizeList: Object.keys(productData.load.sizeDetails),
            id,
          });
        }
        console.log(localWishlist);
        localStorage.setItem("wishlist", JSON.stringify(localWishlist));
        setIsWish(true);
        dispatch(toggleTrigger());
      } else {
        localWishlist = [
          {
            images: productData.load.images,
            title: productData.load.title,
            fcp: productData.load.fcp,
            mrp: productData.load.mrp,
            td: productData.load.td,
            sizeList: Object.keys(productData.load.sizeDetails),
            id,
          },
        ];
      }
      console.log(localWishlist);
      localStorage.setItem("wishlist", JSON.stringify(localWishlist));
      setIsWish(true);
      dispatch(toggleTrigger());
    }
  };

  console.log(productData?.load);

  if (!productData) {
    return null;
  }

  const renderContent = (imageUrl, index) => {
    if (imageUrl.endsWith(".mp4")) {
      // Check if it's a video file
      return (
        <video
          width="100%"
          height="auto"
          src={imageUrl}
          controls={false}
          playsInline
          autoPlay
          muted
          loop
          preload="none"
          poster={
            "https://amarnathckr.online/api/v1/upload/" +
            productData.load.images[0]
          }
        />
      );
    } else {
      // Image
      return <LazyImage src={imageUrl} alt={`Image ${index + 1}`} />;
    }
  };

  console.log(selectedSize);

  return (
    <>
      <Helmet>
        <title>{productData?.load?.seoData.title}</title>
        <meta
          name="description"
          content={productData?.load?.seoData.description}
        />
        {productData?.load?.seoData?.metaTags?.map((items) => {
          console.log(items);
          return (
            <meta key={items.name} name={items.name} content={items.content} />
          );
        })}
      </Helmet>
      <SizeChartDrawer
        sizeDetails={productData?.load?.sizeDetails}
        shortName={productData?.subCategory.shortName}
        isSizeChartOpen={isSizeChartOpen}
        toggleSizeChartDrawer={toggleSizeChartDrawer}
      />
      <section className="section">
        <Breadcrumbs />
        <div className="container details_product_container">
          <div className="details_image_grid common-clearfix">
            <div className="desktop_image_grid">
              {productData &&
                productData.load.images.map((imageUrl, index) => (
                  <div
                    className="details_image-sizing"
                    key={index}
                    onClick={() => openLightbox(index)}
                  >
                    <div className="image-grid-imageContainer">
                      {renderContent(
                        "https://amarnathckr.online/api/v1/upload/" + imageUrl,
                        index
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className="mobile_image_grid">
              <MobileImageSliders images={productData?.load?.images} />
            </div>
          </div>
          <div className="details_all_details">
            <h2 className="details-single__title">
              {productData?.load?.title}
            </h2>
            <div className="details_star_rating">
              <i className="ri-star-fill"></i>
              <i className="ri-star-fill"></i>
              <i className="ri-star-fill"></i>
              <i className="ri-star-half-fill"></i>
              <i className="ri-star-line"></i>
              <span>7 Reviews</span>
            </div>
            <div className="details_all_pricing">
              <div className="details_all_pricing_prices">
                <span className="prices_final-price">
                  Rs. {productData?.load.fcp}
                </span>
                <span className="prices_main-price">
                  Rs. {productData?.load.mrp}
                </span>
                <span className="prices_discount">
                  {productData?.load.td}% off
                </span>
              </div>
              <div className="details_all_pricing_savings">
                <span className="details_all_pricing_saving_price">
                  Save{" "}
                  <b>Rs. {productData?.load.fcp - productData?.load.mrp}</b>
                </span>
                <p className="text-info-label">Inclusive of all taxes</p>
              </div>
            </div>
            <div className="details_details_sizes">
              <div className="details_details_sizes_chart">
                <p>SIZE</p>
                <a onClick={toggleSizeChartDrawer} className="size_chart-btn">
                  Size chart <i className="ri-arrow-right-line"></i>
                </a>
              </div>
              <div className="details_sizes_radio">
                <SizeRadioButtons
                  onChange={(data) => {
                    setError(null);
                    setSelectedSize(data.sizeValue);
                  }}
                  sizeDetails={productData?.load?.sizeDetails}
                  selectedSize={selectedSize}
                />
              </div>
              {!error && <div className="empty-div"></div>}
              {error && <div className="error-text">{error}</div>}
            </div>
            <div className="wishlist_and_add_cart-btn">
              <div className="wishlist_fav_btn">
                {isWishlisted > 0 ? (
                  <a onClick={handleRemoveWishlist} className="fav-btn">
                    <i className="ri-heart-3-fill"></i>
                  </a>
                ) : (
                  <a onClick={handleAddWishlist} className="fav-btn">
                    <i className="ri-heart-3-line"></i>
                  </a>
                )}
              </div>
              <div className="add_to_cart_btn">
                <a onClick={handleAddCart} className="btn">
                  Add to cart
                </a>
              </div>
            </div>
            <div className="details_sales_points">
              <div className="details_sales_point">
                <img src={Fast_Delivery} alt={Fast_Delivery} />
                <p>Free Shipping On Prepaid & COD*</p>
              </div>
              <div className="details_sales_point">
                <img className="globalIcon" src={Global} alt={Global} />
                <p>Worldwide Shipping</p>
              </div>
              <div className="details_sales_point">
                <span className="icon icon--inventory"></span>
                <p>Available</p>
              </div>
            </div>
            <div className="specifications">
              <div className="specifications_heading">
                <h3>Specifications</h3>
              </div>
              <Specifications
                subCategory={productData?.load?.subCategory}
                ProductSpecifications={{
                  productDetails: productData?.load?.productDetails,
                  otherDetails: productData?.load?.otherDetails,
                  colorDetails: productData?.load?.colorDetails,
                  colorFamily: productData?.load?.colorFamily,
                }}
              />
            </div>
            <div className="details_other_info">
              <CollapsibleList
                collapsibleTitle="Description"
                collapsibleValues={`${productData?.load?.title} <br />
        CARE: Dry Clean Only <br /> Model Size: Model is wearing ${productData?.load.otherDetails[4].value.value} Size <br />
        Model Height: ${productData?.load.otherDetails[5].value.value}''
        <br /> DISCLAIMER: Slight Colour variations may occur due to different
        screen resolution.`}
              />
              <CollapsibleList
                collapsibleTitle="Shipping information"
                collapsibleValues={`
                <p>We endeavor to provide a pleasant and zero-hassle shopping experience for you. All our policies and terms of use are crafted to ensure ease of purchase and support. We request you to thoroughly review them and make informed purchases.</p>
                <p>We at Aachho, understand the importance of delivering your products timely and safely. Therefore, we partner with some of the most reputed Indian and international courier partners such as Bluedart, Delhivery, DHL, Aramex, etc., to ensure seamless delivery of all orders.</p>
                <h2>Domestic &amp; International Shipping</h2>
                <ul>
                <li>
                <p><b>Charges:</b> Aachho offers FREE SHIPPING ACROSS INDIA for all orders, prepaid as well as cash on delivery (COD). For orders from outside India, additional shipping fees is charged per kg and the total shipping charges depend on the weight of the total order parcel which is calculated at the time of checkout.</p>
                </li>
                <li>
                <p><b>Tax and duties:</b> Please note that many countries charge varied import duties, customs charges and taxes on shipped items. All such additional charges are to be directly borne by the customer upon delivery. Hence, it is advisable to check your country’s taxes policies before placing an order with us because, once the order is placed, we do not offer returns or cancellations for international orders.</p>
                <li>
                <p><b>Credit note usage:</b> If you are issued any credit note, you shall receive it on your registered email ID. It can be applied only once on our official website and within&nbsp;3 months from the date of issue.</p>
                </li>
                </ul>`}
              />
            </div>
            <div className="details_social_media_links">
              <a
                href={facebookShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-facebook-fill"></i>
                Facebook
              </a>
              <a
                href={twitterShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-twitter-x-fill"></i>
                Twitter
              </a>
              <a
                href={pinterestShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-pinterest-fill"></i>
                Pinterest
              </a>
            </div>
          </div>
        </div>

        <Lightbox
          open={lightboxOpen}
          close={closeLightbox}
          plugins={[Video, Zoom, Fullscreen]}
          fullscreen={{ ref: fullscreenRef }}
          index={photoIndex}
          zoom={{ ref: zoomRef, scrollToZoom: true }}
          // inline={{
          //   style: { width: "100%", maxWidth: "900px", aspectRatio: "3 / 2" },
          // }}
          slides={productData.load.images.map((imageURL) => {
            if (imageURL.includes("mp4")) {
              return {
                type: "video",
                poster: "https://amarnathckr.online/api/v1/upload/" + imageURL,
                sources: [
                  {
                    src: "https://amarnathckr.online/api/v1/upload/" + imageURL,
                    type: "video/mp4",
                  },
                ],
                autoPlay: true,
                playsInline: true,
                muted: true,
                controls: true,
                controlsList: "nodownload",
                loop: true,
              };
            } else {
              return {
                src: "https://amarnathckr.online/api/v1/upload/" + imageURL,
              };
            }
          })}
        />
      </section>
    </>
  );
};

export default ProductDetails;

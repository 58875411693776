import React, { useState } from "react";
import "./TrendingCollection.css";
import ProductQuickView from "../ProductQuickView/ProductQuickView";
import SectionHeadings from "../Headings/SectionHeadings/SectionHeadings";
import ProductCard from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";

const TrendingCollection = ({ trendingCollection }) => {
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const toggleQuickModel = () => {
    setIsQuickViewOpen(!isQuickViewOpen);
    if (isQuickViewOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  return (
    <section className="section new_arrivals">
      <ProductQuickView
        isQuickViewOpen={isQuickViewOpen}
        toggleQuickModel={toggleQuickModel}
      />
      <div className="new-arrivals_container container">
        {/* <SectionHeadings
          title={"Trending New Collections"}
          paragraph="Discover our wide range of women’s ethnic wear and western wear fashion"
        /> */}
        <div className="new-arrivals_product_list">
          {trendingCollection &&
            trendingCollection.map((item) => (
              <div className="products" key={item.title}>
                <ProductCard
                  toggleQuickModel={toggleQuickModel}
                  product_images={item.images}
                  productTitle={item.title}
                  currencyCode="Rs."
                  finalPrice={item.fcp}
                  originalPrice={item.mrp}
                  salePercentage={item.td}
                  id={item.id}
                  sizeList={item.sizeList}
                  promo={item.promo ? item.promo : null}
                  item={item}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="btn-container">
        <Link className="btn" to={"/products/trending"}>
          View More
        </Link>
      </div>
    </section>
  );
};

export default TrendingCollection;

import React, { useRef, useEffect, useState } from "react";

function LazyImage(props) {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    observer.observe(imgRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <img
      className={props.classess}
      ref={imgRef}
      src={isVisible ? props.src : ""}
      alt={props.alt}
      loading="lazy"
    />
  );
}

export default LazyImage
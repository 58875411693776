import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import { Announcement, OffersHeading } from "../Headings";
import logo from "../../assets/images/logo/FGPL.png";
import searchGrowth from "../../assets/images/icons/search_growth.webp";
import {
  CartIcon,
  Fast_Delivery,
  HeartIcon,
  Menu,
  SaleGif,
  StoreIcon,
  UserIcon,
} from "../../assets/images";
import { PostAnyApi, getWithoutAuth } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { subscribeSearch, toggleLoading } from "../../store";

const Header = ({ toggleCartDrawer, menuActive, setMenuActive }) => {
  const [currentMenuTitle, setCurrentMenuTitle] = useState("");
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [categoryList, setCategoryList] = useState(null);

  const [cartCount, setCartCount] = useState(null);
  const [wishCount, setWishCount] = useState(null);
  const [timeout, setTimeoutTimer] = useState();
  const [promotion, setPromotion] = useState(null);

  const globalTrigger = useSelector((state) => state.globalTrigger);

  const location = useLocation();

  useEffect(() => {
    console.log(location)
    PostAnyApi("user-session", location);
  }, [location]);

  useEffect(() => {
    const wishData = JSON.parse(localStorage.getItem("wishlist"));
    const cartData = JSON.parse(localStorage.getItem("cart"));

    if (wishData) {
      setWishCount(wishData.length);
    } else {
      setWishCount(0);
    }
    if (cartData) {
      setCartCount(cartData.length);
    } else {
      setCartCount(0);
    }
  }, [globalTrigger, localStorage.getItem("wishlist")]);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
    setSubMenuActive(false);
    if (subMenuActive) {
      setSubMenuActive(true);
    } else {
      setSubMenuActive(false);
    }
  };

  const showSubMenu = (hasChildren) => {
    const subMenu = hasChildren.querySelector(".sub-menu");
    if (subMenu) {
      subMenu.classList.add("active");
      subMenu.style.animation = "slideLeft 0.5s ease forwards";
      const menuTitle =
        hasChildren?.querySelector("i")?.parentNode?.childNodes[0]?.textContent;
      setCurrentMenuTitle(menuTitle);
      setSubMenuActive(true);
    }
  };

  const hideSubMenu = () => {
    const subMenu = document.querySelector(
      ".menu-item-has-children .sub-menu.active"
    );
    if (subMenu) {
      subMenu.style.animation = "slideRight 0.5s ease forwards";
      setTimeout(() => {
        subMenu.classList.remove("active");
        setSubMenuActive(false);
      }, 300);
      setCurrentMenuTitle("");
    }
  };

  const handleOverlayClick = () => {
    setMenuActive(false);
    setSubMenuActive(false);
    if (subMenuActive) {
      setSubMenuActive(true);
    } else {
      setSubMenuActive(false);
    }
  };

  const handleMenuClick = (e) => {
    if (!menuActive) {
      return;
    }
    if (e.target.closest(".menu-item-has-children")) {
      const hasChildren = e.target.closest(".menu-item-has-children");
      showSubMenu(hasChildren);
    }
  };

  const handleGoBackClick = () => {
    hideSubMenu();
  };

  const handleMenuTriggerClick = () => {
    toggleMenu();
  };

  const handleLinkClick = () => {
    if (menuActive) {
      toggleMenu();
    }
  };

  useEffect(() => {
    document
      .querySelector(".menu-overlay")
      ?.addEventListener("click", handleOverlayClick);

    window.addEventListener("resize", () => {
      if (window.innerWidth > 991 && menuActive) {
        toggleMenu();
      }
    });

    return () => {
      document
        .querySelector(".menu-overlay")
        ?.removeEventListener("click", handleOverlayClick);
      window.removeEventListener("resize", () => {
        if (window.innerWidth > 991 && menuActive) {
          toggleMenu();
        }
      });
    };
  }, [menuActive]);

  const buildHeaderLising = (list) => {
    return list.map((items) => {
      if (items.subcategories.length <= 1) {
        return {
          title: items.name,
          path:
            "/products/" +
            items.subcategories[0].name.split(" ").join("-").toLowerCase(),
          state: items,
        };
      } else {
        return {
          title: items.name,
          path: "/products/" + items.name.split(" ").join("-").toLowerCase(),
          state: items,
          children: items.subcategories.map((data) => ({
            title: data.name,
            path: "/products/" + data.name.split(" ").join("-").toLowerCase(),
            state: data,
          })),
        };
      }
    });
  };

  useEffect(() => {
    dispatch(toggleLoading());
    getWithoutAuth("/category/get_all")
      .then((res) => {
        console.log(res.data);
        const headerList = buildHeaderLising(res.data.load);
        setCategoryList(headerList);
        setPromotion(res.data.promotion);
        dispatch(toggleLoading());
      })
      .catch((err) => {
        dispatch(toggleLoading());
      });
  }, []);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const NavLinks = () => {
    return categoryList?.map((item, index) => {
      return (
        <li
          className={item.children ? "menu-item-has-children" : ""}
          key={index}
        >
          <Link to={item.path} state={item.state} onClick={handleLinkClick}>
            {item.title}
          </Link>
          {item.children && (
            <div className="sub-menu single-column-menu">
              <div className="list-item">
                <ul>
                  {item.children.map((subItem, subIndex) => {
                    return (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          state={subItem.state}
                          onClick={handleLinkClick}
                        >
                          {capitalize(subItem.title)}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </li>
      );
    });
  };

  console.log(cartCount, wishCount);

  const navigate = useNavigate();

  const search = useSelector((state) => state.search);

  const dispatch = useDispatch();

  const handleSearch = (search) => {
    clearTimeout(timeout);
    const newTimer = setTimeout(() => {
      dispatch(subscribeSearch(search));
      navigate("/products/new");
    }, 600);
    setTimeoutTimer(newTimer);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchKeyword(value);
    handleSearch(value);
  };

  return (
    <>
      <Announcement promotion={promotion} />
      <OffersHeading />
      <div className="header-wrapper">
        <div className="upper_header container">
          <div className="search-container">
            <div className="search-icon">
              <i className="ri-search-2-line"></i>
            </div>
            <div className="clear-button">✖</div>
            <input
              type="text"
              className="search-input"
              value={searchKeyword}
              onChange={handleInputChange}
              placeholder="Search..."
            />
            <div
              className={`search-dropdown ${
                searchKeyword.length > 0 && "hide-search"
              }`}
            >
              <div className="search_heading">
                <img src={searchGrowth} alt="" />
                <span>Trending Searches</span>
              </div>

              {categoryList &&
                categoryList.map((item) => {
                  return (
                    <div key={item.path} className={"search_links"}>
                      <Link
                        to={item.path}
                        state={item.state}
                        onClick={handleLinkClick}
                      >
                        <img src={searchGrowth} alt="" />
                        <span>{item.title}</span>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="menu_trigger">
            <a onClick={handleMenuTriggerClick}>
              <img src={Menu} alt={Menu} />
            </a>
          </div>
          <div className="logo_area">
            <div className="company_logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="buttons_area">
            <div className="site-nav__icons">
              <Link
                to={"/account/profile"}
                className="site-nav__link link_option_hidden"
              >
                <img src={UserIcon} alt={UserIcon} />
              </Link>
              <Link
                to="/account/wishlist"
                className="site-nav__link wishlist-has-item"
              >
                <img src={HeartIcon} alt={HeartIcon} />
                {wishCount > 0 && <span className="red-dot"></span>}
              </Link>
              <Link
                className="site-nav__link cart-has-item"
                onClick={toggleCartDrawer}
              >
                <img src={CartIcon} alt={CartIcon} />
                {cartCount > 0 && (
                  <span className="red-dot-with-text">{cartCount}</span>
                )}
              </Link>
              <Link
                to="/our-stores"
                className="site-nav__link link_option_hidden"
                href="/#"
              >
                <img src={StoreIcon} alt={StoreIcon} />
              </Link>
              <Link to="/track-orders" className="site-nav__link" href="/#">
                <img src={Fast_Delivery} alt={Fast_Delivery} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <header className="header">
        <div className="container">
          <div className="row v-center">
            <div className="header-item item-center">
              <div
                className={`menu-overlay ${menuActive ? "active" : ""}`}
                onClick={toggleMenu}
              ></div>
              <nav
                className={`menu ${menuActive ? "active" : ""}`}
                onClick={handleMenuClick}
              >
                <div
                  className={`mobile-menu-head ${
                    subMenuActive ? "active" : ""
                  }`}
                >
                  <div className="go-back" onClick={handleGoBackClick}>
                    <i className="ri-arrow-left-s-line"></i>
                  </div>
                  <div className="current-menu-title">{currentMenuTitle}</div>
                  <div className="mobile-menu-close" onClick={toggleMenu}>
                    &times;
                  </div>
                </div>
                <ul className="menu-main">
                  <li>
                    <Link to="/products/new" onClick={handleLinkClick}>
                      New
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/sales" onClick={handleLinkClick}>
                      <img src={SaleGif} alt="SaleGif" className="sale_gif" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/best-seller" onClick={handleLinkClick}>
                      Bestseller
                    </Link>
                  </li>
                  <NavLinks />
                  <li className="link_option_display">
                    <a onClick={handleLinkClick}>Log in</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

import React, { useState } from "react";
import "./NewArrivals.css";
import ProductCard from "../../ProductCard/ProductCard";
import SectionHeadings from "../../Headings/SectionHeadings/SectionHeadings";

import {
  product_img1,
  product_img1_H,
  product_img1_H2,
  product_img1_H3,
  product_img2,
  product_img2_H,
  product_img2_H2,
  product_img2_H3,
  product_img3,
  product_img3_H,
  product_img3_H2,
  product_img3_H3,
  product_img4,
  product_img4_H,
  product_img4_H2,
  product_img4_H3,
} from "../../../assets/images";
import ProductQuickView from "../../ProductQuickView/ProductQuickView";
import { Link } from "react-router-dom";

const NewArrivals = ({ newArrivals, title }) => {
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const toggleQuickModel = () => {
    setIsQuickViewOpen(!isQuickViewOpen);
    if (isQuickViewOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  console.log(newArrivals);

  return (
    <section className="section new_arrivals">
      <ProductQuickView
        isQuickViewOpen={isQuickViewOpen}
        toggleQuickModel={toggleQuickModel}
      />
      <div className="new-arrivals_container container">
        <SectionHeadings
          title={title ? title : "New Arrivals"}
          paragraph="Discover our wide range of women’s ethnic wear and western wear fashion"
        />
        <div className="new-arrivals_product_list">
          {newArrivals &&
            newArrivals.map((item) => (
              <div className="products" key={item.title}>
                <ProductCard
                  toggleQuickModel={toggleQuickModel}
                  product_images={item.images}
                  productTitle={item.title}
                  currencyCode="Rs."
                  finalPrice={item.fcp}
                  originalPrice={item.mrp}
                  salePercentage={item.td}
                  id={item.id}
                  sizeList={item.sizeList}
                  promo={item.promo ? item.promo : null}
                  item={item}
                  isSales={title === "Sales" ? true : false}
                />
              </div>
            ))}
        </div>
        <div className="btn-container">
          <Link
            className="btn"
            to={title === "Sales" ? "/products/sales" : "/products/new"}
          >
            View More
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NewArrivals;

import React, { useEffect } from "react";
import "./Loader.css";

const Loader = ({ loading }) => {
  useEffect(() => {
    

    const svgElement = document.querySelector("svg");
    svgElement.style.visibility = "visible";

    const selectAll = (s) => {
      return document.querySelectorAll(s);
    };

    const pathElements = selectAll("svg path");
    pathElements.forEach((path) => {
      path.style.transformOrigin = "50% 50%";
    });

    const frok = document.querySelector(".frok");
    const tl = setInterval(() => {
      if (frok) {
        clearInterval(tl);
        animateFrok();
      }
    }, 100);

    const animateFrok = () => {
      const frok = document.querySelector(".frok");
      if (!frok) return;

      const dress = document.querySelector(".dress");
      const mexi = document.querySelector(".mexi");
      const skirt = document.querySelector(".skirt");

      const animations = [
        { shape: dress, fill: "#fabc74" },
        { shape: mexi, fill: "#f090d9" },
        { shape: skirt, fill: "#248ea9" },
      ];

      let currentAnimationIndex = 0;
      const applyAnimation = () => {
        const animation = animations[currentAnimationIndex];
        if (!animation) return;

        const { shape, fill } = animation;
        frok.setAttribute("d", shape.getAttribute("d"));
        frok.style.fill = fill;

        currentAnimationIndex = (currentAnimationIndex + 1) % animations.length;
      };

      applyAnimation(); // Initial application

      const interval = setInterval(applyAnimation, 1500);
      return () => clearInterval(interval);
    };

    return () => clearInterval(tl);
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.5 121">
            <path
              className="frok"
              fill="#239f95"
              d="M479.14,539.25a3.4,3.4,0,0,1,2.35-.69,1.17,1.17,0,0,0,1.28-1.34c0-.89,1.52-.06,1-1.27s-2.28-4.65-3.3-7.64-2.48-8.85-3.68-11.72-7.95-14.64-9-17.25S469.2,492,469.2,492s.8-4.44.94-5c.05-.2-.12-.42-.37-.62a1.07,1.07,0,0,0,.53-.91,1.13,1.13,0,0,0-.1-.44,1.08,1.08,0,0,0,.23-.63h0c.85,0,.54-.38,1.69-1s.1-.52.2-1.29-.43-.86-.77-1.19-.71.38-.71.38c-2-.1-.34,1.48-1.29,1.34-.45-.07-.57.33-.47.77a1.08,1.08,0,0,0-.84,1,1.05,1.05,0,0,0,.1.44,1,1,0,0,0-.23.63v0c-.25,0-.51.08-.65.54a.67.67,0,0,0-1,.37s-1.05-.05-1.21.87c0,0-2.9-.06-3.5.75-.61-.81-3.5-.75-3.5-.75-.16-.93-1.21-.88-1.21-.88a.67.67,0,0,0-1.05-.37c-.14-.46-.4-.56-.65-.54v0a1.08,1.08,0,0,0-.23-.63,1.05,1.05,0,0,0,.1-.44,1.08,1.08,0,0,0-.84-1c.11-.44,0-.83-.46-.77-1,.14.66-1.43-1.29-1.34,0,0-.38-.72-.72-.38s-.86.43-.76,1.19-1,.57.19,1.29.84,1,1.7,1h0a1.08,1.08,0,0,0,.23.63,1.13,1.13,0,0,0-.1.44,1.09,1.09,0,0,0,.52.91c-.24.2-.41.42-.36.62.14.55.93,5,.93,5s2.38,4.72,1.36,7.32-7.78,14.38-9,17.25-2.68,8.71-3.69,11.71-2.81,6.42-3.32,7.63,1,.38,1,1.28.44,1.33,1.27,1.33a3.41,3.41,0,0,1,2.35.7,3.23,3.23,0,0,0,2.61,1c1-.19,4.9.9,5.22,1.66s3.5,2.67,5,1.47c1.27-1,4-1.33,4.67-1.39s3.41.35,4.68,1.4c1.46,1.2,4.65-.7,5-1.47s4.2-1.84,5.22-1.65A3.26,3.26,0,0,0,479.14,539.25Zm-10-55.57a1.22,1.22,0,0,0,.93.73v0a.72.72,0,0,1-.1.36,1,1,0,0,0-1.43-.17.58.58,0,0,1,0-.19A.77.77,0,0,1,469.18,483.68Zm.63,1.36a.74.74,0,0,1-1.09-.14.77.77,0,0,1,.48-.18A.75.75,0,0,1,469.81,485Zm-1.29.1a1.08,1.08,0,0,0,.81.38,1,1,0,0,0,.62-.21,1.62,1.62,0,0,1,0,.19.79.79,0,0,1-.48.72,6.41,6.41,0,0,0-.78-.44,1.84,1.84,0,0,0-.29-.17.43.43,0,0,1,0-.11A.75.75,0,0,1,468.52,485.14Zm-15.24-.72s0,0,0,0a1.22,1.22,0,0,0,.93-.73.77.77,0,0,1,.63.75.58.58,0,0,1,0,.19,1,1,0,0,0-1.43.17A.84.84,0,0,1,453.28,484.42Zm1.4.47a.74.74,0,0,1-1.09.14.76.76,0,0,1,.61-.32A.77.77,0,0,1,454.68,484.89Zm.28.71a1.82,1.82,0,0,0-.3.18,6.38,6.38,0,0,0-.78.43.8.8,0,0,1-.47-.72,1.62,1.62,0,0,1,0-.19,1,1,0,0,0,.61.22,1.09,1.09,0,0,0,.82-.39.76.76,0,0,1,.1.37S455,485.57,455,485.6Z"
              transform="translate(-424.5 -451.5)"
            />

            <path
              className="dress"
              d="M445.63,488.88c-.56.13,1.13,3.2,3.33,2.58,0,0,0,1.13.81.63a4.14,4.14,0,0,1,1,2.63c-.06,1.44,2.13,9,1.38,12.23s-4.5,14.33-2.9,23.65,2,12.89,2,12.89,4.77.47,8.69.47,8.69-.46,8.69-.46.38-3.58,2-12.89-2.12-20.38-2.86-23.65,1.44-10.79,1.38-12.23a4.19,4.19,0,0,1,1-2.63c.81.5.81-.63.81-.63,2.2.63,3.89-2.44,3.33-2.57s-.63-1.44-.63-1.44c.88-2.95-2.82-5.46-2.75-5.21s-.95.31-1.89-.31a18.36,18.36,0,0,0-3.88-1.32c2.94,1,2.88,8.28,2.88,8.28-2.45-.57-5.46,0-6.59,0h-2.89c-1.13,0-4.13-.57-6.58,0,0,0-.06-7.28,2.89-8.28a18.81,18.81,0,0,0-3.89,1.31c-.94.63-1.94.57-1.88.32s-3.64,2.25-2.77,5.2C446.26,487.44,446.2,488.76,445.63,488.88Z"
              transform="translate(-424.5 -451.5)"
            />
            <path
              className="mexi"
              d="M467.92,493.79c.88,1,1.37.4,1.37.4,1.93-2.89,4.5-3.77,4.5-4.26s-.8-3.61-1-4.66-1.13-3.62-1.85-3.07a7.86,7.86,0,0,0-1.44,2.61l-.45.09c-.29.07-1.06.34-2.5.87a44,44,0,0,1-5.95,1.94,20.69,20.69,0,0,1-5.31-1.63c-1.06-.53-3.41-1.4-3.51-1.43l-.7-.26a6.48,6.48,0,0,0-1.26-2.19c-.73-.55-1.61,2-1.85,3.07s-1.05,4.18-1.05,4.67,2.58,1.36,4.5,4.26c0,0,.49.56,1.37-.41s.24,9.81-.64,12.06-1.93,15-1.69,19.94l1.05,21.87s5.92.79,8.87.79,8.87-.79,8.87-.79.8-16.88,1-21.87-.8-17.69-1.69-19.94S467,492.83,467.92,493.79Zm-6.68-3.86c-.48,0-.43-.8-.86-.92h-.09c-.34,0-.36.61-.74.93a.13.13,0,0,1-.1,0c-1,0-7.66-3.16-7.66-3.16l-.18-1.29-.06-.36s2.42.88,3.46,1.41a21.17,21.17,0,0,0,5.55,1.69c1.16,0,7.8-2.66,8.56-2.82v0a3.31,3.31,0,0,1-.4,1.59S461.72,489.93,461.24,489.93Z"
              transform="translate(-424.5 -451.5)"
            />
            <path
              className="skirt"
              d="M438.2,542.74a2.12,2.12,0,0,1-.1,2c-.62.83,10.84,1,13,2.58s9.81,1.24,11.56,0a7,7,0,0,1,3.2-1.34v1.76s6.19,1.44,10.32-.83,9.7-1.76,11.87-2,1.76-.83,1.55-2c0,0,2.79-.11,3.61-1s-2.78-9.5-5.16-23.33-5.88-31.48-8.87-34.17,5.16-3.34-15.38-1.77c0,0-9.7-.44-11.77,0s-2.58-1.54-3.71,1.25-5.78,15.18-7,24.57-1.65,11.56-2.79,16-3.1,10.43-3.4,13-.11,3.92-.93,4.54S438.2,542.74,438.2,542.74Z"
              transform="translate(-424.5 -451.5)"
            />
          </svg>
          <span className="loader"></span>
        </div>
      )}
    </>
  );
};

export default Loader;

import React from "react";
import "./USP.css";
import {
  Fast_Delivery,
  ups1,
  ups2,
  ups3,
  ups4,
  ups5,
} from "../../../assets/images";

const USP = () => {
  return (
    <section className="section USP-section">
      <div className="USP-container container">
        <div className="USP-items">
          <div className="USP">
            <div className="USP-round">
              <img src={ups1} alt={ups1} />
            </div>
            <p>Free Delivery</p>
          </div>
          <div className="USP">
            <div className="USP-round">
              <img src={ups2} alt={ups1} />
            </div>
            <p>Worldwide Delivery</p>
          </div>
          <div className="USP">
            <div className="USP-round">
              <img src={ups3} alt={ups1} />
            </div>
            <p>Secured Payments</p>
          </div>
          <div className="USP">
            <div className="USP-round">
              <img src={ups4} alt={ups1} />
            </div>
            <p>Cash on Delivery*</p>
          </div>
          <div className="USP">
            <div className="USP-round">
              <img src={ups5} alt={ups1} />
            </div>
            <p>Easy Customer Support</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default USP;

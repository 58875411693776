import React, { useState } from "react";
import "./SectionHeadings.css";

const SectionHeadings = ({ title, paragraph, isHome }) => {
  const [isTopShortDesc, setTopShortDesc] = useState(false);

  const toggleDescActive = () => {
    setTopShortDesc(!isTopShortDesc);
  };
  return (
    <>
      <div
        className={`${
          isHome ? "sub_collection_" : ""
        }section_heading_container container`}
      >
        <h1
          className={`${isHome ? "sub_collection_" : ""}section_heading_title`}
        >
          {title}
        </h1>

        {isHome ? (
          <div className={`top-short-desc ${isTopShortDesc ? "active" : null}`}>
            <p>
              <span className="metafield-multi_line_text_field">
                Looking for the perfect salwar suit to enhance your style
                quotient ? Look no further ! Our exquisite collection of salwar
                suits offers a blend of traditional elegance and contemporary
                designs. Crafted with precision, our suits feature intricate
                embroidery, vibrant colors, and premium fabrics for a stunning
                look. Whether it's a casual outing or a special occasion, our
                salwar suits are designed to make you stand out. Explore our
                range today and embrace timeless beauty with our fashionable
                salwar suits. Shop now and make a fashion statement!
              </span>{" "}
              <strong className="show-more" onClick={toggleDescActive}></strong>
            </p>
          </div>
        ) : (
          <p
            className={`${
              isHome ? "sub_collection_" : ""
            }section_heading_paragraph`}
          >
            {paragraph}
          </p>
        )}
      </div>
    </>
  );
};

export default SectionHeadings;

import React, { useRef, useState } from "react";
import "./HeroSlider.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import {
  hero_image1,
  hero_image2,
  hero_image3,
  hero_image_mobile1,
  hero_image_mobile2,
  hero_image_mobile3,
} from "../../assets/images";
import { Link } from "react-router-dom";

const HeroSlider = ({ sliderData }) => {
  return (
    <section className="hero-slider">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {sliderData &&
          sliderData.map((items) => {
            return (
              <div key={items.imageLG}>
                <SwiperSlide key={items.url}>
                  <Link key={items.imageSM} to={items.url}>
                    <img
                      className="desktop_hero_slider"
                      src={"https://amarnathckr.online/api/v1/upload/"+items.imageLG}
                      alt={items.imageLG}
                    />
                  </Link>
                  <Link key={items.imageLG}>
                    <img
                      className="mobile_hero_slider"
                      src={"https://amarnathckr.online/api/v1/upload/"+items.imageSM}
                      alt={items.imageSM}
                    />
                  </Link>
                </SwiperSlide>
              </div>
            );
          })}
        
      </Swiper>
    </section>
  );
};

export default HeroSlider;

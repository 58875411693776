/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./CartDrawer.css";
import { product_img1 } from "../../../assets/images";
import { getWithoutAuth, postAnyAuth } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading, toggleTrigger } from "../../../store";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import AdditionalOffers from "../AdditionalOffers/AdditionalOffers";

const CartDrawer = ({ toggleCartDrawer }) => {
  const [cart, setCart] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const token = useSelector((state) => state.token);
  const [timeout, setTimeoutTimer] = useState();
  const isCartOpen = useSelector((state) => state.cartDrawer);
  const [selectAll, setSelectAll] = useState(false);
  const [minSelected, setMinSelected] = useState(false);

  const globalTrigger = useSelector((state) => state.globalTrigger);

  useEffect(() => {
    const cartLocal = JSON.parse(localStorage.getItem("cart"));
    if (cartLocal) {
      if (!token) {
        const newData = cartLocal.map((item) => {
          return item;
        });
        setCart(newData);
        localStorage.setItem("cart", JSON.stringify(cartLocal));
      } else {
        console.log(
          cartLocal.map((items) => {
            return {
              productId: items.id,
              size: items.size,
              quantity: items.quantity,
              vsku: items.vsku,
              selected: items.selected,
            };
          })
        );
        dispatch(toggleLoading());
        postAnyAuth(
          "cart",
          {
            currentCart:
              cartLocal && cartLocal.length > 0
                ? cartLocal.map((items) => {
                    return {
                      productId: items.id,
                      size: items.size,
                      quantity: items.quantity,
                      vsku: items.vsku,
                      selected: items.selected,
                    };
                  })
                : null,
          },
          token
        )
          .then((res) => {
            console.log(res.data);
            const newData = res.data.load.map((item) => {
              return item;
            });
            setCart(newData);
            localStorage.setItem("cart", JSON.stringify(res.data.load));
            dispatch(toggleLoading());
          })
          .catch((err) => {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
            dispatch(toggleLoading());
          });
      }
    }
  }, [trigger, globalTrigger]);

  const handleQuantityChange = (quantity, productId, size, vsku) => {
    clearTimeout(timeout);
    const newTimer = setTimeout(() => {
      dispatch(toggleLoading());
      postAnyAuth(
        `cart/update-quantity`,
        { quantity, productId, size, vsku },
        token
      )
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("cart", JSON.stringify(res.data.load));
          dispatch(toggleTrigger());
          setCart((prev) => {
            return prev.map((item) => {
              if (item.id === productId && item.size === size) {
                item.quantity = quantity;
              }
              return item;
            });
          });
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err.response.data.message);
          console.log(err.response.data.maxAvailableQuantity);
          // toast.error(err.response.data.message)
          setCart((prev) => {
            return prev.map((item) => {
              if (item.id === productId && item.size === size) {
                item.quantity = err.response.data.maxQuantity;
                item.error = err.response.data.message;
              }
              return item;
            });
          });
          dispatch(toggleLoading());
        });
    }, 650);
    setTimeoutTimer(newTimer);
  };

  const dispatch = useDispatch();

  const handleInputChange = (event, productId, size, vsku) => {
    const value = event;
    setCart((prev) => {
      return prev.map((item) => {
        if (item.id === productId && item.size === size) {
          item.quantity = value;
          item.error = null;
        }
        return item;
      });
    });

    if (value >= 0) {
      console.log(value);

      if (token) {
        handleQuantityChange(value, productId, size, vsku);
      } else {
        localStorage.setItem(
          "cart",
          JSON.stringify(
            cart.map((item) => {
              if (item.id === productId && item.size === size) {
                item.quantity = value;
              }
              return item;
            })
          )
        );

        if (value === 0) {
          const filteredCart = cart.filter(
            (item) => !(item.id === productId && item.size === size)
          );
          localStorage.setItem("cart", JSON.stringify(filteredCart));
          dispatch(toggleTrigger());
        }
      }
      console.log(value);
    }
  };

  const total =
    cart && cart.length > 0
      ? cart.reduce(
          (acc, item) => acc + parseFloat(item.fcp) * parseFloat(item.quantity),
          0
        )
      : 0.0;

  console.log(total);

  useEffect(() => {
    let count = 0;
    let min = 0;

    console.log("running select a;;");

    if (cart) {
      cart.map((item) => {
        if (item.selected === false) {
          count++;
        } else {
          min++;
        }
      });
      if (count > 0) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
      if (min > 0) {
        setMinSelected(true);
      } else {
        setMinSelected(false);
      }
      console.log("sdasdasd" + count);
    }
  }, [trigger, cart, globalTrigger]);

  const handleSelect = (productId, size, event, val) => {
    if (productId === "all") {
      if (token) {
        dispatch(toggleLoading());
        postAnyAuth(
          `cart/update-selected`,
          { productId: "all", value: val },
          token
        )
          .then((res) => {
            console.log(res.data);
            localStorage.setItem("cart", JSON.stringify(res.data.load));
            dispatch(toggleTrigger());
            setCart((prev) => {
              return prev.map((item) => {
                item.selected = val;
                return item;
              });
            });
            dispatch(toggleLoading());
          })
          .catch((err) => {
            console.log(err.response.data.message);
            console.log(err.response.data.maxAvailableQuantity);
            // toast.error(err.response.data.message)

            dispatch(toggleLoading());
          });
      } else {
        return localStorage.setItem(
          "cart",
          JSON.stringify(
            cart.map((item) => {
              item.selected = val;

              return item;
            })
          )
        );
      }
      dispatch(toggleTrigger());
    } else {
      console.log(event.target.checked);
      const value = event.target.checked;

      if (token) {
        // handleQuantityChange(value, productId, size, vsku);
        dispatch(toggleLoading());
        postAnyAuth(`cart/update-selected`, { productId, size, value }, token)
          .then((res) => {
            console.log(res.data);
            localStorage.setItem("cart", JSON.stringify(res.data.load));
            dispatch(toggleTrigger());
            setCart((prev) => {
              return prev.map((item) => {
                if (item.id === productId && item.size === size) {
                  item.selected = value;
                }
                return item;
              });
            });
            dispatch(toggleLoading());
          })
          .catch((err) => {
            console.log(err.response.data.message);
            console.log(err.response.data.maxAvailableQuantity);
            // toast.error(err.response.data.message)

            dispatch(toggleTrigger());
            dispatch(toggleLoading());
          });
      } else {
        localStorage.setItem(
          "cart",
          JSON.stringify(
            cart.map((item) => {
              if (item.id === productId && item.size === size) {
                item.selected = value;
              }
              return item;
            })
          )
        );
        dispatch(toggleTrigger());
      }
      console.log(value);
    }
  };

  return (
    <div className="cartDrawer">
      {/* Overlay */}
      <div
        className={`overlay ${isCartOpen ? "show" : ""}`}
        onClick={toggleCartDrawer}
      ></div>

      {/* Sliding Drawer */}
      <div
        className={`cart-sliding-drawer-container ${isCartOpen ? "open" : ""}`}
      >
        <div className="cart-drawer-content">
          <div className="cart-drawer-header">
            <h2>Cart</h2>
            <i className="ri-close-fill" onClick={toggleCartDrawer}></i>
          </div>
          {cart && cart.length > 0 ? (
            <>
              <div className="cart-drawer__inner">
                <div className="cart-drawer__scrollable">
                  <div className="select-all-wish-list-items">
                    <input
                      type="checkbox"
                      id="select-all"
                      name="select All"
                      checked={selectAll}
                      onClick={() =>
                        handleSelect("all", null, null, !selectAll)
                      }
                    ></input>
                    <label for="vehicle1"> Select All</label>

                    <span>|</span>
                    <button className="delete-all-btn">Delete</button>
                  </div>

                  {/* select all option with delete button */}
                  <div className="cart__items">
                    {cart &&
                      cart.map((items) => {
                        return (
                          <div
                            key={items.size + "_" + items.id}
                            className="cart__item"
                          >
                            <div className="cart__image">
                              <input
                                checked={items.selected}
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                onChange={(e) =>
                                  handleSelect(items.id, items.size, e)
                                }
                              />
                              <label for="vehicle1">
                                <Link
                                  onClick={() => {
                                    toggleCartDrawer();
                                    dispatch(toggleTrigger());
                                  }}
                                  to={`/product-details/${items.title
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}`}
                                  state={{ id: items.id }}
                                >
                                  <img
                                    src={
                                      "https://amarnathckr.online/api/v1/upload/" +
                                      items.images[0]
                                    }
                                    alt=""
                                  />
                                </Link>
                              </label>
                            </div>
                            <div className="cart__item-details">
                              <div className="cart_item_title_area">
                                <div className="cart__item-title">
                                  <Link
                                    onClick={() => {
                                      toggleCartDrawer();
                                      dispatch(toggleTrigger());
                                    }}
                                    to={`/product-details/${items.title
                                      .split(" ")
                                      .join("-")
                                      .toLowerCase()}`}
                                    state={{ id: items.id }}
                                  >
                                    {items.title}
                                  </Link>
                                </div>
                                <div className="cart__item--variants">
                                  <span>Size:</span> {items.size}
                                </div>
                              </div>
                              {items.error && (
                                <div className="error-text">{items.error}</div>
                              )}
                              <div className="cart__item-sub">
                                <div className="steppers">
                                  <div className="quantity">
                                    <a
                                      onClick={() =>
                                        handleInputChange(
                                          parseInt(items.quantity) - 1,
                                          items.id,
                                          items.size,
                                          items.vsku
                                        )
                                      }
                                      className="quantity__minus"
                                    >
                                      <i className="ri-subtract-fill"></i>
                                    </a>
                                    <input
                                      name="quantity"
                                      type="Number"
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          items.id,
                                          items.size
                                        )
                                      }
                                      minLength={0}
                                      className="quantity__input"
                                      value={items.quantity}
                                    />
                                    <a
                                      onClick={() =>
                                        handleInputChange(
                                          parseInt(items.quantity) + 1,
                                          items.id,
                                          items.size
                                        )
                                      }
                                      className="quantity__plus"
                                    >
                                      <i className="ri-add-fill"></i>
                                    </a>
                                  </div>
                                  <a
                                    className="remove-btn-cart-item"
                                    onClick={() =>
                                      handleInputChange(
                                        0,
                                        items.id,
                                        items.size,
                                        items.vsku
                                      )
                                    }
                                  >
                                    Remove
                                  </a>
                                </div>
                                <div className="cart__item-price-col">
                                  <span className="cart__price">
                                    <span className="offer-price">
                                      Offer Price
                                    </span>
                                    <span className="final-amount">
                                      {items.fcp}
                                    </span>
                                    <div className="cart__price--compare">
                                      <span className="mrp-price">MRP</span>
                                      <div>
                                        <span className="line-through">
                                          {items.mrp}
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <AdditionalOffers />
                </div>
              </div>
              <div className="cart-drawer__footer">
                <div className="cart__footer-sub">
                  <div className="cart_footer_left_title">Subtotal</div>
                  <span className="total-amount">Rs. {total}</span>
                </div>

                <div className="cart__checkout-wrapper">
                  {minSelected ? (
                    <Link
                      to={minSelected ? "/checkout" : null}
                      onClick={toggleCartDrawer}
                      name="checkout"
                      className="btn cart__checkout"
                      disabled={!minSelected}
                    >
                      Check out
                    </Link>
                  ) : (
                    <button
                      disabled={true}
                      className="btn disabled-btn-checkout"
                    >
                      Check out
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <p className="cart-empty-message">You cart is empty</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartDrawer;

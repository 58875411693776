import React, { useState } from "react";
import "./Rating.css";

const Rating = ({ stars, onRated, disabled }) => {
  const [value, setValue] = useState(stars);
  const [dynamicValue, setDynamicValue] = useState(stars);

  const handleClick = (newValue) => {
    if (!disabled) {
      setValue(newValue);
      setDynamicValue(newValue);

      if (onRated) {
        onRated(newValue);
      }
    }
  };

  const handleMouseEnter = (newValue) => {
    if (!disabled) {
      setDynamicValue(newValue);
    }
  };

  const handleMouseLeave = () => {
    if (!disabled) {
      setDynamicValue(value);
    }
  };

  const starSpans = [];

  for (let v = 1; v <= 5; v++) {
    starSpans.push(
      <span
        key={v}
        className="star"
        onMouseEnter={() => handleMouseEnter(v)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(v)}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        {v <= dynamicValue ? "★" : "☆"}
      </span>
    );
  }

  return <div>{starSpans}</div>;
};

export default Rating;

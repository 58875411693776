import * as yup from "yup";

const signup = yup.object().shape({
  fname: yup
    .string()
    .trim()
    .required("Name can not be empty")
    .min(5, "first name requires minimum 5 Character required")
    .max(16, "first name cannot exceed 36 Characters")
    .test("isPerfectString", "Enter a valid name", (arg) =>
      /^[A-Za-z ]+$/.test(arg)
    ),
  lname: yup
    .string()
    .trim()
    .required("Last Name can not be empty")
    .min(1, "minimum one Character required")
    .max(16, "last name cannot exceed 36 Characters"),

  email: yup
    .string()
    .trim()
    .required("Enter you email")
    .test("isvalidEmail", "Enter a valid Email", (arg) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(arg)
    ),
  password: yup
    .string()
    .trim()
    .required("Password can not be empty")
    .min(8, "Too short password")
    .max(16, "Too long password")
    .test("isPerfectPasswrod", "Enter a strong password", (arg) =>
      /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?!.*\s).{8,16})/.test(arg)
    ),
});

const otp = yup.object().shape({
  otp: yup
    .string()
    .trim()
    .required("OTP can not be empty")
    .min(6, "OTP should be 6 digits")
    .max(6, "OTP should be 6 digits"),
});

const login = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Enter you email")
    .test("isvalidEmail", "Enter a valid Email", (arg) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(arg)
    ),
  password: yup.string().trim().required("Password can not be empty"),
});

const address = yup.object().shape({
  fname: yup
    .string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name cannot be more than 50 characters"),
  lname: yup
    .string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name cannot be more than 50 characters"),
  addressOne: yup
    .string()
    .required("Address line 1 is required")
    .min(8, "Address line 1 MUST BE AT LEAST 8 CHARACTERS")
    .max(100, "Address line 1 cannot be more than 100 characters"),
  addressTwo: yup.string(),
  city: yup
    .string()
    .required("City is required")
    .min(4, "CITY MUST BE AT LEAST 4 CHARACTERS")
    .max(50, "City cannot be more than 50 characters"),
  country: yup.string().required("Country is required"),
  province: yup.string().required("State or province is required"),
  postalCode: yup
    .string()
    .required("Postal code is required")
    .min(5, "Postal code must be at least 5 characters")
    .max(10, "Postal code cannot be more than 10 characters"),
  contact: yup
    .string()
    .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
    .required("Contact number is required"),
  isDefault: yup.boolean(),
});

const editProfileSchema = yup.object().shape({
  fname: yup
    .string()
    .trim()
    .required("Name can not be empty")
    .min(5, "first name requires minimum 5 Character required")
    .max(16, "first name cannot exceed 36 Characters")
    .test("isPerfectString", "Enter a valid name", (arg) =>
      /^[A-Za-z ]+$/.test(arg)
    ),
  lname: yup
    .string()
    .trim()
    .required("Last Name can not be empty")
    .min(1, "minimum one Character required")
    .max(16, "last name cannot exceed 36 Characters"),

  email: yup
    .string()
    .trim()
    .required("Enter you email")
    .test("isvalidEmail", "Enter a valid Email", (arg) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(arg)
    ),
});

const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Current password is required")
    .min(6, "Password must be at least 6 characters"),
  newPassword: yup
    .string()
    .required("New password is required")
    .min(6, "Password must be at least 6 characters"),
  cPassword: yup
    .string()
    .required("Please confirm your new password")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export const customerYupSchema = {
  login,
  signup,
  otp,
  address,
  editProfileSchema,
  changePasswordSchema,
};

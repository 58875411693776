import React from "react";
import "./MobileFilterDrawer.css";
import FilterForm from "../FilterForm/FilterForm";

const MobileFilterDrawer = ({ isFilterOpen, toggleFilterDrawer,toggleHeader }) => {
  return (
    <div className="">
      {/* Overlay */}
      <div
        className={`overlay ${isFilterOpen ? "show" : ""}`}
        onClick={toggleFilterDrawer}
      ></div>

      {/* Sliding Drawer */}
      <div
        className={`filter-sliding-drawer-container  ${
          isFilterOpen ? "open" : ""
        }`}
      >
        <div className="filter-drawer-content">
          <div className="filter-drawer-header">
            <h2>Filter</h2>
            <i className="ri-close-fill" onClick={toggleFilterDrawer}></i>
          </div>
          <div className="filter-drawer__inner">
            <div className="filter-drawer__scrollable">
              <FilterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilterDrawer;

import React, { useState } from "react";

const Tab = ({ label, onClick, isActive }) => (
  <div
    className={`size-chart-tab ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    {label}
  </div>
);

export default Tab;

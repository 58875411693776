/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./WishList.css";

import { useDispatch, useSelector } from "react-redux";
import WishlistProductCardContainer from "../../WishlistProductCard/WishListProductCardContainer";
import { postAnyAuth } from "../../../api/api";
import { toggleDrawer, toggleLoading, toggleTrigger } from "../../../store";
import toast from "react-hot-toast";
import GlobalModal from "../../GlobalModal/GlobalModal";
import { Link } from "react-router-dom";

const WishList = () => {
  const [isListView, setListView] = useState(true);

  const [wishList, setWishList] = useState(null);

  const [trigger, setTrigger] = useState(false);

  const [search, setSearch] = useState("");

  const token = useSelector((state) => state.token);

  const globalTrigger = useSelector((state) => state.globalTrigger);

  const [isGlobalModaOpen, setGlobalModaOpen] = useState(false);
  const [callBackData, setCallback] = useState();
  const toggleGlobalModaDrawer = (callback) => {
    setGlobalModaOpen(!isGlobalModaOpen);

    if (isGlobalModaOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  useEffect(() => {
    let wishlistLocal = JSON.parse(localStorage.getItem("wishlist"));
    if (token) {
      console.log("triggered wish api call from component");
      dispatch(toggleLoading())
      postAnyAuth(
        "wishlist",
        {
          currentList: null,
        },
        token
      )
        .then((res) => {
          let newData = res.data.load.map((items) => {
            items.selected = false;
            items.size = items.sizeList[0];
            return items;
          });
          if (search.length > 0) {
            newData = newData.filter((product) =>
              product.title.toLowerCase().includes(search.toLowerCase())
            );
          }
          localStorage.setItem("wishlist", JSON.stringify(newData));
          setWishList(newData);
          dispatch(toggleLoading())
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading())
        });
    } else {
      if (wishlistLocal.length >= 0) {
        let newData = wishlistLocal.map((items) => {
          items.selected = false;
          items.size = items.sizeList[0];
          return items;
        });

        if (search.length > 0) {
          newData = newData.filter((product) =>
            product.title.toLowerCase().includes(search.toLowerCase())
          );
        }
        setWishList(newData);

        localStorage.setItem("wishlist", JSON.stringify(wishlistLocal));
      }
    }
  }, [token, trigger, localStorage.getItem("wishlist"), globalTrigger]);

  const toggleView = () => {
    setListView(!isListView);
  };

  const handleSelect = (val, id) => {
    console.log("Before update - Previous state:", wishList);

    setWishList((prev) => {
      let temp = [...prev];
      temp = temp.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            selected: val,
            sizeDetails: { ...item.sizeDetails },
          };
        }
        return item;
      });

      return temp;
    });
  };

  const hanhandleSelectAll = (e) => {
    setWishList((prev) => {
      let temp = [...prev];
      temp = temp.map((item) => {
        return {
          ...item,
          selected: e.target.checked,
          sizeDetails: { ...item.sizeDetails },
        };
      });
      return temp;
    });
  };

  const handleDeleteSelected = () => {
    const callback = () => {
      let allIds = wishList.filter((items) => {
        if (items.selected === true) return true;
        else return false;
      });

      allIds = allIds.map((items) => items.id);

      console.log(allIds);

      if (token) {
        dispatch(toggleLoading())
        postAnyAuth("wishlist/remove", { productIds: allIds }, token)
          .then((res) => {
            console.log(res.data);
            let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

            localWishlist = localWishlist.filter(
              (items) => items.selected === true
            );

            console.log(localWishlist);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));
            setTrigger(!trigger);
            dispatch(toggleTrigger());
            dispatch(toggleLoading())
          })
          .catch((err) => {
            console.log(err);
            dispatch(toggleLoading())
          });
      } else {
        let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

        localWishlist = wishList.filter((items) => items.selected !== true);

        console.log(localWishlist);

        localStorage.setItem("wishlist", JSON.stringify(localWishlist));
        setTrigger(!trigger);
        dispatch(toggleTrigger());
      }
    };

    setCallback({
      toggle: callback,
      params: null,
      title: "Are you sure you want to delete selected?",
    });
    toggleGlobalModaDrawer();
  };

  const dispatch = useDispatch();

  const handleMoveToCartAll = () => {
    const callBack = () => {
      let allIds = wishList.filter((items) => {
        if (items.selected === true) return true;
        else return false;
      });

      allIds = allIds.map((items) => ({
        productId: items.id,
        size: items.size,
        
      }));

      console.log(allIds);

      if (token) {
        dispatch(toggleLoading())
        postAnyAuth("cart/move-cart", { products: allIds }, token)
          .then((res) => {
            console.log(res.data);
            let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

            localWishlist = wishList.filter((items) => items.selected !== true);

            console.log(localWishlist);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));

            localStorage.setItem("cart", JSON.stringify(res.data.load));
            setTrigger(!trigger);
            dispatch(toggleTrigger());
            dispatch(toggleDrawer());
            dispatch(toggleLoading())
          })
          .catch((err) => {
            console.log(err);
            dispatch(toggleLoading())
          });
      } else {
        let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

        localWishlist = wishList.filter((items) => items.selected !== true);

        console.log(localWishlist);

        localStorage.setItem("wishlist", JSON.stringify(localWishlist));

        let localCart = JSON.parse(localStorage.getItem("cart"));

        allIds.map((items) => {
          localCart = wishList.filter(
            (data) => items.productId !== data.id && items.size !== data.size
          );
          return items.productId;
        });

        localCart = localCart.map((items) => {
          items.quantity = 1;
          return items.id;
        });

        let filteredData = wishList.map((items) => {
          items.quantity = 1;
          return items;
        });

        if (localCart.length > 0) {
          localCart.map((items) => {
            filteredData = filteredData.filter(
              (data) => items.id !== data.id && items.size !== data.size
            );
            return items;
          });
          localStorage.setItem("cart", JSON.stringify(localCart));
          dispatch(toggleDrawer());
        } else {
          localStorage.setItem("cart", JSON.stringify(filteredData));
          dispatch(toggleDrawer());
        }

        setTrigger(!trigger);
        dispatch(toggleTrigger());
      }
    };
    setCallback({
      toggle: callBack,
      params: null,
      title: "Are you sure you want to move selected to cart?",
    });
    toggleGlobalModaDrawer();
  };

  const handleSizeSelect = ({
    e: {
      target: { value },
    },
    id,
  }) => {
    setWishList((prev) => {
      let temp = [...prev];
      temp = temp.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            size: value,
          };
        }
        return item;
      });

      return temp;
    });
  };

  console.log(wishList);  

  const handleMoveToCart = (product) => {
    const callBackData = (product) => {
      const productData = { productId: product.id, size: product.size, vsku : product.vsku };
      console.log(product);

      if (token) {
        dispatch(toggleLoading())
        postAnyAuth("cart/move-cart", { products: [productData] }, token)
          .then((res) => {
            dispatch(toggleLoading())
            console.log(res.data);
            let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

            localWishlist = localWishlist.filter(
              (item) => !(item.id === product.id && item.size === product.size)
            );

            console.log(localWishlist);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));

            localStorage.setItem("cart", JSON.stringify(res.data.load));
            setTrigger(!trigger);
            dispatch(toggleTrigger());
            dispatch(toggleDrawer());
          })
          .catch((err) => {
            dispatch(toggleLoading())
            console.log(err);
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          });
      } else {
        let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

        console.log(localWishlist);

        let localCart = JSON.parse(localStorage.getItem("cart"));

        localCart = localCart.filter(
          (item) => !(item.id === product.id && item.size === product.size)
        );

        localCart.push({ ...product, quantity: 1 });

        localStorage.setItem("cart", JSON.stringify(localCart));

        localWishlist = localWishlist.filter(
          (item) => !(item.id === product.id && item.size === product.size)
        );

        console.log(localWishlist);

        localStorage.setItem("wishlist", JSON.stringify(localWishlist));
        dispatch(toggleDrawer());

        setTrigger(!trigger);
        dispatch(toggleTrigger());
      }
    };
    callBackData(product)
    
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setTrigger(!trigger)
  };

  return (
    <section className="wishlist-section">
      <GlobalModal
        callback={callBackData}
        isGlobalModalOpen={isGlobalModaOpen}
        toggleGlobalModal={toggleGlobalModaDrawer}
      />
      <div className="wishlist-container container">
        <div className="wishlist-title-area">
          <h2>Wishlist</h2>
          <div className="select-all-wish-list-items">
            <Link to={"/account/profile"} className="delete-all-btn">
              My Account
            </Link>
          </div>
        </div>
        <div className="wishlist-search-inputs">
          <form onSubmit={handleSearch} className="wishlist-search-bar">
            <i className="ri-search-line"></i>
            <input
            disabled={wishList?.length === 0}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="search"
              name="wishlist-search"
              id="search"
              placeholder="Search Product"
            />
          </form>
          <div className="wishlist-view">
            <div
            
              className={`change__view icon-wishlist-list-view ${
                isListView ? "change__view-active" : ""
              }`}
              onClick={wishList?.length === 0 ? ()=>console.log("disabled") :toggleView}
            >
              <i className="ri-list-check"></i>
            </div>
            <div
              className={`change__view icon-wishlist-grid-view ${
                isListView ? "" : "change__view-active"
              }`}
              onClick={wishList?.length === 0 ? ()=>console.log("disabled") :toggleView}
            >
              <i className="ri-grid-line"></i>
            </div>
          </div>
        </div>
        <div className="select-all-wish-list-items">
          <input
          disabled={wishList?.length === 0}
            onChange={hanhandleSelectAll}
            type="checkbox"
            id="select-all"
            name="select All"
            value="Bike"
          ></input>
          <label for="vehicle1"> Select All</label>
          <span>|</span>
          <button disabled={wishList?.length === 0} className="delete-all-btn" onClick={handleMoveToCartAll}>
            Move to cart
          </button>
          <span>|</span>
          <button disabled={wishList?.length === 0} className="delete-all-btn" onClick={handleDeleteSelected}>
            Delete
          </button>
        </div>

        <div
          className={isListView ? "wishlist-list-view" : "wishlist-grid-view"}
        >
          {!wishList?.length > 0 && <div className="wishlist-empty"><p>PLEASE ADD PRODUCT TO YOUR WISHLIST TO SEE THEM HERE.</p><div className="btn-empty-confirm"><Link to={"/products/new"} className="btn confirm-btn">Go back to shopping</Link></div></div>}
          {wishList &&
            wishList.length > 0 &&
            wishList.map((items) => {
              return (
                <WishlistProductCardContainer
                  allData={items}
                  trigger={() => setTrigger(!trigger)}
                  key={items.id}
                  currencyCode={"Rs"}
                  product_images={items.images}
                  productTitle={items.title}
                  finalPrice={items.fcp}
                  originalPrice={items.mrp}
                  salePercentage={items.td}
                  size={items.sizeList}
                  sizeVal={items.size}
                  setSizeSelected={handleSizeSelect}
                  id={items.id}
                  view={isListView}
                  selected={items.selected}
                  handleSelect={handleSelect}
                  handleMoveToCart={handleMoveToCart}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default WishList;

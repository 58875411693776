import React, { useState, useEffect } from "react";
import "./ProductCard.css";
import { Link, useLocation } from "react-router-dom";
import { getAnyApi, postAnyAuth } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { subscribeQuickView, toggleLoading, toggleTrigger } from "../../store";
import LazyImage from "../utils/LazyImage";

const ProductCard = ({
  product_images,
  productTitle,
  currencyCode,
  finalPrice,
  originalPrice,
  salePercentage,
  toggleQuickModel,
  id,
  sizeList,
  promo,
  item,
  isSales,
}) => {
  const [hovered, setHovered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isWishlisted, setIsWish] = useState(false);

  useEffect(() => {
    let intervalId;

    if (hovered) {
      // Start from the second image
      setCurrentIndex(1);

      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % product_images.length);
      }, 2000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [hovered, product_images]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setCurrentIndex(0);
  };

  const token = useSelector((state) => state.token);

  const handleAddWishlist = () => {
    let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

    if (token) {
      dispatch(toggleLoading());
      getAnyApi("wishlist/add?productID=" + id, token)
        .then((res) => {
          console.log("added to wishlist");
          if (localWishlist && localWishlist.length > 0) {
            setIsWish(true);

            localStorage.setItem("wishlist", JSON.stringify(res.data.load));

            dispatch(toggleTrigger());
          } else {
            localWishlist = [
              {
                images: product_images,
                title: productTitle,
                fcp: finalPrice,
                mrp: originalPrice,
                td: salePercentage,
                sizeList: sizeList,
                id,
              },
            ];
          }

          setIsWish(true);

          localStorage.setItem("wishlist", JSON.stringify(localWishlist));

          dispatch(toggleTrigger());
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          if (localWishlist && localWishlist.length > 0) {
            const index = localWishlist.filter((items) => items.id === id);

            if (index.length > 0) {
              localWishlist.push({
                images: product_images,
                title: productTitle,
                fcp: finalPrice,
                mrp: originalPrice,
                td: salePercentage,
                sizeList: sizeList,
                id,
              });
            }

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));
            dispatch(toggleTrigger());
          } else {
            localWishlist = [
              {
                images: product_images,
                title: productTitle,
                fcp: finalPrice,
                mrp: originalPrice,
                td: salePercentage,
                sizeList: sizeList,
                id,
              },
            ];

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));
            dispatch(toggleTrigger());
          }
          dispatch(toggleLoading());
        });
    } else {
      if (localWishlist && localWishlist.length > 0) {
        const index = localWishlist.filter((items) => items.id !== id);

        if (index.length > 0) {
          localWishlist.push({
            images: product_images,
            title: productTitle,
            fcp: finalPrice,
            mrp: originalPrice,
            td: salePercentage,
            sizeList: sizeList,
            id,
          });
        }
        localStorage.setItem("wishlist", JSON.stringify(localWishlist));
        setIsWish(true);
        dispatch(toggleTrigger());
      } else {
        localWishlist = [
          {
            images: product_images,
            title: productTitle,
            fcp: finalPrice,
            mrp: originalPrice,
            td: salePercentage,
            sizeList: sizeList,
            id,
          },
        ];
      }

      localStorage.setItem("wishlist", JSON.stringify(localWishlist));
      setIsWish(true);
      dispatch(toggleTrigger());
    }
  };

  const globalTrigger = useSelector((state) => state.globalTrigger);

  useEffect(() => {
    const allList = JSON.parse(localStorage.getItem("wishlist"));

    let filteredData = allList
      ? allList?.filter((items) => items.id === id)
      : [];

    if (filteredData && filteredData.length > 0) {
      setIsWish(true);
    } else {
      setIsWish(false);
    }
  }, [token, globalTrigger]);

  const handleRemoveWishlist = () => {
    if (token) {
      dispatch(toggleLoading());
      postAnyAuth("wishlist/remove", { productIds: [id] }, token)
        .then((res) => {
          let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

          localWishlist = localWishlist.filter((items) => items.id !== id);

          localStorage.setItem("wishlist", JSON.stringify(localWishlist));

          setIsWish(false);
          dispatch(toggleTrigger());
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    } else {
      let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

      localWishlist = localWishlist.filter((items) => items.id !== id);

      localStorage.setItem("wishlist", JSON.stringify(localWishlist));

      setIsWish(false);
      dispatch(toggleTrigger());
    }
  };

  const dispatch = useDispatch();

  const handleQuickView = () => {
    dispatch(subscribeQuickView(id));

    toggleQuickModel();
  };

  const location = useLocation();

  const referal = location?.pathname
    ?.split("/")[2]
    ?.split("-")
    ?.join(" ")
    ?.toUpperCase();

  const renderContent = (imageUrl, index) => {
    if (imageUrl.endsWith(".mp4")) {
      // Check if it's a video file
      return (
        <video
          width="100%"
          height="auto"
          src={imageUrl}
          controls={false}
          playsInline
          autoPlay
          muted
          loop
          preload="none"
          poster={
            "https://amarnathckr.online/api/v1/upload/" + product_images[0]
          }
        />
      );
    } else {
      // Image
      return <LazyImage src={imageUrl} alt={`Image ${index + 1}`} />;
    }
  };

  function isStockLow(data) {
    for (const size in data) {
      if (data[size].STOCK <= 10) {
        return true;
      }
    }
    return false;
  }

  const isLimited = isStockLow(item.sizeDetails);

  console.log(isLimited);

  return (
    <div className="product-card">
      <div className="product_link">
        <div
          className="product_images"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="overlay-items">
            <div className="badge-tag">
              <p>New</p>
            </div>
            <div className="fav-btn-item">
              {isWishlisted > 0 ? (
                <button onClick={handleRemoveWishlist} className="fav-btn">
                  <i className="ri-heart-3-fill"></i>
                </button>
              ) : (
                <button onClick={handleAddWishlist} className="fav-btn">
                  <i className="ri-heart-3-line"></i>
                </button>
              )}
            </div>
          </div>
          <Link
            to={
              "/product-details/" +
              productTitle.split(" ").join("-").toLowerCase()
            }
            onClick={() => dispatch(toggleTrigger)}
            state={{ id: id, referal: referal ? referal : null }}
          >
            {/* <img
              src={
                "https://amarnathckr.online/api/v1/upload/" +
                product_images[currentIndex]
              }
              alt=""
              className={` ${hovered ? "transition" : ""}`}
            /> */}
            {/* <LazyImage
              src={
                "https://amarnathckr.online/api/v1/upload/" +
                product_images[currentIndex]
              }
              alt=""
              classes={`product-image ${hovered ? "transition" : ""}`}
            /> */}
            {renderContent(
              "https://amarnathckr.online/api/v1/upload/" +
                product_images[currentIndex]
            )}
          </Link>
          <div className="quick-view">
            <button className="quick-view-btn" onClick={handleQuickView}>
              Quick View
            </button>
          </div>
        </div>
        <Link
          to={
            "/product-details/" +
            productTitle.split(" ").join("-").toLowerCase()
          }
          onClick={() => dispatch(toggleTrigger)}
          state={{ id: id }}
          className="product-text-info"
        >
          <small>Fab Galaxy</small>
          <h2>{`${productTitle}`}</h2>
          <div className="product_price">
            <span className="product-final-price">
              {`${currencyCode} ${
                !promo
                  ? finalPrice
                  : Math.floor(finalPrice - (finalPrice * promo) / 100)
              }`}
              <span className="product-original-price">
                <span className="original-price">
                  {`${currencyCode} ${originalPrice}`}
                </span>
              </span>
            </span>
            {isLimited && !isSales && (
              <span className="limited-text-warning">
                Limited Stocks Availble
              </span>
            )}
            {isSales && (
              <span className="limited-text-warning">
                Limited Stocks Availble
              </span>
            )}
            <span className="sale_percentage">
              {salePercentage + "%"} {/*{promo && "+ " + promo + "%"}*/} Off
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;

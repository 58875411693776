import React, { useEffect, useState } from "react";
import "./MyProfile.css";
import { Breadcrumbs } from "../../Headings";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  unsuscribeFilter,
  unsuscribeToken,
  unsuscribeUser,
} from "../../../store";
import { getAnyApi } from "../../../api/api";

const MyProfile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  
  if (!userData) {
    return null;
  } else {
    const defaultAddress = userData.address.filter((items) => {
      return items.isDefault === true;
    });

    const handleLogout = () => {
      dispatch(unsuscribeToken());
      dispatch(unsuscribeUser());
      dispatch(unsuscribeFilter());

      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("cart");
      localStorage.removeItem("wishlist");
    };

    return (
      <div className="my-profile-container container">
        <Breadcrumbs
          links={[
            {
              links_id: 1,
              redirectTo: "/account/profile",
              linkName: "My Profile",
            },
          ]}
        />
        <div className="my-profile-title">
          <h2>MY ACCOUNT</h2>
        </div>
        <div className="action-buttons">
          <button onClick={handleLogout} className="btn logout-btn">
            Log Out
          </button>
          
        </div>
        <div className="my-profile-contents">
          <div className="my-profiles-orders-history">
            <div className="my-profiles-options">
              <h2>Hey {userData.fname} {userData.lname}</h2>
              <div className="pro-options">
                <Link to="/account/order-history" className="option-btn">
                  <i className="ri-box-3-line"></i> Orders
                </Link>
                <Link to="/account/wishlist" className="option-btn">
                  <i className="ri-heart-3-line"></i> wishlist
                </Link>
                <Link to={"/faq"} className="option-btn">
                  <i className="ri-customer-service-2-line"></i> Help Center
                </Link>
                <Link to="/account/edit-profile" className="option-btn" >
                  <i className="ri-profile-line"></i> Edit Profile
                </Link>
                <a className="option-btn">
                  <i className="ri-wallet-3-line"></i> My Cards & Wallet
                </a>
              </div>
            </div>
          </div>
          <div className="my-profiles-account-details">
            <h3>Account Details</h3>
            <p className="my-profile-name">
              {userData.fname + " " + userData.lname}
            </p>
            {userData.address.length > 0 && defaultAddress[0] && (
              <p className="profile-address">
                {defaultAddress[0].fname + " " + defaultAddress[0].lname} <br />{" "}
                {defaultAddress[0].addresOne} <br />
                {defaultAddress[0].addressTwo} <br /> - <br />
                {defaultAddress[0].postalCode} {defaultAddress[0].city}{" "}
                {defaultAddress[0].province} {defaultAddress[0].country}
              </p>
            )}
            <Link to="/account/addresses" className="view-address-btn">
              View Address ({userData.address.length})
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default MyProfile;

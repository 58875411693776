import React, { useState } from "react";
import "./GlobalModal.css";

const GlobalModal = ({ isGlobalModalOpen, toggleGlobalModal, callback }) => {
  return (
    <div className="product-global-modal">
      <div className={`overlay ${isGlobalModalOpen ? "show" : ""}`}></div>

      <div
        className={`global_modal-sliding-drawer-container ${
          isGlobalModalOpen ? "open" : ""
        }`}
      >
        <div className="global_modal-drawer-content">
          <div className="global_modal-drawer__inner">
            <div className="global_modal-drawer__scrollable">
              {/* <div className="global_modal-drawer-header">
                <i className="ri-close-fill" onClick={toggleGlobalModal}></i>
              </div> */}
              <div className="global_modal_content_area">
                <p className="title">{callback && callback?.title}</p>
                <div className="button-container">
                <button
                  className="btn confirm-btn"
                  onClick={() => {
                    callback?.toggle(callback.params);
                    toggleGlobalModal();
                  }}
                >
                  Confirm
                </button>
                <button
                  className="btn confirm-btn"
                  onClick={() => {
                    toggleGlobalModal();
                  }}
                >
                  Cancel
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalModal;

import React from "react";
import "./OffersHeading.css";

const OffersHeading = () => {
  return (
    <div className="offer_heading">
      <a href="/#" className="container">
        <span>UPTO 70% OFF + Extra 5% Off on Prepaid Orders</span>
      </a>
    </div>
  );
};

export default OffersHeading;

import React, { useState } from "react";
import "./SizeChart.css";
import SizeChartTab from "../SizeChartTab/SizeChartTab";
import { size_chart_cloth_sample } from "../../../assets/images";

const SizeChart = ({ sizeDetails }) => {
  const tabData = [{ tabTitle: "IN" }, { tabTitle: "CM" }];

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="size_chart_container">
      <div className="size-chart-tabs">
        {tabData.map((tab, index) => (
          <SizeChartTab
            key={index}
            label={tab.tabTitle}
            onClick={() => handleTabClick(index)}
            isActive={index === activeTab}
          />
        ))}
      </div>
      <div className="size-chart-tab-content">
        <h3>OUTFITS (GARMENT SIZE)</h3>
        {activeTab === 0 ? (
          <>
            <table>
              <tbody>
                <tr>
                  {sizeDetails &&
                    Object.keys(sizeDetails[0]).map((items,index) => {
                      if (items !== "SIZE" && items !== "STOCK") {
                        return <th key={index}>{items}</th>;
                      }
                    })}
                </tr>

                {sizeDetails &&
                  sizeDetails.map((items,index) => {
                    return (
                      <tr key={index}>
                        {Object.keys(items).map((data) => {
                          if (data !== "SIZE" && data !== "STOCK") {
                            return <td key={data}>{items[data]}</td>;
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <p>
              <strong>Measured in 'Inches'</strong>
              Measured in 'Inches' These are Garment sizes, take your body
              measurements and match the garment accordingly
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  {sizeDetails &&
                    Object.keys(sizeDetails[0]).map((items) => {
                      if (items !== "SIZE" && items !== "STOCK") {
                        return <th key={items}>{items}</th>;
                      }
                    })}
                </tr>

                {sizeDetails &&
                  sizeDetails.map((items) => {
                    return (
                      <tr>
                        {Object.keys(items).map((data) => {
                          if (data !== "SIZE" && data !== "STOCK") {
                            if(data !== "STANDARD"){
                              return <td key={data}>{Math.floor(parseInt(items[data]* 2.54))}</td>;
                            }
                            return <td key={data}>{items[data]}</td>;
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <p>
              <strong>Measured in 'Centimeter'</strong>
              These are Garment sizes, take your body measurements and match the
              garment accordingly
            </p>
          </>
        )}
        <img src={size_chart_cloth_sample} alt="" />
        <div className="size-chart-sample-image"></div>
      </div>
    </div>
  );
};

export default SizeChart;

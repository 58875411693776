import React from "react";
import "./TextField.css";

const NormalTextFields = ({
  titleLabel,
  value,
  name,
  Placeholder,
  type,
  onChange,
}) => {
  return (
    <div className="text-field">
      <label className="text-field-label">{titleLabel}</label>
      <input
        className="text-field-input"
        value={value}
        name={name}
        placeholder={Placeholder}
        type={type}
        onChange={onChange}
      />
    </div>
  );
};

export default NormalTextFields;

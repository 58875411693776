import React, { useEffect, useState } from "react";
import "./SubCollectionCircles.css";
import { Link, useLocation } from "react-router-dom";
import { subCate1, subCate2, subCate3, subCate4 } from "../../../assets/images";
import { getWithoutAuth } from "../../../api/api";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../../store";

const SubCategoryCircles = ({ isHome, isEmpty }) => {
  const [subCollectionsData, setSubCollection] = useState(null);
  const location = useLocation();
  const id = location.state;
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(toggleLoading());
      getWithoutAuth("product/get-banners?category=" + id._id)
        .then((res) => {
          console.log(res.data);
          let newData = res.data.load.map((items) => {
            console.log("images 11" + items.image);
            return {
              id: items._id,
              image: items.image
                ? "https://amarnathckr.online/api/v1/upload/" +
                  items.image
                : subCate1,
              name: items.name,
            };
          });
          if (id) {
            newData = newData.filter((items) => items.id !== id);
          }
          if (newData.length <= 0) {
            isEmpty && isEmpty(true);
          } else {
            isEmpty && isEmpty(false);
          }
          setSubCollection(newData);
          dispatch(toggleLoading());
        })

        .catch((err) => {
          isEmpty && isEmpty(true);
          dispatch(toggleLoading());
        });
    } else {
      dispatch(toggleLoading())
      getWithoutAuth("product/get-banners?category=")
        .then((res) => {
          console.log(res.data);
          const newData = res.data.load.map((items) => {
            return {
              id: items._id,
              image: items.image
                ? "https://amarnathckr.online/api/v1/upload/" +
                  items.image
                : subCate1,
              name: items.name,
            };
          });
          if (newData.length <= 0) {
            isEmpty && isEmpty(true);
          } else {
            isEmpty && isEmpty(false);
          }
          setSubCollection(newData);
          dispatch(toggleLoading())
        })
        .catch((err) => {
          isEmpty && isEmpty(true);
          dispatch(toggleLoading())
        });
    }
  }, [id]);

  return (
    <div
      className={`sub_collations_circle_container${
        isHome ? "-home" : ""
      } container`}
    >
      <div className="sub-collection-list">
        {subCollectionsData?.map((collection) => (
          <Link
            to={`/products/${collection.name
              .split(" ")
              .join("-")
              .toLowerCase()}`}
            state={{ _id: collection.id, ...collection }}
            key={collection.id}
          >
            <figure>
              <img
                src={collection?.image}
                alt="Cotton Kurta Sets"
                width="200"
                height="200"
                loading="lazy"
              />
            </figure>
            <div className="title">{collection.name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SubCategoryCircles;

import React, { useState } from "react";
import "./OrderReturn.css";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { postAnyAuth } from "../../api/api";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading } from "../../store";
import SelectField from "../Select/SelectField";
import * as Yup from "yup";
import { useFormik } from "formik";
function OrderReturn({ order, close, trigger }) {
  const [question, setQuestion] = useState("Have other quality related issues");
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    console.log("close");
    close();
    trigger();
  };

  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (order.paymentMethod === "COD") {
      if (
        formik.errors.accountHolderName ||
        formik.errors.bankName ||
        formik.errors.accountNumber ||
        formik.errors.ifscCode
      ) {
        return;
      }

      if (!checked) {
        setError("Please agree to the terms and conditions");
        return toast.error("Please agree to the terms and conditions");
      }
    }

    console.log(question, answer);
    if (answer.length < 100) {
      return setError("Minimum 100 characters required");
    } else if (answer.length > 1000) {
      return setError("Maximum 1000 characters allowed");
    }
    if (!question) {
      return setError("Please choose a question");
    }
    dispatch(toggleLoading());
    if (order.paymentMethod === "COD") {
      postAnyAuth(
        "order/return-order",
        {
          subOrderId: order.subOrderId,
          question,
          answer,
          bankDetails: formik.values,
        },
        token
      )
        .then((res) => {
          dispatch(toggleLoading());
          toast.success("Order cancelled successfully");
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
          toast.error(err.response.data.message);
        });
    } else {
      postAnyAuth(
        "order/return-order",
        {
          subOrderId: order.subOrderId,
          question,
          answer,
        },
        token
      )
        .then((res) => {
          dispatch(toggleLoading());
          toast.success("Order cancelled successfully");
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
          toast.error(err.response.data.message);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      ifscCode: "",
      bankName: "",
      accountHolderName: "",
    },
    validationSchema: Yup.object({
      accountNumber: Yup.string()
        .matches(/^[0-9]{9,18}$/, "Invalid account number")
        .required("Account number is required"),
      ifscCode: Yup.string()
        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code")
        .required("IFSC code is required"),
      bankName: Yup.string().required("Bank name is required"),
      accountHolderName: Yup.string().required(
        "Account holder name is required"
      ),
    }),
    onSubmit: (values) => {
      // Handle form submission (e.g., send data to server)
      console.log("Form submitted:", values);
    },
  });

  return (
    <div className="product-quickview">
      {/* Overlay */}
      <div className={`overlay ${order ? "show" : ""}`} onClick={close}></div>

      {/* Sliding Drawer */}
      <div
        className={`canccellation-sliding-drawer-container ${
          order ? "open" : ""
        }`}
      >
        <div className="canccellation-drawer-content">
          <div className="canccellation-drawer__inner">
            <div className="canccellation-drawer__scrollable">
              {/* <div className="canccellation-drawer-header order-cancellation-header">
                <h2>Order Cancellation</h2>
                <i className="ri-close-fill" onClick={close}></i>
              </div> */}
              <div className="header-container">
                <h2>Order Return</h2>
                <button className="close-button" onClick={handleClose}>
                  X
                </button>
              </div>
              <div className="canccellation__items">
                {" "}
                <form onSubmit={handleSubmit}>
                  <div className="main-container">
                    <div className="order_cancellation_items">
                      <div key={order.subOrderId} className="orders_item">
                        <div className="cancellation_item_info">
                          <img
                            className="image-cancel"
                            src={
                              "https://amarnathckr.online/api/v1/upload/" +
                              order.productId.images[0]
                            }
                            alt={order.productId.images[0]}
                          />
                          <div className="cancellation-text-infos">
                            <h2>{order.productId.title}</h2>
                            <p>Size: {order.size}</p>
                            <p>Qty : {order.quantity}</p>
                            <p>Size: {order.size}</p>
                            <p>Price: ₹{order.productValue}</p>
                            <p>Order ID: {order.subOrderId}</p>
                            <p>Payment Method : {order.paymentMethod}</p>
                          </div>
                        </div>
                      </div>
                      {order.paymentMethod === "CARD" && (
                        <p className="refund-text">
                          Your refund will be credited to your account in 7
                          working days after we verify the product.
                        </p>
                      )}
                      {order.paymentMethod === "COD" && (
                        <div className="form-container-bank">
                          <p className="refund-text">
                            Please provide the bank details for the refund
                            amount.
                          </p>
                          <div className="text-field">
                            <label className="text-field-label">
                              Account Number
                            </label>
                            <input
                              className={`text-field-input  ${
                                formik.touched.accountNumber &&
                                formik.errors.accountNumber &&
                                "border-red-error"
                              }`}
                              name="accountNumber"
                              id="accountNumber"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.accountNumber}
                            />
                            {formik.touched.accountNumber &&
                              formik.errors.accountNumber && (
                                <div className="field-error-text">
                                  {formik.errors.accountNumber}
                                </div>
                              )}
                          </div>
                          <div className="text-field">
                            <label className="text-field-label">
                              IFSC Code
                            </label>
                            <input
                              className={`text-field-input  ${
                                formik.touched.ifscCode &&
                                formik.errors.ifscCode &&
                                "border-red-error"
                              }`}
                              name="ifscCode"
                              id="ifscCode"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.ifscCode}
                            />
                            {formik.touched.ifscCode &&
                              formik.errors.ifscCode && (
                                <div className="field-error-text">
                                  {formik.errors.ifscCode}
                                </div>
                              )}
                          </div>
                          <div className="text-field">
                            <label className="text-field-label">
                              Bank Name
                            </label>
                            <input
                              className={`text-field-input  ${
                                formik.touched.bankName &&
                                formik.errors.bankName &&
                                "border-red-error"
                              }`}
                              name="bankName"
                              id="bankName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.bankName}
                            />
                            {formik.touched.bankName &&
                              formik.errors.bankName && (
                                <div className="field-error-text">
                                  {formik.errors.bankName}
                                </div>
                              )}
                          </div>
                          <div className="text-field">
                            <label className="text-field-label">
                              Account Holder Name
                            </label>
                            <input
                              className={`text-field-input  ${
                                formik.touched.accountHolderName &&
                                formik.errors.accountHolderName &&
                                "border-red-error"
                              }`}
                              name="accountHolderName"
                              id="accountHolderName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.accountHolderName}
                            />
                            {formik.touched.accountHolderName &&
                              formik.errors.accountHolderName && (
                                <div className="field-error-text">
                                  {formik.errors.accountHolderName}
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                      <div className="question-label">
                        <label>Reason for the Return:</label>
                        <SelectField
                          onChange={(e) => {
                            setError(null);
                            setQuestion(e.target.value);
                          }}
                          value={question}
                        >
                          <option value="Have other quality related issues">
                            Have other quality related issues
                          </option>
                          <option value="Received wrong product">
                            Received wrong product
                          </option>
                          <option value="Have size / fit related issues">
                            Have size / fit related issues
                          </option>
                          <option value="Don't need the product anymore">
                            Don't need the product anymore
                          </option>
                          <option value="Received incomplete product">
                            Received incomplete product
                          </option>
                        </SelectField>
                      </div>
                      <label>Write a detailed reason for the Return:</label>
                      <textarea
                        onChange={(e) => {
                          setError(null);
                          setAnswer(e.target.value);
                        }}
                        value={answer}
                        className="text-area-cancel"
                        placeholder="Write a reason here..."
                      />

                      {order.paymentMethod === "COD" && (
                        <div className="terms-condition-checkbox-container">
                          <label>
                            <input
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                              type="checkbox"
                            />
                            <span> </span>I verify that the bank details
                            provided above are accurate and authorize the refund
                            to be processed to this account. <br />
                            <br />
                            By checking this box, I agree to the terms and
                            conditions of the return policy.
                          </label>
                        </div>
                      )}

                      {error && <p className="error-text-cancel">{error}</p>}

                      <div className="btn-container">
                        <button className="btn cancel-btn">Return Order</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderReturn;

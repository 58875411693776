import React from "react";
import "./Specifications.css";
import { ProductDetails } from "../Pages";

const Specifications = ({ ProductSpecifications, subCategory }) => {
  const productDetails = ProductSpecifications?.productDetails?.map(
    (items, index) => {
      return {
        specifications_title: `${
          index <= 1 ? subCategory[0].shortName + " " : ""
        }${items.name}`,
        specifications_value: `${items.value.value}${
          items.name === "MODEL HEIGHT" ? " Feets" : ""
        }`,
      };
    }
  );

  const otherDetails = ProductSpecifications?.otherDetails?.map((items) => {
    return {
      specifications_title: items.name,
      specifications_value: `${items.value.value}${
        items.name === "MODEL HEIGHT" ? " Feet" : ""
      }`,
    };
  });

  const colorDetails = ProductSpecifications?.colorDetails?.map((items) => {
    return {
      specifications_title: items.name,
      specifications_value: items.value.value,
    };
  });

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <div className="specifications_contents">
      {productDetails?.map((specifications, index) => (
        <div className="specifications_item" key={index}>
          <span className="specifications_item_title">
            {capitalize(specifications.specifications_title)}
          </span>
          <p className="specifications_item_desc">
            {capitalize(specifications.specifications_value)}
          </p>
        </div>
      ))}
      {otherDetails?.map((specifications, index) => (
        <div className="specifications_item" key={index}>
          <span className="specifications_item_title">
            {capitalize(specifications.specifications_title)}
          </span>
          <p className="specifications_item_desc">
            {capitalize(specifications.specifications_value)}
          </p>
        </div>
      ))}
      {colorDetails?.map((specifications, index) => (
        <div className="specifications_item" key={index}>
          <span className="specifications_item_title">
            {capitalize(specifications.specifications_title)}
          </span>
          <p className="specifications_item_desc">
            {capitalize(specifications.specifications_value)}
          </p>
        </div>
      ))}

      <div
        className="specifications_item"
        key={ProductSpecifications?.colorFamily}
      >
        <span className="specifications_item_title">COLOR FAMILY</span>
        <p className="specifications_item_desc">
          {ProductSpecifications?.colorFamily &&
            ProductSpecifications?.colorFamily?.map((a, index) => {
              let val = a.label;
              if (index !== ProductSpecifications.colorFamily.length - 1) {
                val += ", ";
              }
              return capitalize(val);
            })}
        </p>
      </div>
    </div>
  );
};

export default Specifications;

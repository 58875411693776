import React from "react";
import "./Occasion.css";
// import occasion from "../../../assets/images/occasion.avif";
import SectionHeadings from "../../Headings/SectionHeadings/SectionHeadings";
import { Link } from "react-router-dom";

const Occasion = ({ occasionData }) => {
  console.log(occasionData);

  return (
    <section className="section occasion-section">
      <SectionHeadings
        title="Occasions"
        paragraph="Discover our wide range of women’s ethnic wear and western wear fashion"
      />

      <div className="occasion-container container">
        <div className="occasion-items">
          {occasionData &&
            occasionData.map((items) => {
              return (
                <div className="occasion">
                  <Link to={`/products/${items.name.value}?occasion=${items.name._id}`}>
                    <img
                      src={"https://amarnathckr.online/api/v1/upload/" + items.image}
                      alt={items.image}
                    />
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Occasion;

import React from "react";
import "./TrackingDetails.css";
import TrackingDetailItem from "./TrackingDetailItem";
import TrackingDetailItemMobile from "./TrackingDetailItemMobile";
import moment from "moment";

const TrackingDetails = ({ data, isMobile }) => {
  const { trackHeader, trackDetails } = data;

  // Function to format date
  const formatDate = (dateString) => {
    return moment(dateString, "DDMMYYYY").format("YYYY-MM-DD");
  };

  // Function to format time
  const formatTime = (timeString) => {
    return moment(timeString, "HHmm").format("HH:mm");
  };

  return (
    <div className="tracking-details-container container">
      <div class="timeline">
        {trackDetails.map((detail, index) => (
          
          <TrackingDetailItem length={trackDetails.length} index={index} key={index} detail={detail} />
        ))}
      </div>
    </div>
  );
};

export default TrackingDetails;

import React, { useState } from "react";
import "./SignupForm.css";
import TextField from "../../../Inputs/TextField";
import { Form, Formik } from "formik";
import { customerYupSchema } from "../../../../utils/validationSchema/customerYupSchema";
import { PostAnyApi, getWithoutAuth } from "../../../../api/api";
import { useNavigate } from "react-router-dom";
import { toggleLoading } from "../../../../store";
import { useDispatch } from "react-redux";

const SignupForm = () => {
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="signup-form">
      <div className="signup_form_container container">
        <div className="signup-form-title">
          <h1>CREATE ACCOUNT</h1>
        </div>
        <Formik
          initialValues={{
            fname: "",
            lname: "",
            email: "",
            password: "",
          }}
          validationSchema={customerYupSchema.signup}
          onSubmit={(values) => {
            // Handle form submission (e.g., send data to server)
            setError(null);
            console.log("Form submitted with values:", values);
            dispatch(toggleLoading())
            PostAnyApi("/customer/signup", values)
              .then((res) => {
                dispatch(toggleLoading())
                res.status === 200 &&
                  navigate("/verify-otp", { state: { email: values.email } });
              })
              .catch((err) => {
                console.log(err.response.data.message);
                setError(err.response.data.message);
                dispatch(toggleLoading())
              });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <TextField
                titleLabel="First Name"
                name="fname"
                Placeholder="Enter first name here"
                type="text"
                touched={touched.fname}
                error={errors.fname}
              />
              {console.log(errors)}
              <TextField
                titleLabel="Last Name"
                name="lname"
                Placeholder="Enter last name here"
                type="text"
                touched={touched.lname}
                error={errors.lname}
              />

              <TextField
                titleLabel="Email"
                name="email"
                touched={touched.email}
                Placeholder="Enter email here"
                type="email"
                error={errors.email}
              />

              <TextField
                titleLabel="Password"
                name="password"
                touched={touched.password}
                Placeholder="Enter password here"
                type="password"
                error={errors.password}
              />

              {error && <div className="field-error-text">{error}</div>}

              <button type="submit" className="btn sign-in-btn">
                Create
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignupForm;

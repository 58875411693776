import React, { useState } from "react";
import "./AddressForm.css";
import TextField from "../Inputs/TextField";
import SelectField from "../Select/SelectField";
import { Form, Formik } from "formik";
import { customerYupSchema } from "../../utils/validationSchema/customerYupSchema";
import { PostAnyApi, postAnyAuth } from "../../api/api";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useDispatch, useSelector } from "react-redux";
import { subscribeUser, toggleLoading } from "../../store";

const AddressForm = ({ close, editData }) => {
  const [error,setError] = useState(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const handleClose = () => {
    close();
  };
  return (
    <div className="address-form-container">
      <div className="address-form-title">
        <h1>Add Address</h1>
      </div>
      <Formik
        initialValues={{
          fname: editData ? editData.fname :  "",
          lname: editData ? editData.lname :  "",
          addressOne: editData ? editData.addressOne :  "",
          addressTwo: editData ? editData.addressTwo :  "",
          city: editData ? editData.city :  "",
          country: editData ? editData.country :  "India",
          province: editData ? editData.province :  "",
          postalCode: editData ? editData.postalCode :  "",
          contact: editData ? editData.contact :  "",
          isDefault: editData ? editData.isDefault :  false,
        }}
        validationSchema={customerYupSchema.address}
        onSubmit={(values) => {
          // Handle form submission (e.g., send data to server)
          // setError(null);
          console.log("Form submitted with values:", values);
          dispatch(toggleLoading())
          postAnyAuth(editData ? "/customer/edit-address/"+editData._id : "/customer/add-address", values, token)
            .then((res) => {
              console.log(res);
              dispatch(subscribeUser(res.data.load))
              localStorage.setItem(
                "user",
                JSON.stringify(res.data.load)
              );
              dispatch(toggleLoading())
              close()
            })
            .catch((err) => {
              console.log(err.response.data.message);
              setError(err.response.data.message);
              dispatch(toggleLoading())
            });
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur }) => {
          return (
            <Form className="address-form">
              <div className="flex-form-field">
                <TextField
                  titleLabel="First Name"
                  value={values.fname}
                  name="fname"
                  error={errors.fname}
                  Placeholder="Enter First Name"
                  type="text"
                  touched={touched.fname}
                />
                <TextField
                  titleLabel="Last Name"
                  value={values.lname}
                  error={errors.lname}
                  name="lname"
                  Placeholder="Enter Last Name"
                  type="text"
                  touched={touched.lname}
                />
              </div>
              <TextField
                titleLabel="Address1"
                value={values.addressOne}
                name="addressOne"
                error={errors.addressOne}
                Placeholder="Enter Address1"
                touched={touched.addressOne}
                type="text"
              />
              <TextField
                titleLabel="Address2"
                value={values.addressTwo}
                name="addressTwo"
                Placeholder="Enter Address2"
                type="text"
                touched={touched.addressTwo}
                error={errors.addressTwo}
              />
              <div className="flex-form-field">
                <TextField
                  titleLabel="City"
                  name="city"
                  value={values.city}
                  Placeholder="Enter City"
                  type="text"
                  error={errors.city}
                  touched={touched.city}
                />

                <div className="select-field">
                  <label className="select-field-label">{"Province"}</label>

                  <RegionDropdown
                    name="province"
                    id="province"
                    classes={`select-field-tag ${
                      touched.province && errors.province && "border-red-error"
                    }`}
                    country={values.country}
                    value={values.province}
                    onChange={(a, b) => handleChange(b)}
                    onBlur={handleBlur}
                  />
                  {touched.province && errors.province && (
                    <div className="field-error-text">{errors.province}</div>
                  )}
                </div>
              </div>
              <div className="select-field">
                <label className="select-field-label">{"Country"}</label>

                <CountryDropdown
                  classes={`select-field-tag ${
                    touched.province && errors.country && "border-red-error"
                  }`}
                  name="country"
                  id="country"
                  value={values.country}
                  onChange={(a, b) => handleChange(b)}
                  onBlur={handleBlur}
                />
                {touched.country && errors.country && (
                  <div className="field-error-text">{errors.country}</div>
                )}
              </div>

              <div className="flex-form-field">
                <TextField
                  titleLabel="POSTAL/ZIP CODE"
                  value={values.postalCode}
                  name="postalCode"
                  error={errors.postalCode}
                  Placeholder="Enter postal/zip code"
                  type="Number  "
                  touched={touched.postalCode}
                />
                <TextField
                  titleLabel="PHONE"
                  value={values.contact}
                  name="contact"
                  Placeholder="Enter phone"
                  type="Number"
                  error={errors.contact}
                  touched={touched.contact}
                />
              </div>
              <div className="set-as-default-checkbox">
                <label>
                  <input
                    onChange={handleChange}
                    type="checkbox"
                    id="isDefault"
                    name="isDefault"
                  />{" "}
                  Set as default Address
                </label>
              </div>
              {error && <div className="field-error-text">{error}</div>}
              <button type="submit" className="btn add-address-btn">
                {editData ? "Save Changes" : "Create"}
              </button>
              <button onClick={handleClose} className="cancel-address-btn">
                Cancel
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddressForm;

import React, { useState } from "react";
import "./OrderCancellation.css";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { postAnyAuth } from "../../api/api";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading } from "../../store";
import SelectField from "../Select/SelectField";

function OrderCancellation({ order, close, trigger }) {
  const [question, setQuestion] = useState(
    "What is the reason for canceling your order?"
  );
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(null);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      height: "fit-content",
      width: "40%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
  };

  console.log(order);

  const handleClose = () => {
    console.log("close");
    close();
    trigger();
  };

  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(question, answer);
    if (answer.length < 100) {
      return setError("Minimum 100 characters required");
    } else if (answer.length > 1000) {
      return setError("Maximum 1000 characters allowed");
    }
    if (!question) {
      return setError("Please choose a question");
    }
    dispatch(toggleLoading());
    postAnyAuth(
      "order/cancel-order",
      {
        subOrderId: order.subOrderId,
        question,
        answer,
      },
      token
    )
      .then((res) => {
        console.log(res.data);
        dispatch(toggleLoading());
        toast.success("Order cancelled successfully");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        dispatch(toggleLoading());
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="product-quickview">
      {/* Overlay */}
      <div className={`overlay ${order ? "show" : ""}`} onClick={close}></div>

      {/* Sliding Drawer */}
      <div
        className={`canccellation-sliding-drawer-container ${
          order ? "open" : ""
        }`}
      >
        <div className="canccellation-drawer-content">
          <div className="canccellation-drawer__inner">
            <div className="canccellation-drawer__scrollable">
              {/* <div className="canccellation-drawer-header order-cancellation-header">
                <h2>Order Cancellation</h2>
                <i className="ri-close-fill" onClick={close}></i>
              </div> */}
              <div className="header-container">
                <h2>Order Cancellation</h2>
                <button className="close-button" onClick={handleClose}>
                  X
                </button>
              </div>
              <div className="canccellation__items">
                {" "}
                <form onSubmit={handleSubmit}>
                  <div className="main-container">
                    <div className="order_cancellation_items">
                      <div key={order.subOrderId} className="orders_item">
                        <div className="cancellation_item_info">
                          <img
                            className="image-cancel"
                            src={
                              "https://amarnathckr.online/api/v1/upload/" +
                              order.productId.images[0]
                            }
                            alt={order.productId.images[0]}
                          />
                          <div className="cancellation-text-infos">
                            <h2>{order.productId.title}</h2>
                            <p>Size: {order.size}</p>
                            <p>Qty : {order.quantity}</p>
                            <p>Size: {order.size}</p>
                            <p>Price: ₹{order.productValue}</p>
                            <p>Order ID: {order.subOrderId}</p>
                            <p>Payment Method : {order.paymentMethod}</p>
                          </div>
                        </div>
                      </div>
                      {order.paymentMethod === "CARD" && (
                        <p className="refund-text">
                          Your refund will be credited to your account in 7
                          working days.
                        </p>
                      )}
                      <div className="question-label">
                        <label>Reason for Cancellation:</label>
                        <SelectField
                          onChange={(e) => {
                            setError(null);
                            setQuestion(e.target.value);
                          }}
                          value={question}
                        >
                          <option value="What is the reason for canceling your order?">
                            What is the reason for canceling your order?
                          </option>
                          <option value="Did you find a better deal elsewhere?">
                            Did you find a better deal elsewhere?
                          </option>
                          <option value="Are you dissatisfied with the product or service?">
                            Are you dissatisfied with the product or service?
                          </option>
                        </SelectField>
                      </div>
                      <label>Write a detailed reason for cancellation:</label>
                      <textarea
                        onChange={(e) => {
                          setError(null);
                          setAnswer(e.target.value);
                        }}
                        value={answer}
                        className="text-area-cancel"
                        placeholder="Write a reason here..."
                      />
                      {error && <p className="error-text-cancel">{error}</p>}
                      <div className="btn-container">
                        <button className="btn cancel-btn">Cancel Order</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderCancellation;

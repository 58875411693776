import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTrigger } from "../../../store";

function OrderSuccess() {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      localStorage.setItem("cart", JSON.stringify([]));
      dispatch(toggleTrigger());
      window.location.href = ("/account/order-history");
    }
  }, [token]);
  return <div>Loading</div>;
}

export default OrderSuccess;

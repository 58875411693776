import React from "react";
import "./TrackingDetailItem.css";
import moment from "moment";

const TrackingDetailItem = ({ length, detail, index }) => {
  // Function to format date
  const formatDate = (dateString) => {
    return moment(dateString, "DDMMYYYY").format("YYYY-MM-DD");
  };

  // Function to format time
  const formatTime = (timeString) => {
    return moment(timeString, "HHmm").format("hh:mm A");
  };

  if (index === 0) {
    return (
      <div class="tl-content">
        <div class="tl-header">
          <span class="tl-marker"></span>
          <p class="tl-title">Order {detail.strAction}</p>
          <time class="tl-time" datetime="2023-06-20">
            {formatDate(detail.strActionDate)}{" "}
            {formatTime(detail.strActionTime)}
          </time>
        </div>
        <div class="tl-body">
          <p>{detail.strOrigin}</p>
        </div>
      </div>
    );
  }
  if (index === length - 1) {
    return (
      <div class="tl-content tl-content-active">
        <div class="tl-header">
          <span class="tl-marker"></span>
          <p class="tl-title">Order {detail.strAction}</p>
          <time class="tl-time" datetime="2023-06-20">
            {formatDate(detail.strActionDate)}{" "}
            {formatTime(detail.strActionTime)}
          </time>
        </div>
        <div class="tl-body">
          <p>{detail.strOrigin}</p>
        </div>
      </div>
    );
  }

  return (
    <div class="tl-content">
      <div class="tl-header">
        <span class="tl-marker"></span>
        <p class="tl-title">Order {detail.strAction}</p>
        <time class="tl-time" datetime="2023-06-20">
          {formatDate(detail.strActionDate)} {formatTime(detail.strActionTime)}
        </time>
      </div>
      <div class="tl-body">
        <p>{detail.strOrigin}</p>
      </div>
    </div>
  );

  // return (
  //   <div className="tracking-detail-item">
  //     {/* <div className="detail-code">{detail.strCode}</div> */}
  //     <div className="detail-action">Order {detail.strAction}</div>
  //     <div className="detail-date">{formatDate(detail.strActionDate)}</div>
  //     <div className="detail-time">{formatTime(detail.strActionTime)}</div>
  //     <div className="detail-origin">{detail.strOrigin}</div>
  //     <div className="detail-destination">{detail.strDestination}</div>
  //     <div className="detail-remarks">{detail.sTrRemarks}</div>
  //   </div>
  // );
};

export default TrackingDetailItem;

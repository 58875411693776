import React, { useEffect, useState } from "react";
import { getWithoutAuth } from "../../../api/api";
import toast from "react-hot-toast";
import SectionHeadings from "../../Headings/SectionHeadings/SectionHeadings";
import CollapsibleList from "../../CollapsibleList/CollapsibleList";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../../store";

function FAQPage() {
  const [faq, setFAQ] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleLoading());
    getWithoutAuth("faq")
      .then((res) => {
        setFAQ(res.data.load);
        dispatch(toggleLoading());
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(toggleLoading());
      });
  }, []);

  if (!faq) return null;

  return (
    <>
      <section className="faq_section section">
        <div className="faq_container container">
          <SectionHeadings
            title="FAQ's"
            paragraph="Our Frequently Ask Questions by the customers who have asked recently"
          />
          <div className="faq_list">
            {faq.map((items) => {
              return (
                <CollapsibleList
                  keyVal={items.title}
                  collapsibleTitle={items.title}
                  collapsibleValues={items.description}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default FAQPage;

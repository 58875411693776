import React from "react";
import "./FAQ.css";
import SectionHeadings from "../Headings/SectionHeadings/SectionHeadings";
import CollapsibleList from "../CollapsibleList/CollapsibleList";
import { Link } from "react-router-dom";

const FAQ = ({ list }) => {
  if (!list) return null;
  return (
    <section className="faq_section section">
      <div className="faq_container container">
        <SectionHeadings
          title="FAQ's"
          paragraph="Our Frequently Ask Questions by the customers who have asked recently"
        />
        <div className="faq_list">
          {list.map((items) => {
            return (
              <CollapsibleList
                keyVal={items.description}
                collapsibleTitle={items.title}
                collapsibleValues={items.description}
              />
            );
          })}

          <Link to="/faq" className="btn">
            Read More FAQ's ?
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FAQ;

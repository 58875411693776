import React, { useEffect, useState } from "react";
import "./LoginForm.css";
import TextField from "../../../Inputs/TextField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { PostAnyApi } from "../../../../api/api";
import { customerYupSchema } from "../../../../utils/validationSchema/customerYupSchema";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../../../store";

const VeifyOTP = () => {
  const [error, setError] = useState(null);
  const [minutes, setMinutes] = useState(0); // Initial minutes
  const [seconds, setSeconds] = useState(40); // Initial seconds

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setSeconds(59);
          setMinutes(minutes - 1);
        } else {
          clearInterval(interval);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(5);
    setSeconds(0);
    // You can also make an API call here to resend the OTP
  };

  const location = useLocation();

  console.log(location.state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = (value) => {
    setError(null);
    dispatch(toggleLoading());
    PostAnyApi("/customer/verify-otp", {
      otp: value.otp,
      email: location.state.email,
    })
      .then((res) => {
        dispatch(toggleLoading());
        res.status === 200 && navigate("/login");
      })
      .catch((err) => {
        console.log(err);

        setError(err.response.data.message);
        dispatch(toggleLoading());
      });
  };

  const handleResend = () => {
    setError(null);
    dispatch(toggleLoading())
    PostAnyApi("/customer/resend-otp", {
      email: location.state.email,
    })
      .then((res) => {
        dispatch(toggleLoading())
        resendOTP();
        setError(null);
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
        dispatch(toggleLoading())
      });
  };

  return (
    <div className="login-form">
      <div className="login_form_container container">
        <div className="login-form-title">
          <h1>Verify OTP</h1>
        </div>

        <Formik
          initialValues={{
            otp: "",
          }}
          className="login-form-contents"
          validationSchema={customerYupSchema.otp}
          onSubmit={handleFormSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <TextField
                titleLabel="please enter your otp received in your mail. OTP expires in 5 mins."
                name="otp"
                error={errors.otp}
                placeholder="OTP"
                type="OTP"
              />
              {error && <div className="field-error-text">{error} </div>}
              <a className="resend-button">
                <button
                  onClick={handleResend}
                  disabled={seconds > 0 || minutes > 0}
                  type="reset"
                >
                  Resend OTP
                </button>
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Didn't receive the code?</p>
                )}
              </a>
              <button type="submit" className="btn sign-in-btn">
                Verify OTP
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VeifyOTP;

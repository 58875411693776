import React, { useEffect, useState } from "react";
import "./TrackOrders.css";
import TextField from "../../Inputs/TextField";
import { PostAnyApi, postAnyAuth } from "../../../api/api";
import TrackingDetails from "../../TrackingDetails/TrackingDetails";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../../store";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const TrackOrders = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [trackingDetails, setTracking] = useState(null);
  const [error, setError] = useState(null);
  const [values, setValues] = useState({
    orderId: "",
    trackingId: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    orderId: "",
    trackingId: "",
    email: "",
  });
  const [touched, setTouched] = useState({
    orderId: "",
    trackingId: "",
    email: "",
  });

  const location = useLocation();

  console.log(location.state);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    if (location.state) {
      setValues({
        orderId: "",
        trackingId: location.state,
        email: "",
      });

      handleSubmit(true,location.state);
    }

    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location]);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("order-number");

  const handleTabClick = (tab) => {
    setErrors({
      orderId: "",
      trackingId: "",
      email: "",
    });
    setTouched({
      orderId: "",
      trackingId: "",
      email: "",
    });
    setValues({
      orderId: "",
      trackingId: "",
      email: "",
    });
    setActiveTab(tab);
  };

  const handleChange = ({ target: { name, value } }) => {
    setValues((prev) => {
      const newData = { ...prev };
      newData[name] = value;
      return newData;
    });
    setErrors((prev) => {
      const newData = { ...prev };
      newData[name] = "";
      return newData;
    });
    setError(null);
  };

  const handleSubmit = (typo = true, track) => {
    let requestObject;

    console.log(values);

    if (track) {
      dispatch(toggleLoading());
      return PostAnyApi("/order-tracking/track-order", {
        trackingId: track,
        typo: true,
      })
        .then((response) => {
          dispatch(toggleLoading());
          if (response.status === 200) {
            console.log(response.data);
            setError(null);
            setTracking(response.data);
          }
        })
        .catch((err) => {
          dispatch(toggleLoading());
          if (err.response.status === 404) {
            toast.error(err.response.data.message);
            setError(err.response.data.message);
          }
        });
    }

    if (typo) {
      if (values.trackingId.length < 6) {
        setErrors({
          trackingId: "Please enter valid trackingId",
        });
        setTouched({
          trackingId: "Please enter valid trackingId",
        });
        return;
      }
      requestObject = {
        trackingId: values.trackingId,
        typo,
      };
    } else {
      if (values.email.length > 6 && values.orderId.length > 6) {
        requestObject = {
          email: values.email,
          orderId: values.orderId,
          typo,
        };
      } else {
        if (values.email.length < 6 && values.orderId.length < 6) {
          setErrors({
            email: "Please enter email",
            orderId: "Please enter valid OrderId",
          });
          setTouched({
            email: "Please enter email",
            orderId: "Please enter valid OrderId",
          });
          return;
        } else {
          if (values.orderId.length < 6) {
            setErrors({
              orderId: "Please enter valid OrderId",
            });
            setTouched({
              orderId: "Please enter valid OrderId",
            });
            return;
          } else {
            setErrors({
              email: "Please enter valid email",
            });
            setTouched({
              email: "Please enter valid email",
            });
            return;
          }
        }
      }
    }

    console.log(requestObject);
    dispatch(toggleLoading());
    setTracking(null);
    PostAnyApi("/order-tracking/track-order", requestObject)
      .then((response) => {
        dispatch(toggleLoading());
        if (response.status === 200) {
          console.log(response.data);
          setError(null);
          setTracking(response.data);
        }
      })
      .catch((err) => {
        dispatch(toggleLoading());
        if (err.response.status === 404) {
          toast.error(err.response.data.message);
          setError(err.response.data.message);
        }
      });
  };

  console.log(values);

  return (
    <>
      <div className="container track-order-container">
        <div className="track-order-title">
          <h1>TRACK YOUR ORDER</h1>
        </div>
        {isMobile ? (
          <div className="track-order-mobile-form-container">
            <div className="track-order-tabs">
              <button
                className={`track-order-tab ${
                  activeTab === "order-number" ? "active" : ""
                }`}
                onClick={() => handleTabClick("order-number")}
              >
                Order Number
              </button>
              <button
                className={`track-order-tab ${
                  activeTab === "tracking-number" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tracking-number")}
              >
                Tracking Number
              </button>
            </div>

            {activeTab === "order-number" && (
              <div className="order-number-div">
                <div className="track-order-option1">
                  <input
                    className={
                      errors.orderId
                        ? "text-field-input border-red-error"
                        : "text-field-input"
                    }
                    titleLabel="Order Number"
                    value={values.orderId}
                    name="orderId"
                    Placeholder="Enter order number"
                    type="text"
                    onChange={handleChange}
                  />
                  <div className="form-error">
                    {errors.orderId && errors.orderId}
                  </div>
                  <input
                    className={
                      errors.email
                        ? "text-field-input border-red-error"
                        : "text-field-input"
                    }
                    titleLabel="Email"
                    value={values.email}
                    name="email"
                    Placeholder="Enter email"
                    type="email"
                    onChange={handleChange}
                  />
                  <div className="form-error">
                    {errors.email && errors.email}
                  </div>
                  {error && <div className="form-error">{error}</div>}
                  <a
                    onClick={() => handleSubmit(false)}
                    type="submit"
                    className="btn"
                  >
                    Track
                  </a>
                </div>
              </div>
            )}
            {activeTab === "tracking-number" && (
              <div className="tracking-number-div">
                <div className="track-order-option2">
                  <input
                    className={
                      errors.trackingId
                        ? "text-field-input border-red-error"
                        : "text-field-input"
                    }
                    titleLabel="Tracking Number"
                    value={values.trackingId}
                    name="trackingId"
                    Placeholder="Enter tracking number"
                    type="text"
                    onChange={handleChange}
                  />

                  <div className="form-error">
                    {errors.trackingId && errors.trackingId}
                  </div>
                  {error && <div className="form-error-all">{error}</div>}
                  <a
                    onClick={() => handleSubmit()}
                    type="submit"
                    className="btn"
                  >
                    Track
                  </a>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="track-order-form-container">
            <form onSubmit={handleSubmit} className="track-order-form">
              <div className="track-order-option1">
                <input
                  className={
                    errors.orderId
                      ? "text-field-input border-red-error"
                      : "text-field-input"
                  }
                  titleLabel="Order Number"
                  value={values.orderId}
                  name="orderId"
                  Placeholder="Enter order number"
                  type="text"
                  onChange={handleChange}
                />
                <div className="form-error">
                  {errors.orderId && errors.orderId}
                </div>
                <input
                  className={
                    errors.email
                      ? "text-field-input border-red-error"
                      : "text-field-input"
                  }
                  titleLabel="Email"
                  value={values.email}
                  name="email"
                  Placeholder="Enter email"
                  type="email"
                  onChange={handleChange}
                />
                <div className="form-error">{errors.email && errors.email}</div>
                <a
                  onClick={() => handleSubmit(false)}
                  type="submit"
                  className="btn"
                >
                  Track
                </a>
              </div>
              <div className="track-order-divider">
                <div class="track-order_line_center">
                  <div class="track-order_line"></div>
                  <div class="track-order_word">OR</div>
                  <div class="track-order_line"></div>
                </div>
              </div>
              <div className="track-order-option2">
                <input
                  className={
                    errors.trackingId
                      ? "text-field-input border-red-error"
                      : "text-field-input"
                  }
                  titleLabel="Tracking Number"
                  value={values.trackingId}
                  name="trackingId"
                  Placeholder="Enter tracking number"
                  type="text"
                  onChange={handleChange}
                />
                <div className="form-error">
                  {errors.trackingId && errors.trackingId}
                </div>
                <a onClick={() => handleSubmit()} type="submit" className="btn">
                  Track
                </a>
              </div>
            </form>
            {error && <div className="form-error-all">{error}</div>}
          </div>
        )}
        {trackingDetails && (
          <TrackingDetails data={trackingDetails} isMobile={isMobile} />
        )}
      </div>
    </>
  );
};

export default TrackOrders;

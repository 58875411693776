import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../Headings";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { getWithoutAuth } from "../../../../api/api";
import parse from "html-react-parser";
import "../Policy.css"
const CancellationsAndReturns = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getWithoutAuth("policy/get-all").then((res) => {
      const contentState = convertFromRaw(
        JSON.parse(
          res.data.load.cancellation[res.data.load.cancellation.length - 1]
            .content
        )
      );
      const html = stateToHTML(contentState);
      setData(html);
    });
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div className="container">
      {/* <Breadcrumbs
        links={[
          { links_id: 1, redirectTo: "/profile", linkName: "My Profile" },
        ]}
      /> */}
      <div className="policy-container ">
        <div className="policy-titles policy-title-center">
          <h1>Cancellation And Return Policy</h1>
        </div>
        <div className="policy-paragraph">
          {parse(data)}
          
        </div>
      </div>
    </div>
  );
};

export default CancellationsAndReturns;

import React, { useState, useEffect } from "react";
import "./WishlistProductCard.css";
import { Link } from "react-router-dom";
import { postAnyAuth } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import GlobalModal from "../GlobalModal/GlobalModal";
import { toggleLoading } from "../../store";

const WishlistProductCard = ({
  product_images,
  productTitle,
  currencyCode,
  finalPrice,
  originalPrice,
  size,
  trigger,
  id,
  selected,
  handleSelect,
  setSizeSelected,
  handleMoveToCart,
  sizeVal,
  allData
}) => {
  const [hovered, setHovered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const token = useSelector((state) => state.token);
  const [isGlobalModaOpen, setGlobalModaOpen] = useState(false);
  
  const [callBackData, setCallback] = useState();
  const toggleGlobalModaDrawer = (callback) => {
    setGlobalModaOpen(!isGlobalModaOpen);
    
    if (isGlobalModaOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  const dispatch = useDispatch()

  const handleRemoveWishlist = () => {
    const removeFromWishlist = () => {
      if (token) {
        dispatch(toggleLoading())
        postAnyAuth("wishlist/remove", { productIds: [id] }, token)
          .then((res) => {
            console.log(res.data);
            let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

            localWishlist = localWishlist.filter((items) => items.id !== id);

            console.log(localWishlist);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));
            trigger();
            dispatch(toggleLoading())
          })
          .catch((err) => {
            console.log(err);
            trigger();
            dispatch(toggleLoading())
          });
      } else {
        let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

        localWishlist = localWishlist.filter((items) => items.id !== id);

        console.log(localWishlist);

        localStorage.setItem("wishlist", JSON.stringify(localWishlist));
        trigger();
      }
    };
    setCallback({toggle : removeFromWishlist, title : "Are you sure you want to remove from wishlist?"})
    toggleGlobalModaDrawer();
  };
  useEffect(() => {
    let intervalId;
    
    if (hovered) {
      // Start from the second image
      setCurrentIndex(1);
      
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % product_images.length);
      }, 2000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [hovered, product_images]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setCurrentIndex(0);
  };


  return (
    <div className="product-wishlist-card">
      <div className="product_link">
        <GlobalModal
          callback={callBackData}
          isGlobalModalOpen={isGlobalModaOpen}
          toggleGlobalModal={toggleGlobalModaDrawer}
        />
        <div
          className="product_images"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="overlay-items">
            <div className="checkbox">
              <input
                checked={selected}
                onChange={(e) => {
                  handleSelect(e.target.checked, id);
                }}
                type="checkbox"
                id=""
                name=""
                value=""
              ></input>
            </div>
            <div className="remove-btn-item">
              <button onClick={handleRemoveWishlist} className="remove-btn">
                <i className="ri-close-line"></i>
              </button>
            </div>
          </div>
          <Link to="/product-details">
            <img
              src={
                "https://amarnathckr.online/api/v1/upload/" +
                product_images[currentIndex]
              }
              alt=""
              className={`product-image ${hovered ? "transition" : ""}`}
            />
          </Link>
        </div>
        <Link to="/product-details" className="product-text-info">
          <h2>{`${currencyCode} ${productTitle}`}</h2>
          <div className="product_price">
            <span className="product-final-price">
              {`${currencyCode} ${finalPrice}`}
              <span className="product-original-price">
                <span className="original-price">
                  {`${currencyCode} ${originalPrice}`}
                </span>
              </span>
            </span>
          </div>
        </Link>
        <select
          name="sizes"
          id=""
          onChange={(e) => setSizeSelected({ e, id })}
          className="size-select-option"
        >
          {size &&
            size.map((items) => {
              return <option value={items}>{items}</option>;
            })}
        </select>
        <div className="wishlist-status">
          <button
            className="btn move-to-cart-btn"
            onClick={() => handleMoveToCart({ id, size: sizeVal,...allData })}
          >
            Move to cart
          </button>
          <p>Added on 16 January 2024</p>
        </div>
      </div>
    </div>
  );
};

export default WishlistProductCard;

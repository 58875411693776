import React, { useEffect, useState } from "react";
import "./EditProfile.css";

import { Form, Formik } from "formik";

import { useNavigate } from "react-router-dom";
import { customerYupSchema } from "../../../utils/validationSchema/customerYupSchema";
import { PostAnyApi, postAnyAuth } from "../../../api/api";
import TextField from "../../Inputs/TextField";
import { useDispatch, useSelector } from "react-redux";
import { subscribeUser, toggleLoading } from "../../../store";

const EditProfile = () => {
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [showOTPField, setShowOTPField] = useState(false);
  const [otp, setOTP] = useState("");
  const [verified, setVerified] = useState(true);
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);

  const userData = useSelector((state) => state.user);

  let initialVal = {
    fname: userData.fname,
    lname: userData.lname,
    email: userData.email,
    otp: "",
  };

  const token = useSelector((state) => state.token);

  console.log(token);

  const handleVerifyEmail = () => {
    const email = document.getElementById("email").value;
    const fname = document.getElementById("fname").value;
    const lname = document.getElementById("lname").value;

    dispatch(toggleLoading(true));

    postAnyAuth("customer/edit-profile", { email, lname, fname }, token)
      .then((res) => {
        console.log(res.data);
        dispatch(toggleLoading(false));
        // Handle successful response, for example:
        setShowOTPField(true); // Show OTP field after successful verification
      })
      .catch((err) => {
        // Handle error
        console.error("Error verifying email:", err);
        setError(err.response.data.message);
        dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    const email = document.getElementById("email");
    const fname = document.getElementById("fname");
    const lname = document.getElementById("lname");
    email.value = userData.email;
    fname.value = userData.fname;
    lname.value = userData.lname;
    setError(null);
  }, [trigger, userData]);

  const handleCancel = () => {
    setEditMode(null);
    setShowOTPField(false);
    setVerified(null);
  };

  const dispatch = useDispatch();

  return (
    <div className="edit-profile-form">
      <div className="edit-profile_form_container container">
        <div className="edit-profile-form-title">
          <h1>Edit Profile</h1>
        </div>
        {editMode !== "password" && (
          <Formik
            initialValues={initialVal}
            validationSchema={customerYupSchema.editProfileSchema}
            onSubmit={(values) => {
              // Handle form submission (e.g., send data to server)
              setError(null);
              console.log("Form submitted with values:", values);
              if (values.otp.length < 6) {
                setError("Please enter valid OTP");
                return;
              }
              dispatch(toggleLoading(true));
              postAnyAuth("/customer/verify-edit", values, token)
                .then((res) => {
                  dispatch(toggleLoading(false));
                  res.status === 200 && dispatch(subscribeUser(res.data.user));
                  localStorage.setItem("user", JSON.stringify(res.data.user));
                  handleCancel();
                })
                .catch((err) => {
                  console.log(err);
                  dispatch(toggleLoading(false));
                  if (err.response.status === 401) {
                    setShowOTPField(false);
                  }
                  console.log(err.response.data.message);
                  setError(err.response.data.message);
                });
            }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <TextField
                  titleLabel="First Name"
                  value={values.fname}
                  disabled={editMode === "info" ? false : true}
                  name="fname"
                  Placeholder="Enter first name here"
                  type="text"
                  touched={touched.fname}
                  error={errors.fname}
                />

                <TextField
                  titleLabel="Last Name"
                  name="lname"
                  value={values.lname}
                  disabled={editMode === "info" ? false : true}
                  Placeholder="Enter last name here"
                  type="text"
                  error={errors.lname}
                  touched={touched.lname}
                />

                <div className="form-group">
                  <div className="form-group-elements">
                    <TextField
                      titleLabel="Email"
                      name="email"
                      value={values.email}
                      disabled={editMode === "info" ? false : true}
                      Placeholder="Enter email here"
                      type="email"
                      error={errors?.email}
                      touched={touched?.email}
                      renderProps={
                        !showOTPField &&
                        touched?.email && (
                          <button
                            type="button"
                            disabled={showOTPField ? true : false}
                            onClick={handleVerifyEmail}
                            className={`${
                              showOTPField ? "disabled-btn" : ""
                            } verify-email-btn`}
                          >
                            Verify
                          </button>
                        )
                      }
                    />
                  </div>
                </div>

                {showOTPField && (
                  <TextField
                    titleLabel="OTP"
                    name="otp"
                    value={values.cPassword}
                    Placeholder="Enter OTP here"
                    type="text"
                    error={errors.otp}
                  />
                )}

                {error && <div className="field-error-text">{error}</div>}

                {editMode === "info" && (
                  <button
                    disabled={!showOTPField ? true : false}
                    type="submit"
                    className={`${
                      !showOTPField ? "disabled-btn" : ""
                    } btn sign-in-btn`}
                  >
                    Save
                  </button>
                )}
                <div className="edit-profile-btn-div">
                
                  {editMode !== "info" && (
                    <button
                      onClick={() => setEditMode("info")}
                      className="btn sign-in-btn"
                    >
                      Edit
                    </button>
                  )}
                  {editMode !== "info" && editMode !== "password" && (
                    <button
                      onClick={() => setEditMode("password")}
                      className="btn sign-in-btn"
                    >
                      Change Password
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
        {editMode === "password" && (
          <Formik
            initialValues={{
              password: "",
              newPassword: "",
              cPassword: "",
            }}
            validationSchema={customerYupSchema.changePasswordSchema} // You can use the same schema for password change
            onSubmit={(values) => {
              // Handle form submission (e.g., send data to server)
              setError(null);
              console.log("Form submitted with values:", values);
              dispatch(toggleLoading())
              postAnyAuth("/customer/change-password", values, token)
                .then((res) => {
                  dispatch(toggleLoading())
                  res.status === 200 && handleCancel();
                })
                .catch((err) => {
                  console.log(err.response.data.message);
                  setError(err.response.data.message);
                  dispatch(toggleLoading())
                });
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <TextField
                  titleLabel="Current Password"
                  name="password"
                  value={values.password}
                  Placeholder="Enter current password here"
                  type="password"
                  disabled={editMode === "password" ? false : true}
                  error={errors.password}
                  touched={touched.password}
                />

                <TextField
                  titleLabel="New Password"
                  name="newPassword"
                  value={values.newPassword}
                  disabled={editMode === "password" ? false : true}
                  Placeholder="Enter new password here"
                  type="password"
                  error={errors.newPassword}
                  touched={touched.newPassword}
                />
                <TextField
                  titleLabel="Confirm Password"
                  name="cPassword"
                  value={values.cPassword}
                  disabled={editMode === "password" ? false : true}
                  Placeholder="Confirm new password here"
                  type="password"
                  error={errors.cPassword}
                  touched={touched.cPassword}
                />

                {error && <div className="field-error-text">{error}</div>}

                {editMode === "password" && (
                  <button type="submit" className="btn sign-in-btn">
                    Change Password
                  </button>
                )}
              </Form>
            )}
          </Formik>
        )}
        {editMode === "password" || editMode === "info" ? (
          <div className="edit-profile-cancel-btn">
            <button  type="button" onClick={handleCancel}>
            Cancel
          </button>
          </div>


        ) : null}
      </div>
    </div>
  );
};

export default EditProfile;

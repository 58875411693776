import React from "react";
import "./Breadcrumbs.css";
import { Link, useLocation } from "react-router-dom";

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const Breadcrumbs = ({ links,overRide }) => {
  let currentPath = window.location.pathname;
  const location = useLocation();
  currentPath = currentPath.slice(1).split("/");
  console.log(currentPath[1]);

  links = [
    { links_id: 1, redirectTo: "/", linkName: "Home" },
    {
      links_id: 2,
      redirectTo:
        location.state && location.state.id
          ? "/product-details/" + currentPath[1]
          : "/products/" + currentPath[1],
      linkName: currentPath[1],
      state:
        location.state && location.state?.id
          ? location.state.id
          : location.state,
    },
  ];
  return (
    <div className="inner_page_breadcrumbs container">
      {links.map((mapItems,index) => (
       <div key={mapItems.links_id}>
          <Link
            key={mapItems.links_id}
            to={mapItems.redirectTo}
            state={mapItems.state}
          >
            {overRide && index === 1 ? "Contact Us" :capitalize(mapItems.linkName.split("-").join(" "))}
          </Link>
          {index !== links.length - 1 && <span>/</span>}
       </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;

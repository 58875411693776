import React from "react";
import "./OtherBanners.css";
import { Link } from "react-router-dom";

const OtherBanners = ({ redirectTo, bannerImage, bannerImageMobile }) => {
  return (
    <Link to={redirectTo && redirectTo} className="other_banner">
      <img
        src={bannerImage}
        alt={bannerImage}
        className="desktop_banner_slider"
      />
      <img
        src={bannerImageMobile ? bannerImageMobile : bannerImage }
        alt={bannerImageMobile ? bannerImageMobile : bannerImage }
        className="mobile_banner_slider"
      />
    </Link>
  );
};

export default OtherBanners;

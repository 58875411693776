import React, { useState } from "react";
import "./LoginForm.css";
import TextField from "../../../Inputs/TextField";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { customerYupSchema } from "../../../../utils/validationSchema/customerYupSchema";
import { PostAnyApi } from "../../../../api/api";
import { useDispatch } from "react-redux";
import { subscribeToken, subscribeUser, toggleLoading } from "../../../../store";

const LoginForm = () => {
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="login-form">
      <div className="login_form_container container">
        <div className="login-form-title">
          <h1>Login</h1>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={customerYupSchema.login}
          onSubmit={(values) => {
            // Handle form submission (e.g., send data to server)
            setError(null);
            console.log("Form submitted with values:", values);
            dispatch(toggleLoading())
            PostAnyApi("/customer/login", values)
              .then((res) => {
                dispatch(toggleLoading())
                if (res.status === 200) {
                  console.log(res.data);
                  localStorage.setItem("token", res.data.token);
                  localStorage.setItem(
                    "user",
                    JSON.stringify(res.data.userData)
                  );
                  dispatch(subscribeToken(res.data.token));
                  dispatch(subscribeUser(res.data.userData));
                  navigate("/");
                }
                
              })
              .catch((err) => {
                console.log(err.response);
                setError(err.response.data.error);
                dispatch(toggleLoading())
              });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <TextField
                titleLabel="Email"
                name="email"
                Placeholder="Enter email here"
                type="email"
                error={errors.email}
              />

              <TextField
                titleLabel="Password"
                name="password"
                Placeholder="Enter password here"
                type="password"
                error={errors.password}
              />

              {error && <div className="field-error-text">{error}</div>}

              <button type="submit" className="btn sign-in-btn">
                Login
              </button>
            </Form>
          )}
        </Formik>
        <div className="create_account_link">
          <p>
            <Link to="/signup" href="">
              Create Account
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

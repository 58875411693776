import React, { useState } from "react";
import TextField from "../../../Inputs/TextField";
import { Link } from "react-router-dom";
import "./ResetPassword.css";

const ResetPassword = () => {
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const { email, password } = inputValue;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add your login logic here
  };
  return (
    <div className="reset_password-form">
      <div className="reset_password_form_container container">
        <div className="reset_password-form-title">
          <h1>RESET YOUR PASSWORD</h1>
          <p>We will send you an email to reset your password.</p>
        </div>
        <form
          action=""
          className="reset_password-form-contents"
          onSubmit={handleFormSubmit}
        >
          <TextField
            titleLabel="Email"
            value={email}
            name="email"
            placeholder="Enter existing email"
            type="email"
            onChange={handleChange}
          />
          <button className="btn sign-in-btn">Submit</button>
        </form>
        <div className="create_account_link">
          <p>
            <Link to="/login" href="">
              Cancel
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

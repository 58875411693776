import React from "react";
import "./FilterByFeatured.css";

const FilterByFeatured = ({ totalProductsNumber,value,sortHandler }) => {
  return (
    <div className="filter_by_feature_on_list">
      <div className="total_number_of_products_title">
        {totalProductsNumber} products
      </div>
      <div className="products_by_feature_selection">
        <select value={value} onChange={sortHandler} name="Featured" id="Featured">
          <option value="title_az">Alphabetically, A-Z</option>
          <option value="title_za">Alphabetically, Z-A</option>
          <option value="mrp_lh">Price, low to high</option>
          <option value="mrp_hl">Price, high to low</option>
          <option value="date_on">Date, old to new</option>
          <option value="date_no">Date, new to old</option>
        </select>
      </div>
    </div>
  );
};

export default FilterByFeatured;

import React, { useState } from "react";
import "./OrderRating.css";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { postAnyAuth } from "../../api/api";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading } from "../../store";
import SelectField from "../Select/SelectField";
import RatingComponent from "../Rating/Rating";
import Rating from "../Rating/Rating";

function OrderRating({ order, close, trigger }) {
  const [rating, setRating] = useState(0);
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(null);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      height: "fit-content",
      width: "40%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
  };

  console.log(order);

  const handleClose = () => {
    console.log("close");
    close();
    trigger();
  };

  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(rating, answer);
    if (answer.length < 20) {
      return setError("Minimum 20 characters required");
    } else if (answer.length > 500) {
      return setError("Maximum 500 characters allowed");
    }
    if (rating === 0) {
      return setError("Please rate the product");
    }
    dispatch(toggleLoading());
    postAnyAuth(
      "order/order-rating",
      {
        subOrderId: order.subOrderId,
        orderId: order._id,
        rating,
        review: answer,
      },
      token
    )
      .then((res) => {
        dispatch(toggleLoading());
        toast.success("Order rated successfully");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        dispatch(toggleLoading());
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="product-quickview">
      {/* Overlay */}
      <div className={`overlay ${order ? "show" : ""}`} onClick={close}></div>

      {/* Sliding Drawer */}
      <div className={`rating-sliding-drawer-container ${order ? "open" : ""}`}>
        <div className="rating-drawer-content">
          <div className="rating-drawer__inner">
            <div className="rating-drawer__scrollable">
              {/* <div className="rating-drawer-header order-rating-header">
                <h2>Order Cancellation</h2>
                <i className="ri-close-fill" onClick={close}></i>
              </div> */}
              <div className="header-container">
                <h2>Rate Your Order</h2>
                <button className="close-button" onClick={handleClose}>
                  X
                </button>
              </div>
              <div className="rating__items">
                {" "}
                <form onSubmit={handleSubmit}>
                  <div className="main-container">
                    <div className="order_rating_items">
                      <div key={order.subOrderId} className="orders_item">
                        <div className="rating_item_info">
                          <img
                            className="image-cancel"
                            src={
                              "https://amarnathckr.online/api/v1/upload/" +
                              order.productId.images[0]
                            }
                            alt={order.productId.images[0]}
                          />
                          <div className="rating-text-infos">
                            <h2>{order.productId.title}</h2>
                            <p>Size: {order.size}</p>
                            <p>Qty : {order.quantity}</p>
                            <p>Size: ₹{order.productValue}</p>

                            <p>Order ID: {order.subOrderId}</p>
                            {/* <p>Payment Method : {order.paymentMethod}</p> */}
                          </div>
                        </div>
                      </div>

                      <div className="question-label">
                        <label>Rate this Product:</label>
                        <Rating
                          stars={rating}
                          onRated={(data) => setRating(data)}
                        />
                      </div>
                      <label>Review this product:</label>
                      <textarea
                        onChange={(e) => {
                          setError(null);
                          setAnswer(e.target.value);
                        }}
                        value={answer}
                        className="text-area-cancel"
                        placeholder="Write a review here..."
                      />
                      {error && <p className="error-text-cancel">{error}</p>}
                      <div className="btn-container">
                        <button className="btn cancel-btn">
                          Submit Rating
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderRating;

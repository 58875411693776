import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";

import "./App.css";
import {
  Header,
  Footer,
  CartDrawer,
  NavigationBar,
  MobileFilterButtons,
  MobileFilterDrawer,
} from "./components";
import {
  Home,
  LoginForm,
  SignupForm,
  ResetPassword,
  OurStores,
  TrackOrders,
  ProductList,
  ProductDetails,
  WishList,
  MyProfile,
  MyAddresses,
} from "./components/Pages";

import { Provider, useDispatch, useSelector } from "react-redux";
import store, {
  subscribeToken,
  subscribeUser,
  subscribesort,
  toggleDrawer,
  toggleLoading,
  toggleTrigger,
} from "./store/index.js";
import VeifyOTP from "./components/Pages/AuthPages/VerifyOTP/VerifyOTP";
import { getWithoutAuth, postAnyAuth } from "./api/api.js";
import ScrollToTopOnMount from "./components/utils/ScrollToTop.jsx";

import toast, { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";
import Checkout from "./components/Pages/Checkout/Checkout.jsx";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderHistory from "./components/Pages/OrderHistory/OrderHistory.jsx";
import EditProfile from "./components/Pages/EditProfile/EditProfile.jsx";

import FAQPage from "./components/Pages/FAQPage/FAQPage.jsx";
import TestingPage from "./components/Pages/TestingPage/TestingPage.jsx";
import NotFound from "./components/Pages/NotFound/NotFound.jsx";
import ContactUs from "./components/Pages/ContactUs/ContactUs.jsx";
import OrderSuccess from "./components/Pages/OrderSuccess/OrderSuccess.jsx";
import Loader from "./components/Loader/Loader.jsx";
import ErrorBoundary from "./utils/ErrorBoundary/ErrorBoundary.jsx";
import PrivacyPolicy from "./components/Pages/Policies/PrivacyPolicy/PrivacyPolicy.jsx";
import ShippingPolicy from "./components/Pages/Policies/ShippingPolicy/ShippingPolicy.jsx";
import TermsAndCondition from "./components/Pages/Policies/TermsAndConditions/TermsAndConditions.jsx";
import CancellationsAndReturns from "./components/Pages/Policies/CancellationAndReturns/CancellationAndReturns.jsx";
import CookieConsent from "react-cookie-consent";


function App() {
  const currentPath = window.location.pathname;
  console.log(currentPath);
  const [menuActive, setMenuActive] = useState(false);
  const sort = useSelector((state) => state.sort);
  const setSort = (data) => {
    dispatch(subscribesort(data));
  };

  const [isLogin, setIsLogin] = useState(true);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const isCartOpen = useSelector((state) => state.cartDrawer);

  const [homeData, setHomeData] = useState();
  const globalTrigger = useSelector((state) => state.globalTrigger);

  useEffect(() => {
    dispatch(toggleLoading());
    getWithoutAuth("banner")
      .then((res) => {
        console.log(res.data.trendingCollection);
        setHomeData({
          homeData: res.data.load,
          otherBanner: res.data.otherBanner,
          categoryBanner: res.data.categoryBanners,
          newArrivals: res.data.newArrivals,
          sales: res.data.sales,
          faqs: res.data.faqs,
          category: res.data.categories,
          trendingCollection: res.data.trendingCollection,
          videos: res.data.videos,
          ratings: res.data.ratings,
          occasionData: res.data.occasionData,
        });
        dispatch(toggleLoading());
      })
      .catch((err) => {
        dispatch(toggleLoading());
      });
  }, []);

  console.log(isCartOpen);

  const setIsCartOpen = (data) => {
    console.log(data);
    dispatch(toggleDrawer());
  };

  const toggleCartDrawer = () => {
    setIsCartOpen(!isCartOpen);
    console.log(isCartOpen);
    if (isCartOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  if (loading) {
    document.documentElement.style.overflow = "hidden";
  } else {
    document.documentElement.style.overflow = "unset";
  }

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilterDrawer = () => {
    setIsFilterOpen(!isFilterOpen);
    if (isFilterOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  useEffect(() => {
    if (!token) {
      const localToken = localStorage.getItem("token");
      const localUser = localStorage.getItem("user");
      if (localToken) {
        dispatch(subscribeToken(localToken));
        dispatch(subscribeUser(JSON.parse(localUser)));
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } else {
      setIsLogin(true);
    }
  }, [token]);

  useEffect(() => {
    const wishlistLocal = JSON.parse(localStorage.getItem("wishlist"));
    if (token) {
      console.log("triggered wish apicall");
      dispatch(toggleLoading());
      postAnyAuth(
        "wishlist",
        {
          currentList:
            wishlistLocal && wishlistLocal.length > 0 ? wishlistLocal : null,
        },
        token
      )
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("wishlist", JSON.stringify(res.data.load));
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    } else {
      localStorage.setItem(
        "wishlist",
        JSON.stringify(wishlistLocal ? wishlistLocal : [])
      );
    }
  }, [token, globalTrigger]);

  useEffect(() => {
    const cartLocal = JSON.parse(localStorage.getItem("cart"));

    console.log("triggered from app cart");

    console.log(cartLocal);
    if (token) {
      dispatch(toggleLoading());
      postAnyAuth(
        "cart",
        {
          currentCart:
            cartLocal && cartLocal.length > 0
              ? cartLocal.map((items) => {
                  return {
                    productId: items.id,
                    size: items.size,
                    quantity: items.quantity,
                    vsku: items.vsku,
                    selected: items.selected,
                  };
                })
              : null,
        },
        token
      )
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("cart", JSON.stringify(res.data.load));
          dispatch(toggleTrigger());
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    } else {
      localStorage.setItem("cart", JSON.stringify(cartLocal ? cartLocal : []));
      dispatch(toggleTrigger());
    }
  }, [token]);

  console.log(isLogin);

  console.log(currentPath.split("/")[1]);

  

  return (
    <HelmetProvider>
      {loading && <Loader loading={loading} />}
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Router>
        <Toaster />
        <ScrollToTopOnMount />
        <Header
          menuActive={menuActive}
          setMenuActive={setMenuActive}
          toggleCartDrawer={toggleCartDrawer}
        />
        <CartDrawer toggleCartDrawer={toggleCartDrawer} />

        <MobileFilterDrawer
          isFilterOpen={isFilterOpen}
          toggleFilterDrawer={toggleFilterDrawer}
          toggleHeader={() => setMenuActive(!menuActive)}
          style={{
            display: currentPath === "/products" ? "block" : "none",
          }}
        />
        <div className="main-content">
          <MobileFilterButtons
            value={sort}
            sortHandler={(e) => setSort(e.target.value)}
            toggleFilterDrawer={toggleFilterDrawer}
            style={{
              display: currentPath === "/products" ? "block" : "none",
            }}
          />
          <Suspense
            fallback={
              <NotFound
                title={"Network Error"}
                message="Please try after some time."
                categoryBanner={homeData?.categoryBanner}
              />
            }
          >
            <ErrorBoundary
              fallback={
                <NotFound
                  title={"Network Error"}
                  message="Please try after some time."
                  categoryBanner={homeData?.categoryBanner}
                />
              }
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      sales={homeData?.sales}
                      newArrivals={homeData?.newArrivals}
                      categoryBanner={homeData?.categoryBanner}
                      homeData={homeData?.homeData}
                      otherBanner={homeData?.otherBanner}
                      faqs={homeData?.faqs}
                      category={homeData?.category}
                      trendingCollection={homeData?.trendingCollection}
                      videos={homeData?.videos}
                      ratings={homeData?.ratings}
                      occasionData={homeData?.occasionData}
                    />
                  }
                />
                <Route
                  path="/login"
                  element={!isLogin ? <LoginForm /> : <Navigate to={"/"} />}
                />
                <Route
                  path="/signup"
                  element={!isLogin ? <SignupForm /> : <Navigate to={"/"} />}
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="account/wishlist" element={<WishList />} />
                <Route path="/our-stores" element={<OurStores />} />
                <Route path="/track-orders" element={<TrackOrders />} />
                <Route path="/products/:id" element={<ProductList />} />
                <Route path="/verify-otp" element={<VeifyOTP />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/shipping-policy" element={<ShippingPolicy />} />
                <Route
                  path="/terms-of-service"
                  element={<TermsAndCondition />}
                />
                <Route
                  path="/cancellation-and-return"
                  element={<CancellationsAndReturns />}
                />

                <Route
                  path="/product-details/:id"
                  element={<ProductDetails />}
                />
                <Route
                  path="/account/profile"
                  element={isLogin ? <MyProfile /> : <Navigate to={"/login"} />}
                />
                <Route
                  path="/account/edit-profile"
                  element={
                    isLogin ? <EditProfile /> : <Navigate to={"/login"} />
                  }
                />
                <Route
                  path="/checkout"
                  element={isLogin ? <Checkout /> : <Navigate to={"/login"} />}
                />
                <Route
                  path="/account/addresses"
                  element={
                    isLogin ? <MyAddresses /> : <Navigate to={"/login"} />
                  }
                />
                <Route
                  path="/account/order-history"
                  element={
                    isLogin ? <OrderHistory /> : <Navigate to={"/login"} />
                  }
                />
                <Route
                  path="/testing/product-details"
                  element={<TestingPage />}
                />
                <Route
                  path="/faq"
                  element={<FAQPage />}
                />
                <Route
                  path="/contact-us"
                  element={
                    <ContactUs categoryBanner={homeData?.categoryBanner} />
                  }
                />
                <Route
                  path="account/order-sucess"
                  element={
                    isLogin ? <OrderSuccess /> : <Navigate to={"/login"} />
                  }
                />
                <Route
                  path="/*"
                  element={
                    <NotFound
                      title={"404 PAGE NOT FOUND"}
                      message="The page you were looking for does not exist."
                      categoryBanner={homeData?.categoryBanner}
                    />
                  }
                />
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </div>
        <NavigationBar
          toggleHeader={() => setMenuActive(!menuActive)}
          toggleFilterDrawer={toggleFilterDrawer}
        />
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;

import React from "react";
import "./OurStores.css";

const OurStores = () => {
  throw new Error('Test error in OurStores component');
  
  return <div>OurStores</div>;
};

export default OurStores;

import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../Headings";
import "./OrderHistory.css";
import { product_img1 } from "../../../assets/images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAnyApi } from "../../../api/api";
import OrderCancellation from "../../OrderCancellation/OrderCancellation";
import { toggleLoading } from "../../../store";
import OrderRating from "../../OrderRating/OrderRating";
import Rating from "../../Rating/Rating";
import OrderReturn from "../../OrderReturn/OrderReturn";

const OrderHistory = () => {
  const [orders, setorders] = useState();
  const [returnItem, setReturnItem] = useState(null);
  const [trigger, setTrigger] = useState();
  const [cancelItem, setCancelItem] = useState(null);
  const [rating, setRating] = useState(null);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(toggleLoading());
      getAnyApi("order", token)
        .then((res) => {

          setorders(res.data.load);
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    }
  }, [token, trigger]);


  const handleCancel = (data) => {
    console.log("triggered cancel");
    if (cancelItem) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
    setCancelItem(data);
  };

  const handleReturn = (data) => {
    console.log("triggered return");
    if (returnItem) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
    setReturnItem(data);
  };

  const hadleRating = (data) => {
    console.log("triggered rating interface");
    if (rating) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
    setRating(data);
  };

  const isReturnableOrExchangeable = (order) => {

    const deliveryDate = new Date(order.deliveryData.deliveredDate);
    const currentDate = new Date();

    // Convert milliseconds to days
    const differenceInDays = (currentDate - deliveryDate) / (1000 * 3600 * 24);

  

    // Return true if difference is less than or equal to 7 days, false otherwise
    return differenceInDays <= 7;
  };

  return (
    <div className="order_history_container container">
      <Breadcrumbs
        links={[
          { links_id: 1, redirectTo: "/profile", linkName: "Order History" },
        ]}
      />
      {returnItem && (
        <OrderReturn
          trigger={() => setTrigger(!trigger)}
          close={() => handleReturn(null)}
          order={returnItem}
        />
      )}
      {cancelItem && (
        <OrderCancellation
          trigger={() => setTrigger(!trigger)}
          close={() => handleCancel(null)}
          order={cancelItem}
        />
      )}
      {rating && (
        <OrderRating
          trigger={() => setTrigger(!trigger)}
          close={() => hadleRating(null)}
          order={rating}
        />
      )}
      <div className="order_history-title">
        <h2>Orders History</h2>
      </div>
      <div className="order_history_items">
        {orders && orders.length > 0 ? (
          <>
            {orders.map((order) => {
             
              return (
                <div key={order.subOrderId} className="orders_item">
                  <div className="orders_item_info">
                    <img
                      src={
                        "https://amarnathckr.online/api/v1/upload/" +
                        order.productId.images[0]
                      }
                      alt={order.productId.images[0]}
                    />
                    <div className="text-infos">
                      <h2>{order.productId.title}</h2>
                      <p>Size: {order.size}</p>
                      <p>Order ID: {order.subOrderId}</p>
                    </div>
                  </div>
                  <div className="orders_item_status">
                    <div className="delivery-status">
                      <div className="dot-color-warning"></div>
                      <p>
                        {order.status === "falied"
                          ? "order failed"
                          : order.isReturnInitiated
                          ? "Return inititated"
                          : order.shipped}
                      </p>
                    </div>
                    {order.shipped === "pending" ||
                    order.shipped === "cancelled" ||
                    order.shipped === "Delivered" ? null : (
                      <>
                        <p>
                          Tracking ID: {order.shippingDetails.reference_number}
                        </p>
                        <div className="delivery-tracking">
                          <Link
                            className="track-order-btn"
                            to="/track-orders"
                            state={order.shippingDetails.reference_number}
                          >
                            <i className="ri-map-pin-line"></i> Track Your Order
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                  {order.shipped === "cancelled" ? null : (
                    <div className="orders_item_actions">
                      {order.shipped === "pending" ||
                        (order.shipped === "ready_to_shipped" ? null : (
                          <>
                            {order.shipped === "Delivered" && isReturnableOrExchangeable(order) && (
                              <>
                                {!order.isReturnInitiated && (
                                  <>
                                    <a
                                      href="/#"
                                      className="orders_item_actions-btn"
                                    >
                                      <i className="ri-arrow-left-right-line"></i>{" "}
                                      Exchange
                                    </a>
                                    <a
                                      onClick={() => {
                                        handleReturn(order);
                                      }}
                                      className="orders_item_actions-btn"
                                    >
                                      <i className="ri-arrow-go-back-line"></i>{" "}
                                      Return
                                    </a>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ))}
                      {order.shipped !== "Delivered" && (
                        <a
                          onClick={() => {
                            handleCancel(order);
                          }}
                          className="orders_item_actions-btn"
                        >
                          <i className="ri-close-fill"></i> Cancel Order
                        </a>
                      )}
                      {order.shipped === "Delivered" && order.rating.rating ? (
                        <div className="rating-container">
                          {" "}
                          <Rating stars={order.rating.rating} disabled={true} />
                        </div>
                      ) : (
                        order.shipped === "Delivered" && (
                          <a
                            onClick={() => {
                              hadleRating(order);
                            }}
                            className="orders_item_actions-btn"
                          >
                            <i className="ri-star-fill"></i> Review
                          </a>
                        )
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          "No orders found"
        )}
      </div>
    </div>
  );
};

export default OrderHistory;

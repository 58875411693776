import React from "react";
import "./SelectField.css";

const SelectField = ({ titleLabel, name, id, options, children }) => {
  options = [
    { id: 1, name: "India" },
    { id: 2, name: "USA" },
  ];
  return (
    <div className="select-field">
      <label className="select-field-label">{titleLabel}</label>
      <select name={name} id={id} className="select-field-tag">
        {/* {options.map((options) => (
          <option value={options.name} key={options.id}>
            {options.name}
          </option>
        ))} */}
        {children}
      </select>
    </div>
  );
};

export default SelectField;

import React, { useState } from "react";
import "./AdditionalOffers.css";

const AdditionalOffers = () => {
  // Define initial copied state for each coupon code
  const initialCopiedState = {
    PRE5ss1: false,
    PRE5ss2: false,
    PRE5ss3: false,
    PRE5ss4: false,
  };

  const [isCopied, setIsCopied] = useState(initialCopiedState);

  const copyCouponCode = (code) => {
    const newState = { ...isCopied };

    // Create a temporary textarea element to copy the text to clipboard
    const textarea = document.createElement("textarea");
    textarea.value = code;
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text to clipboard
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);

    // Update state to indicate that the code has been copied for this coupon
    newState[code] = true;
    setIsCopied(newState);
  };

  return (
    <div className="additional_offers-section">
      <h2>Additional Offers</h2>
      <div className="offers-div">
        <div className="offer">
          {/* Offer 1 */}
          <figure>
            <img
              height="35"
              width="24"
              loading="lazy"
              src="https://cdn.shopify.com/s/files/1/2542/7564/files/tag_icon1-01.svg?v=1684735154"
              alt="Offer Icon"
            />
          </figure>
          <div className="right">
            <strong>Flat 5% OFF</strong>
            <p>Get Flat 5% OFF on Prepaid Orders</p>
            <p className="coupon-bar">
              Use Coupon Code : <strong>PRE5ss1</strong>
              <span
                className="copy-code"
                onClick={() => copyCouponCode("PRE5ss1")}
              >
                <strong>
                  <i>{isCopied["PRE5ss1"] ? "Code Copied" : "Copy Code"}</i>
                </strong>
              </span>
            </p>
          </div>
        </div>
        {/* Add more coupon offers similarly */}
        {/* Offer 2 */}
        <div className="offer">
          <figure>
            <img
              height="35"
              width="24"
              loading="lazy"
              src="https://cdn.shopify.com/s/files/1/2542/7564/files/tag_icon1-01.svg?v=1684735154"
              alt="Offer Icon"
            />
          </figure>
          <div className="right">
            <strong>Flat 5% OFF</strong>
            <p>Get Flat 5% OFF on Prepaid Orders</p>
            <p className="coupon-bar">
              Use Coupon Code : <strong>PRE5ss2</strong>
              <span
                className="copy-code"
                onClick={() => copyCouponCode("PRE5ss2")}
              >
                <strong>
                  <i>{isCopied["PRE5ss2"] ? "Code Copied" : "Copy Code"}</i>
                </strong>
              </span>
            </p>
          </div>
        </div>
        {/* Add more coupon offers similarly */}
      </div>
    </div>
  );
};

export default AdditionalOffers;

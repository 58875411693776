import React from "react";
import "./CustomersReview.css";
import SectionHeadings from "../../Headings/SectionHeadings/SectionHeadings";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import {
  product_img1,
  product_img2,
  product_img3,
  product_img4,
} from "../../../assets/images";

const CustomersReview = ({ ratings }) => {
  return (
    <section className="section customers-review-section">
      <SectionHeadings
        title="Customers Review"
        paragraph="Discover our wide range of women’s ethnic wear and western wear fashion"
      />
      <div className="customers-review-container container">
        <div className="customers-review-items">
          <Swiper
            slidesPerView={6}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              340: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            className="mySwiper"
          >
            {ratings &&
              ratings.map((items) => {
                return (
                  <SwiperSlide key={items._id}>
                    <div className="customers-review-item">
                      <a className="customer-review-button">
                        <img
                          src={
                            "https://amarnathckr.online/api/v1/upload/" +
                            items.productId.images[0]
                          }
                          alt={items.productId.images[0]}
                        />
                      </a>
                      <div className="customer-review-info">
                        <div className="review-stars">
                          {[...Array(items.rating).keys()].map((i) => (
                            /* eslint-disable jsx-a11y/alt-text */

                            <i className="ri-star-fill"></i>
                          ))}
                          {[...Array(5 - items.rating).keys()].map((i) => (
                            /* eslint-disable jsx-a11y/alt-text */

                            <i className="ri-star-line"></i>
                          ))}

                          <span>({items.rating})</span>
                        </div>
                        <div className="review-text">
                          <p>{items.review}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}

            
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default CustomersReview;

import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import WishlistProductCardTwo from "./WishlistProductCardTwo/WishlistProductCardTwo";
import WishlistProductCard from "./WishlistProductCard";

const WishlistProductCardContainer = ({
  allData,
  product_images,
  productTitle,
  currencyCode,
  finalPrice,
  originalPrice,
  size,
  view,
  id,
  trigger,
  selected,
  handleSelect,
  setSizeSelected,
  handleMoveToCart,
  sizeVal
}) => {
  return (
    <>
      {view ? (
        <WishlistProductCardTwo
        allData={allData}
          product_images={product_images}
          productTitle={productTitle}
          currencyCode={currencyCode}
          finalPrice={finalPrice}
          originalPrice={originalPrice}
          size={size}
          id={id}
          sizeVal={sizeVal}
          trigger={trigger}
          selected={selected}
          handleSelect={handleSelect}
          setSizeSelected={setSizeSelected}
          handleMoveToCart={handleMoveToCart}
        />
      ) : (
        <div className="wishlist_product">
          <WishlistProductCard
          allData={allData}
            product_images={product_images}
            productTitle={productTitle}
            currencyCode={currencyCode}
            finalPrice={finalPrice}
            originalPrice={originalPrice}
            size={size}
            sizeVal={sizeVal}
            id={id}
            trigger={trigger}
            selected={selected}
            handleSelect={handleSelect}
            setSizeSelected={setSizeSelected}
            handleMoveToCart={handleMoveToCart}
          />
        </div>
      )}
    </>
  );
};

export default WishlistProductCardContainer;

import React from "react";
import "./TextField.css";
import { Field } from "formik";
const TextField = ({
  titleLabel,
  name,
  placeholder,
  type,
  value,
  onChange,
  disabled,
  error,
  touched,
  renderProps,
}) => {
  if (renderProps) {
    return (
      <div className="text-field">
        <label className="text-field-label">{titleLabel}</label>
        <div className="text-field-container">
          <Field
            disabled={disabled}
            className={`text-field-input  ${
              touched && error && "border-red-error"
            }`}
            value={value}
            name={name}
            id={name}
            placeholder={placeholder}
            type={type}
          />
          {renderProps}
        </div>
        {touched && error && <div className="field-error-text">{error}</div>}
      </div>
    );
  } else {
    return (
      <div className="text-field">
        <label className="text-field-label">{titleLabel}</label>
        <Field
          disabled={disabled}
          className={`text-field-input  ${
            touched && error && "border-red-error"
          }`}
          value={value}
          name={name}
          id={name}
          placeholder={placeholder}
          type={type}
        />

        {touched && error && <div className="field-error-text">{error}</div>}
      </div>
    );
  }
};

export default TextField;

import React from "react";
import "./NavigationBar.css";
import {
  CategoriesIcon,
  HeartIcon,
  NavigationLogo,
  ReelsIcon,
  SaleGif,
  StoreIcon,
  UserIcon
} from "../../assets/images";
import { Link } from "react-router-dom";

const NavigationBar = ({ handleMenuTriggerClick,toggleFilterDrawer,toggleHeader }) => {
  return (
    <div className="navigation_bar">
      <ul>
        <li>
          <Link to="/">
            <figure>
              <img src={NavigationLogo} alt={NavigationLogo} />
            </figure>
            Home
          </Link>
        </li>
        <li>
          <Link onClick={toggleHeader}>
            <figure>
              <img src={CategoriesIcon} alt={CategoriesIcon} />
            </figure>
            Categories
          </Link>
        </li>
        <li>
          <Link to="/products/sales">
            <figure>
              <img src={SaleGif} alt={SaleGif} />
            </figure>
            Sale
          </Link>
        </li>
        <li>
          <Link to="/account/wishlist">
            <figure>
              <img src={HeartIcon} alt={HeartIcon} />
            </figure>
            Wishlist
          </Link>
        </li>
        <li>
          <Link to="/account/profile">
            <figure>
            <img src={UserIcon} alt={UserIcon} />
            </figure>
            Profile
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;

import React, { useState } from "react";
import "./Footer.css";
import UpArrow from "../../assets/images/icons/up_arrow.svg";
import DownArrow from "../../assets/images/icons/down_arrow.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const [collapsibleStates, setCollapsibleStates] = useState({
    topCategories: false,
    discover: false,
    support: false,
    policies: false,
    contact: false,
    // Add more states for other grid items as needed
  });

  const toggleCollapsible = (gridItem) => {
    setCollapsibleStates((prevState) => ({
      ...prevState,
      [gridItem]: !prevState[gridItem],
    }));
  };

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="footer-grid">
          <div className="grid__item">
            <p className="footer__title">Top Categories</p>
            <button
              className="footer__title-btn"
              onClick={() => toggleCollapsible("topCategories")}
            >
              Top Categories{" "}
              {collapsibleStates.topCategories ? (
                <img src={UpArrow} alt="down" />
              ) : (
                <img src={DownArrow} alt="down" />
              )}
            </button>
            <div
              className={`collapsible ${
                collapsibleStates.topCategories ? "active" : ""
              }`}
            >
              <ul className="collapsible_list">
                <li className="collapsible_links">
                  <a href="/#">Salwar Suit Sets </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Sarees </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Women Kurta Sets </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Lehengas </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Dresses</a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Juttis </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Jewellery </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Footwear</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid__item">
            <p className="footer__title">Discover</p>
            <button
              className="footer__title-btn"
              onClick={() => toggleCollapsible("discover")}
            >
              Discover{" "}
              {collapsibleStates.discover ? (
                <img src={UpArrow} alt="down" />
              ) : (
                <img src={DownArrow} alt="down" />
              )}
            </button>
            <div
              className={`collapsible ${
                collapsibleStates.discover ? "active" : ""
              }`}
            >
              <ul className="collapsible_list">
                <li className="collapsible_links">
                  <a href="/#">About Us </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Celebrities </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Careers </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Customer Reviews</a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Media </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Business Query</a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Blog</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid__item">
            <p className="footer__title">SUPPORT</p>
            <button
              className="footer__title-btn"
              onClick={() => toggleCollapsible("support")}
            >
              SUPPORT{" "}
              {collapsibleStates.support ? (
                <img src={UpArrow} alt="down" />
              ) : (
                <img src={DownArrow} alt="down" />
              )}
            </button>
            <div
              className={`collapsible ${
                collapsibleStates.support ? "active" : ""
              }`}
            >
              <ul className="collapsible_list">
                <li className="collapsible_links">
                  <Link to={"/track-orders"}>Track Order </Link>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Gift Card </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Exchange Request </a>
                </li>
                <li className="collapsible_links">
                  <Link to="/terms-of-service">Terms of Services </Link>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Sitemap</a>
                </li>
                <li className="collapsible_links">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid__item">
            <p className="footer__title">POLICIES</p>
            <button
              className="footer__title-btn"
              onClick={() => toggleCollapsible("policies")}
            >
              POLICIES{" "}
              {collapsibleStates.policies ? (
                <img src={UpArrow} alt="down" />
              ) : (
                <img src={DownArrow} alt="down" />
              )}
            </button>
            <div
              className={`collapsible ${
                collapsibleStates.policies ? "active" : ""
              }`}
            >
              <ul className="collapsible_list">
                <li className="collapsible_links">
                  <Link to={"/shipping-policy"}>Shipping Policy </Link>
                </li>
                <li className="collapsible_links">
                  <Link to="privacy-policy">Privacy Policy </Link>
                </li>
                <li className="collapsible_links">
                  <Link to="cancellation-and-return">
                    Cancellation, Return <br /> & Exchange
                  </Link>
                </li>
                <li className="collapsible_links">
                  <Link to={"/faq"}>FAQ's</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid__item">
            <p className="footer__title">Contact With Us</p>
            <button
              className="footer__title-btn"
              onClick={() => toggleCollapsible("contact")}
            >
              Contact With Us{" "}
              {collapsibleStates.contact ? (
                <img src={UpArrow} alt="down" />
              ) : (
                <img src={DownArrow} alt="down" />
              )}
            </button>
            <div
              className={`collapsible ${
                collapsibleStates.contact ? "active" : ""
              }`}
            >
              <ul className="collapsible_list">
                <li className="collapsible_links">
                  <a href="/#">Salwar Suit Sets </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Sarees </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Women Kurta Sets </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Lehengas </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Dresses</a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Juttis </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Jewellery </a>
                </li>
                <li className="collapsible_links">
                  <a href="/#">Footwear</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="footer__small-text">
          © 2023 Aachho Jaipur Pvt. Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

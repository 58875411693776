import React from "react";
import "./SizeRadioButtons.css";

const SizeRadioButtons = ({ sizeDetails, onChange, selectedSize }) => {
  console.log(sizeDetails);

  // If sizeValues prop is not provided, use the default values
  let sizeValues = sizeDetails
    ? Object.keys(sizeDetails)?.map((items) => {
        return { sizeValue: items };
      })
    : [];

  return (
    <>
      {sizeValues?.map((size, index) => {
        console.log(sizeDetails[size.sizeValue])
        if(sizeDetails[size.sizeValue].STOCK == 0){
          return (<div className="size_radio_btn-disabled" key={index}>
          <input
            disabled
            type="radio"
            id={`radio${size.sizeValue}`} // Use a unique identifier for each radio button
            name="sizeRadio" // Use the same name for all radio buttons in the group
            value={size.sizeValue}
            checked={selectedSize === size.sizeValue}
          />
          <label htmlFor={`radio${size.sizeValue}`}>{size.sizeValue}</label>
        </div>)
        }
        return (
          <div className="size_radio_btn" key={index}>
            <input
              onChange={(e) => onChange(size)}
              type="radio"
              id={`radio${size.sizeValue}`} // Use a unique identifier for each radio button
              name="sizeRadio" // Use the same name for all radio buttons in the group
              value={size.sizeValue}
              checked={selectedSize === size.sizeValue}
            />
            <label htmlFor={`radio${size.sizeValue}`}>{size.sizeValue}</label>
          </div>
        );
      })}
    </>
  );
};

export default SizeRadioButtons;

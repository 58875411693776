import React from "react";
import { ErrorIcon } from "react-hot-toast";

function CloseButton({toast}) {
  return (
    <button className="close-button" onClick={()=>toast.dismiss()}>
      <i className="ri-error-warning-fill"></i>
    </button>
  );
}

export default CloseButton;

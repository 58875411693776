// ** Toolkit imports
import { configureStore, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// ** Reducers

const API = axios.create({
  baseURL: "https://amarnathckr.online/api/v1/",

  withCredentials: true, // Add withCredentials option here
});
/* eslint-disable no-unused-vars */

const userSlice = createSlice({
  name: "user",
  initialState: "",
  reducers: {
    subscribeUser: (state, actions) => {
      return (state = actions.payload);
    },
    unsuscribeUser: (state, actions) => {
      return (state = "");
    },
  },
});

const tokenSlice = createSlice({
  name: "token",
  initialState: "",
  reducers: {
    subscribeToken: (state, actions) => {
      return (state = actions.payload);
    },
    unsuscribeToken: (state, actions) => {
      return (state = "");
    },
  },
});

const filterSlice = createSlice({
  name: "filterData",
  initialState: {},
  reducers: {
    subscribeFilter: (state, actions) => {
      return (state = actions.payload);
    },
    unsuscribeFilter: (state, actions) => {
      return (state = "");
    },
  },
});

const quickViewSlice = createSlice({
  name: "quickViewData",
  initialState: null,
  reducers: {
    subscribeQuickView: (state, actions) => {
      return (state = actions.payload);
    },
    unsubscribeQuickView: (state, actions) => {
      return (state = "");
    },
  },
});

const globalTriggerSlice = createSlice({
  name: "globalTrigger",
  initialState: false,
  reducers: {
    toggleTrigger: (state, actions) => {
      return (state = !state);
    },
  },
});

const loadingSlice = createSlice({
  name: "loading",
  initialState: false,
  reducers: {
    toggleLoading: (state, actions) => {
      return (state = !state);
    },
  },
});

const cartDrawerSlice = createSlice({
  name: "cartDrawer",
  initialState: false,
  reducers: {
    toggleDrawer: (state, actions) => {
      return (state = !state);
    },
  },
});

const searchSlice = createSlice({
  name: "search",
  initialState: "",
  reducers: {
    subscribeSearch: (state, actions) => {
      return (state = actions.payload);
    },
    unsubscribeSearch: (state, actions) => {
      return (state = "");
    },
  },
});

const sortSlice = createSlice({
  name: "sort",
  initialState: "",
  reducers: {
    subscribesort: (state, actions) => {
      return (state = actions.payload);
    },
    unsubscribesort: (state, actions) => {
      return (state = "");
    },
  },
});

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    token: tokenSlice.reducer,
    filterData: filterSlice.reducer,
    quickViewData: quickViewSlice.reducer,
    globalTrigger: globalTriggerSlice.reducer,
    loading: loadingSlice.reducer,
    cartDrawer: cartDrawerSlice.reducer,
    search: searchSlice.reducer,
    sort: sortSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export const { subscribeToken, unsuscribeToken } = tokenSlice.actions;
export const { subscribeUser, unsuscribeUser } = userSlice.actions;
export const { subscribeFilter, unsuscribeFilter } = filterSlice.actions;
export const { subscribeQuickView, unsubscribeQuickView } =
  quickViewSlice.actions;
export const { toggleTrigger } = globalTriggerSlice.actions;
export const { toggleLoading } = loadingSlice.actions;
export const { toggleDrawer } = cartDrawerSlice.actions;
export const { subscribeSearch, unsubscribeSearch } = searchSlice.actions;
export const { subscribesort, unsubscribesort } = sortSlice.actions;

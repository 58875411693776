import React, { useState } from "react";
import "./WishlistProductCardTwo.css";
import { product_img1 } from "../../../assets/images";
import { Link } from "react-router-dom";
import { postAnyAuth } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading, toggleTrigger } from "../../../store";
import GlobalModal from "../../GlobalModal/GlobalModal";

const WishlistProductCardTwo = ({
  product_images,
  productTitle,
  currencyCode,
  finalPrice,
  originalPrice,
  size,
  id,
  trigger,
  selected,
  handleSelect,
  setSizeSelected,
  handleMoveToCart,
  sizeVal,
  allData
}) => {
  const token = useSelector((state) => state.token);

  const [isGlobalModaOpen, setGlobalModaOpen] = useState(false);
  const [callBackData, setCallback] = useState();
  const toggleGlobalModaDrawer = (callback) => {
    setGlobalModaOpen(!isGlobalModaOpen);

    if (isGlobalModaOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };

  const handleRemoveWishlist = () => {
    const removeFromWishlist = () => {
      if (token) {
        dispatch(toggleLoading())
        postAnyAuth("wishlist/remove", { productIds: [id] }, token)
          .then((res) => {
            console.log(res.data);
            let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

            localWishlist = localWishlist.filter((items) => items.id !== id);

            console.log(localWishlist);

            localStorage.setItem("wishlist", JSON.stringify(localWishlist));
            trigger();
            dispatch(toggleLoading())
          })
          .catch((err) => {
            console.log(err);
            trigger();
            dispatch(toggleLoading())
          });
      } else {
        let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

        localWishlist = localWishlist.filter((items) => items.id !== id);

        console.log(localWishlist);

        localStorage.setItem("wishlist", JSON.stringify(localWishlist));
        trigger();
      }
    };
    removeFromWishlist()
    toggleGlobalModaDrawer();
  };

  const dispatch = useDispatch();

  return (
    <div className="wishlist-list-card">
     
      <div className="wishlist-list-card-checkbox">
        <input
          checked={selected}
          onChange={(e) => {
            handleSelect(e.target.checked, id);
          }}
          type="checkbox"
          id=""
          name=""
          value=""
        ></input>
      </div>
      <img
        src={"https://amarnathckr.online/api/v1/upload/" + product_images[0]}
        alt="productIMG"
      />
      <div className="wishlist-list-card-other-info-grid">
        <div className="information-texts">
          <Link
            onClick={() => dispatch(toggleTrigger())}
            to={`/product-details/${productTitle
              .split(" ")
              .join("-")
              .toLowerCase()}`}
            state={{ id: id }}
            className="product-text-info"
          >
            <p>Fab Galaxy</p>
            <h2>{`${currencyCode} ${productTitle}`}</h2>
            <div className="product_price">
              <span className="product-final-price">
                {`${currencyCode} ${finalPrice}`}
                <span className="product-original-price">
                  <span className="original-price">
                    {`${currencyCode} ${originalPrice}`}
                  </span>
                </span>
              </span>
            </div>
          </Link>
          <select
            name="sizes"
            id=""
            onChange={(e) => setSizeSelected({ e, id })}
            className="size-select-option"
          >
            {size &&
              size.map((items) => {
                return <option value={items}>{items}</option>;
              })}
          </select>
        </div>
        <div className="wishlist-status">
          <p>Added on 16 January 2024</p>
          <button
            onClick={() => handleMoveToCart({ id, size: sizeVal,...allData })}
            className="btn move-to-cart-btn"
          >
            Move to cart
          </button>
          <button onClick={handleRemoveWishlist} className="delete-btn">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default WishlistProductCardTwo;

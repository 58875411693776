// Client-side code
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { postAnyAuth } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { toggleLoading, toggleTrigger } from "../../store";
import { useNavigate } from "react-router-dom";

function  PaymentForm({ amount, address, setError, isChecked ,error}) {
  const stripe = useStripe();
  const elements = useElements();
  // A state variable to store the payment element's state
  const [paymentElementState, setPaymentElementState] = useState({});
  // A state variable to store the submit button's disabled status
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const token = useSelector((state) => state.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!address) {
      return toast.error("Please choose an address");
    }

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    elements.submit();
    console.log(parseFloat(Math.round(amount * 100)));
    const bodyObject = {
      // Pass the amount and currency to your server-side code
      amount: parseFloat(Math.round(amount * 100)),
      currency: "inr",
      address: address,
      paymentMethod: "CARD",
    };
    // Get the client secret of a payment intent from your server-side code
    // You can use the same code as before to create a payment intent
    dispatch(toggleLoading());
    await postAnyAuth("payments/create-payment-intent", bodyObject, token)
      .then(async (response) => {
        const { clientSecret } = response.data;
        dispatch(toggleLoading());
        
        // await localStorage.setItem("cart", "[]");
        // dispatch(toggleTrigger());
        dispatch(toggleLoading());
        // Confirm the payment with the client secret and the payment element
        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          clientSecret,
          confirmParams: {
            // Pass the client secret of the payment intent

            // Pass the payment element as the payment method
            payment_method: "card",
            // Optionally, pass a return URL if you want to redirect the customer after the payment
            return_url: "http://localhost:3001/account/order-sucess",
          },
          
        });

        if (result.error) {
          
          // Show error to your customer (for example, payment details incomplete)
          console.log(result.error.message);
          toast.error(result.error.message);
          setError(result.error.message)
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // await localStorage.setItem("cart", "[]");
          // await localStorage.setItem("cart", "[]");
          // dispatch(toggleTrigger());
        }
        dispatch(toggleLoading());
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          toast.error(`${error && error.response && error.response.data &&  error.response.data.message}`);
          
          setError(error && error.response && error.response.data && error.response.data?.message)
          dispatch(toggleTrigger());
          
          // localStorage.setItem(
          //   "cart",
          //   JSON.stringify(
          //     error && error.response && error.response.data &&error.response.data.cart ? error.response.data.cart : []
          //   )
          // );
          if (error && error.response && error.response.data && error.response.data.cart && error.response.data.cart.length < 1) {
            dispatch(toggleTrigger());
            window.location.href = "/";
          }
          dispatch(toggleLoading());
          // return navigate("/");
        }

        console.log(error);
      });
  };

  // A function to handle the onChange callback of the Payment Element
  const handlePaymentElementChange = (event) => {
    // Update the payment element state
    setPaymentElementState(event);
    // Enable or disable the submit button based on the payment element's validity and completeness
    setSubmitDisabled(!event.complete || event.error);
  };

  console.log(error)

  return (
    <div>
      <PaymentElement onChange={handlePaymentElementChange} />
      {error && error}
      <button
        type="submit"
        className="btn pay_now-btn"
        disabled={submitDisabled}
        onClick={handleSubmit}
      >
        Pay now
      </button>
    </div>
  );
}

export default PaymentForm;

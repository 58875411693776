import React from "react";
import "./NotFound.css";
import { product_img1 } from "../../../assets/images";
import { Link } from "react-router-dom";
const NotFound = ({ categoryBanner, title, message }) => {
  console.log(categoryBanner);


  const handleReload = ()=>{
    window.location.href = "/"
  }

  return (
    <section className="section">
      <div className="not-found-container container">
        <div className="not-found-title">
          <h1>{title}</h1>
          <p>{message}</p>
          <a onClick={handleReload} className="continue-shopping">
            Continue shopping
          </a>
        </div>
      </div>
      <div className="shop-by-category-container container">
        <div className="shop-by-category-title">
          <h1>SHOP BY CATEGORY</h1>
        </div>
        <div className="shop-by-category-items">
          {categoryBanner &&
            categoryBanner.map((item) => {
              return (
                <Link
                  to={
                    "/products/" + item.name.split(" ").join("-").toLowerCase()
                  }
                  state={item}
                  className="shop-by-category-card"
                >
                  <div className="category-card-img">
                    <img
                      src={`https://amarnathckr.online/api/v1/upload/${item.homeBanner}`}
                      alt="prod"
                    />
                  </div>
                  <div className="category-labels-button">
                    <Link
                      to={
                        "/products/" +
                        item.name.split(" ").join("-").toLowerCase()
                      }
                      state={item}
                      className="title-label"
                    >
                      {item.name}
                    </Link>
                    <div className="see-all">
                      <Link
                        to={
                          "/products/" +
                          item.name.split(" ").join("-").toLowerCase()
                        }
                        state={item}
                        className="see-all-btn"
                      >
                        See all
                      </Link>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default NotFound;

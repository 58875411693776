import React, { useEffect, useState } from "react";
import "./Checkout.css";
import { product_img1 } from "../../../assets/images";
import NormalTextFields from "../../Inputs/NormalTextField";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { getAnyApi, postAnyAuth } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import AddressForm from "../../AddressForm/AddressForm";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { toggleLoading, toggleTrigger } from "../../../store";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../PaymentForm/PaymentForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [mrp, setMrp] = useState(null);

  const [cartItems, setCartItems] = useState(null);

  const token = useSelector((province) => province.token);
  console.log(token);

  const [isCashOnDeliverySelected, setCashOnDeliverySelected] = useState("COD");

  const handleCashOnDeliveryChange = (val) => {
    setCashOnDeliverySelected(val);
  };

  const [selectedAddress, setSelected] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [inputValue, setInputValue] = useState({
    fname: "",
    lname: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    postalCode: "",
    phone: "",
    cardNumber: "",
    expDate: "",
    cvv: "",
    nameOnCard: "",
    province: "",
    country: "",
  });
  const {
    fname,
    lname,
    addressOne,
    addressTwo,
    city,
    postalCode,
    phone,
    cardNumber,
    expDate,
    cvv,
    nameOnCard,
    province,
    country,
  } = inputValue;

  const [errors, setErrors] = useState(null);

  const [totalAmount, setTotal] = useState(0);

  const navigate = useNavigate();

  const globalTrigger = useSelector((state) => state.globalTrigger);

  useEffect(() => {
   
    if (token) {
      console.log("herer");
      dispatch(toggleLoading());
      getAnyApi("checkout", token)
        .then((res) => {
          console.log(res.data.load.products);
          const total =
            res.data.load.products && res.data.load.products.length
              ? res.data.load.products.reduce((acc, item) => {
                  if (item.promo) {
                    let newVal =
                      item.productId.mrp -
                      (item.productId.mrp *
                        (parseFloat(item.promo) +
                          parseFloat(item.productId.td))) /
                        100;
                    return acc + newVal * item.quantity;
                  }
                  return acc + item.productId.fcp * item.quantity;
                }, 0)
              : 0;

          const mrp =
            res.data.load.products && res.data.load.products.length
              ? res.data.load.products.reduce((acc, item) => {
                  return acc + item.productId.mrp * item.quantity;
                }, 0)
              : 0;

          console.log(mrp);

          setMrp(mrp);

          setTotal(total);

          setCartItems(res.data.load.products);
          dispatch(toggleLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleLoading());
        });
    } 
  }, [token, trigger, globalTrigger]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const dispatch = useDispatch();
  const handleFormSubmit = (e) => {
    console.log("before submit");
    e.preventDefault();
    // Add your login logic here
    console.log("submit");
    if (!selectedAddress) {
      return setErrors("Please select an address");
    }
    if (!isCashOnDeliverySelected) {
      return setErrors("Please select a payment option");
    }

    if (isCashOnDeliverySelected === "COD") {
      dispatch(toggleLoading());
      return postAnyAuth(
        "checkout",
        {
          products: cartItems,
          address: selectedAddress,
          paymentMethod: isCashOnDeliverySelected,
          email: isChecked,
        },
        token
      )
        .then((res) => {
          dispatch(toggleLoading());
          console.log(res.data);
          localStorage.setItem("cart", "[]");
          dispatch(toggleTrigger());
          navigate("/account/order-history");
          toast.success("Order Placed Successfully");
        })
        .catch((err) => {
          dispatch(toggleLoading());
          console.log(err);
          toast.error(err.response.data.message);
          setErrors(err.response.data.message);
          localStorage.setItem(
            "cart",
            JSON.stringify(err.response.data.cart ? err.response.data.cart : [])
          );
          if (err.response.data.cart && err.response.data.cart.length < 1) {
            dispatch(toggleTrigger());
            // window.location.href("/");
          }
          // navigate("/");
        });
    }
  };

  const handlePaymentMethod = (paymentMethod) => {
    setCashOnDeliverySelected(paymentMethod);
  };

  console.log(selectedAddress);

  const user = useSelector((state) => state.user);

  console.log(user);

  useEffect(() => {
    if (user) {
      setSelected(user?.address[user?.address?.length - 1]);
    }
  }, [edit]);

  const appearance = {
    theme: "stripe",
    variables: {
      fontWeightNormal: "500",
      borderRadius: "2px",
      colorPrimary: "#f360a6",
      tabIconSelectedColor: "#fff",
      gridRowSpacing: "16px",
    },
    rules: {
      ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
        boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
      },
      ".Block": {
        borderColor: "transparent",
      },
      ".BlockDivider": {
        backgroundColor: "#ebebeb",
      },
      ".Tab, .Tab:hover, .Tab:focus": {
        border: "0",
      },
      ".Tab--selected, .Tab--selected:hover": {
        backgroundColor: "#f360a6",
        color: "#fff",
      },
    },
  };

  const stripeOptions = {
    appearance,
    mode: "payment",
    amount: parseFloat(Math.round(totalAmount * 100)),
    currency: "inr",
  };

  // if (cartItems && cartItems.length < 1) {
  //   navigate("/");
  // }

  return (
    <div className="checkout__container container">
      <div className="form_container">
        <div className="account_info">
          <div className="title">
            <h4>Account</h4>
          </div>
          <div className="account_email">
            <p>
              {user?.fname}
              {user?.lname}
            </p>
          </div>
          <a href="/#">Log out</a>
        </div>
        {!edit && user && user?.address?.length > 0 ? (
          <label>
            <select
              className="size-select-option"
              onChange={(e) => setSelected(JSON.parse(e.target.value))}
              value={JSON.stringify(selectedAddress)}
            >
              {!selectedAddress && (
                <option value={null}>Choose an address</option>
              )}
              {user?.address?.map((item) => {
                return (
                  <option key={item.addressOne} value={JSON.stringify(item)}>
                    {item.addressOne}
                  </option>
                );
              })}
            </select>
          </label>
        ) : null}

        {!edit && (
          <button
            onClick={() => setEdit(!edit)}
            className="btn add-addressOnes-btn"
          >
            Add address
          </button>
        )}

        {edit && !edit.editData && (
          <AddressForm
            buttonName={"Add addressOne"}
            close={() => {
              setTrigger(!trigger);
              setEdit(false);
            }}
          />
        )}

        {selectedAddress && (
          <div className="my-addresses-account-details">
            <p className="profile-address">
              {selectedAddress.fname + " " + selectedAddress.lname} <br />{" "}
              {selectedAddress.addressOne} <br />
              {selectedAddress.addressTwo} <br /> - <br />
              {selectedAddress.postalCode} {selectedAddress.city}{" "}
              {selectedAddress.province} {selectedAddress.country}
            </p>
          </div>
        )}
        <form
          className="checkout_form_div"
          target="none"
          onSubmit={handleFormSubmit}
        >
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span> </span>
            Email me with news and offers
          </label>

          <div className="form_title"></div>
          <div className="form_fields">
            <div className="form_select_field">{country}</div>
            <div className="form_side_fields">
              {fname} {lname}
            </div>
            {addressOne} {addressTwo}
            <div className="form_side_fields">
              <div className="city_field">{city}</div>
              <div className="form_select_field province_field">{province}</div>
              <div className="pin_code_field">{postalCode}</div>
            </div>
            {phone}
          </div>
          <div className="shipping_method">
            <div className="shipping_title">
              <h2>Shipping Method</h2>
            </div>
            <div className="method_display">
              <p>Free Shipping</p>
              <p>
                <b>Free</b>
              </p>
            </div>
          </div>
          <div className="payment_method">
            <div className="payment_title">
              <h2>Shipping Method</h2>
            </div>
            <div className="payment_method_selection">
              <label className="radio_labels">
                <input
                  type="radio"
                  name="paymentOption"
                  value="card"
                  checked={isCashOnDeliverySelected === "CARD" ? true : false}
                  onChange={() => handleCashOnDeliveryChange("CARD")}
                />
                <span> </span>
                Pay with Card
              </label>

              {isCashOnDeliverySelected === "CARD" && (
                <div className="card_payment_fields">
                  {/* Card Form Fields */}
                  {totalAmount > 0 && (
                    <Elements stripe={stripePromise} options={stripeOptions}>
                      <PaymentForm
                        error={errors}
                        isChecked={isChecked}
                        address={selectedAddress}
                        setError={(err) => setErrors(err)}
                        amount={totalAmount}
                      />
                    </Elements>
                  )}
                </div>
              )}
              <hr />
              <label className="radio_labels">
                <input
                  type="radio"
                  name="paymentOption"
                  value="cashOnDelivery"
                  checked={isCashOnDeliverySelected === "COD" ? true : false}
                  onChange={() => handlePaymentMethod("COD")}
                />
                <span> </span>
                Cash on Delivery
              </label>
            </div>
          </div>
          {errors && <div className="form-error">{errors}</div>}
          {isCashOnDeliverySelected === "COD" && (
            <a
              onClick={handleFormSubmit}
              type="submit"
              className="btn pay_now-btn"
            >
              Place Order
            </a>
          )}
        </form>
      </div>

      <div className="items_cart_container">
        <div className="products_container">
          {cartItems &&
            cartItems.map((items) => {
              return (
                <div
                  key={items.productId.images[0]}
                  className="checkout_product"
                >
                  <div className="checkout_product_image">
                    <img
                      src={
                        "https://amarnathckr.online/api/v1/upload/" +
                        items.productId.images[0]
                      }
                      alt={items.productId.images[0]}
                    />
                    <div className="product_item_count">{items.quantity}</div>
                  </div>
                  <div className="checkout_product_info">
                    <div className="info">
                      <div className="product_title">
                        <p>{items.productId.title}</p>
                      </div>
                      <div className="product_size">
                        <p>{items.size}</p>
                      </div>
                    </div>
                  </div>
                  <div className="checkout_product_price">
                    <p>₹ 
                      {items.promo
                        ? items.productId.fcp -
                          (items.productId.fcp * items.promo) / 100
                        : items.productId.fcp}{" "}
                      {items.promo && `(${items.promo}% off)`}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="promo_code_container">
          <NormalTextFields
            titleLabel="Promo Code"
            name="lname"
            placeholder="Enter Promo code here"
            type="text"
          />
          <button className="btn promo-apply-btn">Apply</button>
        </div>
        <div className="pricing_container">
          <div className="pricing_subtotal">
            <p>Total MRP</p>
            <p className="price-text">₹{mrp}</p>
          </div>
          <div className="pricing_subtotal">
            <p>Total Discount</p>
            <p className="price-text">- ₹{mrp - totalAmount}</p>
          </div>
          <div className="pricing_subtotal">
            <p>Total Discount</p>
            <p className="price-text">{Math.floor(((mrp - totalAmount) / mrp) * 100)}%</p>
          </div>
          <div className="pricing_subtotal">
            <p>Subtotal</p>
            <p className="price-text">₹{Math.floor(totalAmount)}</p>
          </div>
          <div className="pricing_shipping">
            <p>Shipping fee</p>
            <p>Free</p>
          </div>
          <div className="pricing_shipping">
            <p>Platform fee</p>
            <p>Free</p>
          </div>
          <div className="pricing_total">
            <div className="total_titles">
              <p className="total_text price-text">Total</p>
            </div>
            <p className="price_with_currency price-text">
              <span className="currency">INR</span> ₹{Math.floor(totalAmount)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

import React, { useState, useEffect } from "react";
import "./Announcement.css";

const Announcement = ({ promotion }) => {
  const calculateCountdown = () => {
    const endDate = new Date(promotion.toDate).getTime();
    const now = new Date().getTime();
    const difference = endDate - now;

    if (difference <= 0) {
      // Promotion has expired
      clearInterval(intervalId);
      setCountdown(null); // Set countdown to null when the promotion expires
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }
  };

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (promotion) {
      calculateCountdown();
      const id = setInterval(calculateCountdown, 1000);
      setIntervalId(id);
      return () => clearInterval(id);
    }
  }, [promotion]);

  if (!promotion) {
    return null;
  }

  if (!countdown) return null;

  return (
    <div className="top_sale_announcement">
      <p>Hurry up! Sale ends in:</p>
      <div className="announcement_grid">
        <div className="announcement_inner_flex">
          <p>{countdown.days < 10 ? `0${countdown.days}` : countdown.days}</p>
          <span>Days</span>
        </div>
        <div className="announcement_inner_flex colon">
          <p>:</p>
        </div>
        <div className="announcement_inner_flex">
          <p>
            {countdown.hours < 10 ? `0${countdown.hours}` : countdown.hours}
          </p>
          <span>Hrs</span>
        </div>
        <div className="announcement_inner_flex colon">
          <p>:</p>
        </div>
        <div className="announcement_inner_flex">
          <p>
            {countdown.minutes < 10
              ? `0${countdown.minutes}`
              : countdown.minutes}
          </p>
          <span>Mins</span>
        </div>
        <div className="announcement_inner_flex colon">
          <p>:</p>
        </div>
        <div className="announcement_inner_flex">
          <p>
            {countdown.seconds < 10
              ? `0${countdown.seconds}`
              : countdown.seconds}
          </p>
          <span>Secs</span>
        </div>
      </div>
    </div>
  );
};

export default Announcement;

import React, { useState } from "react";
import "./ContactUs.css";
import { Breadcrumbs } from "../../Headings";
import TextField from "../../Inputs/TextField";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import { PostAnyApi } from "../../../api/api";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../../store";

const contactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be at least 10 characters")
    .max(15, "Mobile number can be maximum 15 characters")
    .required("Mobile number is required"),
  subject: Yup.string()
    .min(10, "Subject must be at least 10 characters")
    .max(30, "Subject can be maximum 30 characters")
    .required("Subject is required"),
  message: Yup.string()
    .min(50, "Message must be at least 50 characters")
    .max(500, "Message can be maximum 500 characters")
    .required("Message is required"),
});

const ContactUs = () => {
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  return (
    <section className="section contact_us">
      <div className="contact_us_container container">
        <div className="contact_us-title">
          <h2>CONTACT US</h2>
        </div>
        <div className="contact_us-paragraph">
          <p>
            We'd love to hear from you! If you have any questions or comments
            for us, fill out the form below we will get back to you within 48
            hours. Or feel free to write us at support@fabgalaxy.co.in or call
            us at +91-8222165423. We are available to take your calls on (10:00
            am till 6:00 pm)
          </p>
        </div>

        <div className="table_wrapper">
          <table>
            <tbody>
              <tr>
                <td>
                  Manufactured, Packed &amp; Marketed By: <br /> FAB GALAXY PVT
                  LTD
                </td>
                <td>
                  Address <br /> 103-104 Ancillary park,
                  <br />
                  Sachin Apparel Park SEZ., Surat, Gujarat 394230
                </td>
              </tr>
              <tr>
                <td>
                  Customer Support <br /> 99999 99999
                </td>
                <td>
                  Email <br /> support@fabgalaxy.co.in
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="contact_us-title">
          <h2>CONTACT</h2>
        </div>
        <Formik
          initialValues={{
            email: "",
            name: "",
            subject: "",
            message: "",
            mobile: "",
          }}
          validationSchema={contactUsSchema}
          onSubmit={(values, { resetForm }) => {
            // Handle form submission (e.g., send data to server)

            setSuccess(false);
            dispatch(toggleLoading());

            PostAnyApi("contact-us", values)
              .then((res) => {
                toast.success(
                  "Contact request submited, Our staff will contact you back in 24 hours."
                );
                resetForm();
                dispatch(toggleLoading());
                setSuccess(true);
              })
              .catch((err) => {
                dispatch(toggleLoading());
                toast.error("Something went wrong.");
              });
          }}
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form className="contact_us-form">
              <div className="flex-form-field">
                <TextField
                  titleLabel="Full Name"
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  name="name"
                  Placeholder="Enter first name here"
                  type="text"
                />
                <TextField
                  titleLabel="Email"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  name="email"
                  Placeholder="Enter email here"
                  type="email"
                />
              </div>
              <TextField
                titleLabel="PHONE"
                value={values.mobile}
                error={errors.mobile}
                touched={touched.mobile}
                name="mobile"
                Placeholder="Enter phone"
                type="text"
                onChange=""
              />
              <TextField
                titleLabel="Subject"
                value={values.subject}
                error={errors.subject}
                touched={touched.subject}
                name="subject"
                Placeholder="Enter Subject"
                type="text"
                onChange=""
              />
              <label className="text-area-label">Message</label>
              <textarea
                className={`text-field-input  ${
                  touched.message && errors.message && "border-red-error"
                }`}
                name="message"
                id="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                cols="30"
                rows="5"
                placeholder="Your message..."
              ></textarea>
              {touched.message && errors.message && (
                <div className="field-error-text">{errors.message}</div>
              )}
              {success && (
                <div className="contact-success">
                  <p>
                    Contact request submited, Our staff will contact you back in
                    24 hours.
                  </p>
                </div>
              )}
              <div className="send-button-div">
                <button className="btn send-btn">Send</button>
                <p>
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a href="https://policies.google.com/privacy" target="_blank">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://policies.google.com/terms" target="_blank">
                    Terms of Service
                  </a>{" "}
                  apply.
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default ContactUs;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import "./FilterForm.css";
import { useSelector, useDispatch } from "react-redux";
import { subscribeFilter } from "../../../store";
import Skeleton from "react-loading-skeleton";

const FilterForm = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filterData);

  const [isFilterOpen, setIsFilterOpen] = useState({});
  const [isFilterPricingOpen, setIsFilterPricingOpen] = useState(true);
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(50000);
  const [tagList, setTagList] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    if (!isInitialized && filters["subCategory"]) {
      // Set the initial state to have all sections opened when the component mounts
      const initialIsopenv = {};

      Object.keys(filters).map((items) => {
        initialIsopenv[items] = true;
      });

      setIsFilterOpen(initialIsopenv);
      setIsInitialized(true);
    }
  }, [filters]);

  useEffect(() => {
    // Update tagList based on filters state
    const newTagList = [];

    if (filters.subCategory) {
      Object.entries(filters).forEach(([filterKey, filterData]) => {
        Object.entries(filterData).forEach(([checkbox, data]) => {
          if (data[Object.keys(data)].state) {
            newTagList.push({ id: checkbox, tagName: Object.keys(data) });
          }
        });
      });
    }

    setTagList(newTagList);
  }, [filters]);

  const setFilters = (data) => {
    dispatch(subscribeFilter(data)); // Dispatch your action to update Redux state
  };

  const handleCheckboxChange = (list, checkbox) => {
    // Find the target category in the filters
    const updatedFilters = { ...filters };
    const categoryFilters = updatedFilters[list];

    // Find the target filter option within the category
    const targetFilterIndex = categoryFilters.findIndex(
      (filterOption) => filterOption[checkbox] !== undefined
    );

    if (targetFilterIndex !== -1) {
      // Create a new object for the target filter option with the updated state
      const updatedFilterOption = {
        ...categoryFilters[targetFilterIndex],
        [checkbox]: {
          ...categoryFilters[targetFilterIndex][checkbox],
          state: !categoryFilters[targetFilterIndex][checkbox].state,
        },
      };

      // Create a new array with the updated filter option
      const updatedCategoryFilters = [
        ...categoryFilters.slice(0, targetFilterIndex),
        updatedFilterOption,
        ...categoryFilters.slice(targetFilterIndex + 1),
      ];

      // Update the state with the modified filters
      setFilters({
        ...updatedFilters,
        [list]: updatedCategoryFilters,
      });
    }
  };

  const toggleFilter = (filterKey) => {
    setIsFilterOpen((prev) => ({
      ...prev,
      [filterKey]: !prev[filterKey],
    }));
  };

  const togglePricingFilter = () => {
    setIsFilterPricingOpen(!isFilterPricingOpen);
  };

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const renderFilterSection = (filterKey, filterData) => {
    return (
      <div key={filterKey}>
        <button
          type="button"
          className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${
            isFilterOpen[filterKey] ? "is-open" : "bottom-line"
          } tag-list__header`}
          aria-controls={`CollectionSidebar-${filterKey}-filter`}
          data-collapsible-id={`filter-${filterKey}`}
          aria-expanded={isFilterOpen[filterKey] ? "true" : "false"}
          onClick={() => toggleFilter(filterKey)}
        >
          {filterKey}
          <span
            className={`collapsible-trigger__icon${
              isFilterOpen[filterKey] ? "--open" : ""
            }`}
            role="presentation"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              viewBox="0 0 28 16"
            >
              <path
                d="M1.57 1.59l12.76 12.77L27.1 1.59"
                strokeWidth="2"
                stroke="#000"
                fill="none"
                fillRule="evenodd"
              ></path>
            </svg>
          </span>
        </button>
        {isFilterOpen[filterKey] && (
          <div className="collapsible-content__inner bottom-line">
            <div className="filter-section filter-scrollable">
              {Object.entries(filterData).map(([checkbox, data]) => (
                <div key={Object.keys(data)[0]} className="check-with-colors">
                  <label
                    className={data[Object.keys(data)].state ? "checked" : ""}
                  >
                    <input
                      type="checkbox"
                      checked={data[Object.keys(data)].state}
                      onChange={() =>
                        handleCheckboxChange(filterKey, Object.keys(data))
                      }
                    />
                    <span></span>
                    {Object.keys(data)} ({data[Object.keys(data)].count})
                  </label>
                  {/* ... (other code) */}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTagSection = (filterKey, filterData) => {
    return (
      <div key={filterKey}>
        {Object.entries(filterData).map(([checkbox, data]) => {
          if (data[Object.keys(data)].state) {
            return (
              <li className="tag tag--remove" key={Object.keys(data)[0]}>
                <a
                  className="btn btn--small"
                  onClick={() =>
                    handleCheckboxChange(filterKey, Object.keys(data))
                  }
                >
                  {Object.keys(data)}
                  <i className="ri-close-line icon"></i>
                </a>
              </li>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  console.log(filters);

  return (
    <>
      {
        <div className="collection-sidebar">
          <ul className="no-bullets tag-list tag-list--active-tags">
            {!loading && filters.subCategory &&
              Object.entries(filters).flatMap(([filterKey, filterData]) =>
                Object.entries(filterData)
                  .filter(([checkbox, data]) => data.state)
                  .map(([checkbox, data]) => (
                    <li className="tag tag--remove" key={Object.keys(data)[0]}>
                      <a
                        className="btn btn--small"
                        onClick={() =>
                          handleCheckboxChange(filterKey, Object.keys(data))
                        }
                      >
                        {Object.keys(data)}
                        <i className="ri-close-line icon"></i>
                      </a>
                    </li>
                  ))
              )}
            {!loading && filters.subCategory &&
              Object.entries(filters).map(([filterKey, filterData]) =>
                renderTagSection(filterKey, filterData)
              )}
          </ul>
          <form action="" className="filter-form">
            <div className="collection-sidebar__group--1">
              <div className="collection-sidebar__group">
                {!loading && Object.entries(filters).map(([filterKey, filterData]) =>
                  renderFilterSection(filterKey, filterData)
                )}

                {loading && (
                  <button
                    type="button"
                    className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${"bottom-line"} tag-list__header`}
                    aria-controls={`CollectionSidebar-${"loading"}-filter`}
                    data-collapsible-id={`filter-${"loading"}`}
                    aria-expanded={"false"}
                  >
                    <Skeleton height={"100%"} />
                    <span
                      className={`collapsible-trigger__icon${""}`}
                      role="presentation"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        viewBox="0 0 28 16"
                      >
                        <path
                          d="M1.57 1.59l12.76 12.77L27.1 1.59"
                          strokeWidth="2"
                          stroke="#000"
                          fill="none"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                )}
                {loading && (
                  <button
                    type="button"
                    className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${"bottom-line"} tag-list__header`}
                    aria-controls={`CollectionSidebar-${"loading"}-filter`}
                    data-collapsible-id={`filter-${"loading"}`}
                    aria-expanded={"false"}
                  >
                    <Skeleton height={"100%"} />
                    <span
                      className={`collapsible-trigger__icon${""}`}
                      role="presentation"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        viewBox="0 0 28 16"
                      >
                        <path
                          d="M1.57 1.59l12.76 12.77L27.1 1.59"
                          strokeWidth="2"
                          stroke="#000"
                          fill="none"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                )}
                {loading && (
                  <button
                    type="button"
                    className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${"bottom-line"} tag-list__header`}
                    aria-controls={`CollectionSidebar-${"loading"}-filter`}
                    data-collapsible-id={`filter-${"loading"}`}
                    aria-expanded={"false"}
                  >
                    <Skeleton height={"100%"} />
                    <span
                      className={`collapsible-trigger__icon${""}`}
                      role="presentation"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        viewBox="0 0 28 16"
                      >
                        <path
                          d="M1.57 1.59l12.76 12.77L27.1 1.59"
                          strokeWidth="2"
                          stroke="#000"
                          fill="none"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                )}
                {loading && (
                  <button
                    type="button"
                    className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${"bottom-line"} tag-list__header`}
                    aria-controls={`CollectionSidebar-${"loading"}-filter`}
                    data-collapsible-id={`filter-${"loading"}`}
                    aria-expanded={"false"}
                  >
                    <Skeleton height={"100%"} />
                    <span
                      className={`collapsible-trigger__icon${""}`}
                      role="presentation"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        viewBox="0 0 28 16"
                      >
                        <path
                          d="M1.57 1.59l12.76 12.77L27.1 1.59"
                          strokeWidth="2"
                          stroke="#000"
                          fill="none"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                )}
                {loading && (
                  <button
                    type="button"
                    className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${"bottom-line"} tag-list__header`}
                    aria-controls={`CollectionSidebar-${"loading"}-filter`}
                    data-collapsible-id={`filter-${"loading"}`}
                    aria-expanded={"false"}
                  >
                    <Skeleton height={"100%"} />
                    <span
                      className={`collapsible-trigger__icon${""}`}
                      role="presentation"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        viewBox="0 0 28 16"
                      >
                        <path
                          d="M1.57 1.59l12.76 12.77L27.1 1.59"
                          strokeWidth="2"
                          stroke="#000"
                          fill="none"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                )}
                {loading && (
                  <button
                    type="button"
                    className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${"bottom-line"} tag-list__header`}
                    aria-controls={`CollectionSidebar-${"loading"}-filter`}
                    data-collapsible-id={`filter-${"loading"}`}
                    aria-expanded={"false"}
                  >
                    <Skeleton height={"100%"} />
                    <span
                      className={`collapsible-trigger__icon${""}`}
                      role="presentation"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        viewBox="0 0 28 16"
                      >
                        <path
                          d="M1.57 1.59l12.76 12.77L27.1 1.59"
                          strokeWidth="2"
                          stroke="#000"
                          fill="none"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                )}

                <button
                  type="button"
                  className={`collapsible-trigger collapsible-trigger-btn collapsible--auto-height ${
                    isFilterPricingOpen ? "is-open" : "bottom-line"
                  } tag-list__header`}
                  aria-controls="CollectionSidebar-2-filter-shop-by-category"
                  data-collapsible-id="filter-shop-by-category"
                  aria-expanded={isFilterPricingOpen ? "true" : "false"}
                  onClick={togglePricingFilter}
                >
                  Pricing
                  <span
                    className={`collapsible-trigger__icon${
                      isFilterPricingOpen ? "--open" : ""
                    }`}
                    role="presentation"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      className={`icon icon--wide icon-chevron-${
                        isFilterPricingOpen ? "up" : "down"
                      }`}
                      viewBox="0 0 28 16"
                    >
                      <path
                        d="M1.57 1.59l12.76 12.77L27.1 1.59"
                        strokeWidth="2"
                        stroke="#000"
                        fill="none"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </button>
                {isFilterPricingOpen && (
                  <div className="collapsible-content__inner bottom-line">
                    <div className="filter-section filter-scrollable">
                      <div className="rangeSlider">
                        <div className="rangeSlider_labels">
                          <div className="label_left">
                            <p>{minValue}</p>
                          </div>
                          <div className="label_right">
                            <p>{maxValue}</p>
                          </div>
                        </div>
                        <MultiRangeSlider
                          min={0}
                          max={100}
                          step={5}
                          minValue={minValue}
                          maxValue={maxValue}
                          ruler="false"
                          label="false"
                          thumbLeftColor="#DC747D"
                          thumbRightColor="#DC747D"
                          barInnerColor="#DC747D"
                          onInput={(e) => {
                            handleInput(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      }
    </>
  );
};

export default FilterForm;

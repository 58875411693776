import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAnyApi, getWithoutAuth } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import SizeChartDrawer from "../../SizeChart/SizeChartDrawer/SizeChartDrawer";
import { Breadcrumbs } from "../../Headings";
import LazyImage from "../../utils/LazyImage";
import MobileImageSliders from "../../MobileImageSliders/MobileImageSliders";
import SizeRadioButtons from "../../SizeRadioButtons/SizeRadioButtons";

import {
  Fast_Delivery,
  Global,
  HeartIcon,
  product_img1,
  product_img1_H,
  product_img1_H2,
  product_img1_H3,
} from "../../../assets/images";
import Specifications from "../../Specifications/Specifications";
import CollapsibleList from "../../CollapsibleList/CollapsibleList";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { toggleLoading } from "../../../store";
import Video from "yet-another-react-lightbox/plugins/video";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

function TestingPage() {
  const [product, setProduct] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const accessKey = searchParams.get("accessKey"); // Replace 'myParam' with your actual parameter name
  console.log(id, accessKey);

  useEffect(() => {
    dispatch(toggleLoading());
    getWithoutAuth("product/get-testing-product?productId=" + id)
      .then((res) => {
        setProduct(res.data);
        dispatch(toggleLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(toggleLoading());
      });
  }, []);

  const [selectedSize, setSelectedSize] = useState(null);
  const fullscreenRef = React.useRef(null);

  const globalTrigger = useSelector((state) => state.globalTrigger);

  console.log(globalTrigger);

  const shareUrl = "https://example.com";
  const title = "Check out this amazing content!";

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    shareUrl
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    shareUrl
  )}&text=${encodeURIComponent(title)}`;
  const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    shareUrl
  )}&description=${encodeURIComponent(title)}`;

  const [isSizeChartOpen, setSizeChartOpen] = useState(false);

  const toggleSizeChartDrawer = () => {
    setSizeChartOpen(!isSizeChartOpen);
    if (isSizeChartOpen) {
      document.documentElement.style.overflow = "unset";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  };
  const zoomRef = React.useRef(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isWishlisted, setIsWish] = useState(false);
  const [error, setError] = useState(null);

  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state && location.state.id) {
      const allList = JSON.parse(localStorage.getItem("wishlist"));

      console.log(allList);

      let filteredData = allList?.filter(
        (items) => items.id === location.state.id
      );

      console.log(filteredData);
      if (filteredData && filteredData.length > 0) {
        setIsWish(true);
      }
    }
  }, [token, location.state]);

  console.log(isWishlisted);

  const referal = location?.state?.referal;

  console.log(referal);

  const images = product?.load?.images;

  const openLightbox = (index) => {
    document.documentElement.style.overflow = "hidden";
    // Check if all images are loaded before opening the Lightbox
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    document.documentElement.style.overflow = "unset";
    setLightboxOpen(false);
    setZoomLevel(1);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(1, prevZoom - 0.1));
  };

  const gotoNext = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const gotoPrev = () => {
    setPhotoIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  console.log(selectedSize);

  console.log(product?.load);

  const renderContent = (imageUrl, index) => {
    if (imageUrl.includes(".mp4")) {
      // Check if it's a video file
      return (
        <video
          width="100%"
          height="auto"
          src={imageUrl}
          controls={false}
          playsInline
          autoPlay
          muted
          loop
          preload="none"
          poster={
            "https://amarnathckr.online/api/v1/upload/" + product.load.images[0]
          }
        />
      );
    } else {
      // Image
      return <LazyImage src={imageUrl} alt={`Image ${index + 1}`} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>{product?.load?.seoData.title}</title>
        <meta name="description" content={product?.load?.seoData.description} />
        {product?.load?.seoData?.metaTags?.map((items) => {
          console.log(items);
          return (
            <meta key={items.name} name={items.name} content={items.content} />
          );
        })}
      </Helmet>
      <SizeChartDrawer
        sizeDetails={product?.load?.sizeDetails}
        shortName={product?.subCategory.shortName}
        isSizeChartOpen={isSizeChartOpen}
        toggleSizeChartDrawer={toggleSizeChartDrawer}
      />
      <section className="section">
        <Breadcrumbs />
        <div className="container details_product_container">
          <div className="details_image_grid common-clearfix">
            <div className="desktop_image_grid">
              {product &&
                product.load.images.map((imageUrl, index) => (
                  <div
                    className="details_image-sizing"
                    key={index}
                    onClick={() => openLightbox(index)}
                  >
                    <div className="image-grid-imageContainer">
                      {renderContent(
                        "https://amarnathckr.online/api/v1/upload/" + imageUrl,
                        index
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className="mobile_image_grid">
              <MobileImageSliders images={product?.load?.images} />
            </div>
          </div>
          <div className="details_all_details">
            <h2 className="details-single__title">{product?.load?.title}</h2>
            <div className="details_star_rating">
              <i className="ri-star-fill"></i>
              <i className="ri-star-fill"></i>
              <i className="ri-star-fill"></i>
              <i className="ri-star-half-fill"></i>
              <i className="ri-star-line"></i>
              <span>7 Reviews</span>
            </div>
            <div className="details_all_pricing">
              <div className="details_all_pricing_prices">
                <span className="prices_final-price">
                  Rs. {product?.load.fcp}
                </span>
                <span className="prices_main-price">
                  Rs. {product?.load.mrp}
                </span>
                <span className="prices_discount">{product?.load.td}% off</span>
              </div>
              <div className="details_all_pricing_savings">
                <span className="details_all_pricing_saving_price">
                  Save <b>Rs. {product?.load.fcp - product?.load.mrp}</b>
                </span>
                <p className="text-info-label">Inclusive of all taxes</p>
              </div>
            </div>
            <div className="details_details_sizes">
              <div className="details_details_sizes_chart">
                <p>SIZE</p>
                <a onClick={toggleSizeChartDrawer} className="size_chart-btn">
                  Size chart <i className="ri-arrow-right-line"></i>
                </a>
              </div>
              <div className="details_sizes_radio">
                <SizeRadioButtons
                  onChange={(data) => {
                    setError(null);
                    setSelectedSize(data.sizeValue);
                  }}
                  sizeValues={product?.load?.sizeDetails}
                  selectedSize={selectedSize}
                />
              </div>
              {!error && <div className="empty-div"></div>}
              {error && <div className="error-text">{error}</div>}
            </div>
            <div className="wishlist_and_add_cart-btn">
              <div className="wishlist_fav_btn">
                {isWishlisted > 0 ? (
                  <a className="fav-btn">
                    <i className="ri-heart-3-fill"></i>
                  </a>
                ) : (
                  <a className="fav-btn">
                    <i className="ri-heart-3-line"></i>
                  </a>
                )}
              </div>
              <div className="add_to_cart_btn">
                <a className="btn">Add to cart</a>
              </div>
            </div>
            <div className="details_sales_points">
              <div className="details_sales_point">
                <img src={Fast_Delivery} alt={Fast_Delivery} />
                <p>Free Shipping On Prepaid & COD*</p>
              </div>
              <div className="details_sales_point">
                <img className="globalIcon" src={Global} alt={Global} />
                <p>Worldwide Shipping</p>
              </div>
              <div className="details_sales_point">
                <span className="icon icon--inventory"></span>
                <p>Available</p>
              </div>
            </div>
            <div className="specifications">
              <div className="specifications_heading">
                <h3>Specifications</h3>
              </div>
              <Specifications
                subCategory={product?.load.subCategory}
                ProductSpecifications={{
                  productDetails: product?.load?.productDetails,
                  otherDetails: product?.load?.otherDetails,
                  colorDetails: product?.load?.colorDetails,
                  colorFamily: product?.load?.colorFamily,
                }}
              />
            </div>
            <div className="details_other_info">
              <CollapsibleList
                collapsibleTitle="Description"
                collapsibleValues={`${product?.load?.title} <br />
        CARE: Dry Clean Only <br /> Model Size: Model is wearing ${product?.load.otherDetails[4].value.value} Size <br />
        Model Height: ${product?.load.otherDetails[5].value.value}''
        <br /> DISCLAIMER: Slight Colour variations may occur due to different
        screen resolution.`}
              />
              <CollapsibleList
                collapsibleTitle="Shipping information"
                collapsibleValues={`
                <p>We endeavor to provide a pleasant and zero-hassle shopping experience for you. All our policies and terms of use are crafted to ensure ease of purchase and support. We request you to thoroughly review them and make informed purchases.</p>
                <p>We at Aachho, understand the importance of delivering your products timely and safely. Therefore, we partner with some of the most reputed Indian and international courier partners such as Bluedart, Delhivery, DHL, Aramex, etc., to ensure seamless delivery of all orders.</p>
                <h2>Domestic &amp; International Shipping</h2>
                <ul>
                <li>
                <p><b>Charges:</b> Aachho offers FREE SHIPPING ACROSS INDIA for all orders, prepaid as well as cash on delivery (COD). For orders from outside India, additional shipping fees is charged per kg and the total shipping charges depend on the weight of the total order parcel which is calculated at the time of checkout.</p>
                </li>
                <li>
                <p><b>Tax and duties:</b> Please note that many countries charge varied import duties, customs charges and taxes on shipped items. All such additional charges are to be directly borne by the customer upon delivery. Hence, it is advisable to check your country’s taxes policies before placing an order with us because, once the order is placed, we do not offer returns or cancellations for international orders.</p>
                <li>
                <p><b>Credit note usage:</b> If you are issued any credit note, you shall receive it on your registered email ID. It can be applied only once on our official website and within&nbsp;3 months from the date of issue.</p>
                </li>
                </ul>`}
              />
            </div>
            <div className="details_social_media_links">
              <a
                href={facebookShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-facebook-fill"></i>
                Facebook
              </a>
              <a
                href={twitterShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-twitter-x-fill"></i>
                Twitter
              </a>
              <a
                href={pinterestShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-pinterest-fill"></i>
                Pinterest
              </a>
            </div>
          </div>
        </div>
        {lightboxOpen && (
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            plugins={[Video, Zoom, Fullscreen]}
            fullscreen={{ ref: fullscreenRef }}
            index={photoIndex}
            zoom={{ ref: zoomRef, scrollToZoom: true }}
            slides={product.load.images.map((imageURL) => {
              if (imageURL.includes("mp4")) {
                return {
                  type: "video",
                  poster:
                    "https://amarnathckr.online/api/v1/upload/" + imageURL,
                  sources: [
                    {
                      src:
                        "https://amarnathckr.online/api/v1/upload/" + imageURL,
                      type: "video/mp4",
                    },
                  ],
                  autoPlay: true,
                  controls: false,
                  loop: true,
                };
              } else {
                return {
                  src: "https://amarnathckr.online/api/v1/upload/" + imageURL,
                };
              }
            })}
          />
        )}
      </section>
    </>
  );
}

export default TestingPage;

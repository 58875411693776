import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ProductListLoader.css"

const ProductListLoader = () => {
  return (
    <>
      <div className="product-card">
        <div className="product_link">
          <div className="product_images"></div>

          <div className="product-image">
            <Skeleton height={"100%"} />
          </div>
        </div>

        <h2>
          <Skeleton height={"100%"} />
        </h2>
        <div className="product_price">
          <span className="product-final-price">
            <Skeleton height={"100%"} />
          </span>
          <span className="sale_percentage">
            <Skeleton height={"100%"} />
          </span>
        </div>
      </div>
    </>
  );
};

export default ProductListLoader;

import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../Headings";
import "./MyAddresses.css";
import { AddressForm } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { getAnyApi } from "../../../api/api";
import { subscribeUser, toggleLoading } from "../../../store";

const MyAddresses = () => {
  const [addOrEdit, setAddOrEdit] = useState(false);
  const userData = useSelector((state) => state.user);

  const toggleEdit = (data) => {
    console.log(data);
    setAddOrEdit({editData : data});
  };

  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);

  const handleDelete = (data) => {
    dispatch(toggleLoading())
    getAnyApi("customer/delete-address/" + data._id, token)
      .then((res) => {
        console.log(res.data);
        dispatch(subscribeUser(res.data.load));
        localStorage.setItem("user", JSON.stringify(res.data.load));
        dispatch(toggleLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(toggleLoading())
      });
  };

  return (
    <div className="my-addresses-container container">
      <Breadcrumbs
        links={[{ links_id: 1, redirectTo: "/account", linkName: "ADDRESSES" }]}
      />
      <div className="my-addresses-title">
        <h2>ADDRESSES</h2>
      </div>
      <button
        onClick={() => setAddOrEdit(!addOrEdit)}
        className="btn add-addresses-btn"
      >
        Add Address
      </button>
      {addOrEdit && !addOrEdit.editData && <AddressForm close={() => setAddOrEdit(false)} />}
      {addOrEdit && addOrEdit.editData && (
        <AddressForm
          editData={addOrEdit.editData}
          close={() => setAddOrEdit(false)}
        />
      )}
      {userData && userData.address.length < 1 && !addOrEdit && (
        <p className="no-address-found">No Address Found</p>
      )}

      {userData &&
        userData.address.map((items) => {
          return (
            <div className="my-addresses-account-details">
              {items.isDefault && (
                <p className="my-addresses-default">Default</p>
              )}
              <p className="profile-address">
                {items.fname + " " + items.lname} <br /> {items.addressOne}{" "}
                <br />
                {items.addressTwo} <br /> - <br />
                {items.postalCode} {items.city} {items.province} {items.country}
              </p>
              <div className="my-addresses-action-buttons">
                <button
                  onClick={() => toggleEdit(items)}
                  className="btn edit-address-btn"
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    handleDelete(items);
                  }}
                  className="btn delete-address-btn"
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MyAddresses;
